import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@postenbring/hedwig-react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ButtonContent } from './style'
import React, { ButtonHTMLAttributes, PropsWithChildren } from "react";

type Variant = "primary" | "secondary" | "primary-outline" | "secondary-outline" | "primary-no-outline";

interface ButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  /**
   * The height, font size and padding of the button
   */
  size?: "small" | "medium" | "large";

  /**
   * The background and fill of the button
   *
   * @default "primary"
   */
  variant?: Variant;

  /**
   * Make the button use 100% width available.
   * Using the "mobile" it only stretches to full width on smaller screens
   */
  fullWidth?: boolean | "mobile";

  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   *
   * @default false
   */
  asChild?: boolean
  icon?: IconProp
  spin?: boolean
  iconRigth?: boolean
}

const PbeButton = ({
                     icon,
                     size,
                     type,
                     disabled,
                     spin,
                     onClick,
                     iconRigth,
                     variant = "primary",
                     children,
                   }: ButtonProps) => {

  function safeVariant(_variant: Variant): "primary" | "secondary" | "primary-outline" | "secondary-outline" {
    if (_variant == "primary-no-outline") return "primary-outline"
    return _variant;
  }

  return (
    <Button
      variant={safeVariant(variant)}
      size={size}
      type={type}
      disabled={disabled}
      style={{cursor: disabled ? "not-allowed" : "pointer", border: variant === "primary-no-outline" ? "none" : ""}}
      onClick={onClick}>
      <ButtonContent style={{flexDirection: iconRigth ? "row-reverse" : "row"}}>
        {icon && <FontAwesomeIcon spin={spin} icon={icon}/>}
        {children}
      </ButtonContent>
    </Button>
  );
}

export default PbeButton;
