import { Header, HeaderText, Icon, InfoContainer, InfoText, ProgressBar, PorgressBarContainer, ProgressCardContainer } from "./style"

interface IProgressCardPlaceholderProps {
    headerWidth?: number
    infoTextWidth?: number
    firstProgress?: number
    secondProgress?: number
}

const ProgressCardPlaceholder = ({ headerWidth, infoTextWidth, firstProgress, secondProgress }: IProgressCardPlaceholderProps) => {
    return (
        <ProgressCardContainer>
            {
                headerWidth && (
                    <Header>
                        <HeaderText width={headerWidth} className="loading-content" />
                        <Icon className="loading-content" />
                    </Header>
                )
            }
            {
                infoTextWidth && (
                    <InfoContainer>
                        <Icon className="loading-content" />
                        <InfoText width={infoTextWidth} className="loading-content" />
                    </InfoContainer>
                )
            }
            {
                firstProgress && (
                    <PorgressBarContainer>
                        <Icon className="loading-content" />
                        <ProgressBar progress={firstProgress.toString()} className="loading-content" />
                    </PorgressBarContainer>
                )
            }
            {
                secondProgress && (
                    <PorgressBarContainer>
                        <Icon className="loading-content" />
                        <ProgressBar progress={secondProgress.toString()} className="loading-content" />
                    </PorgressBarContainer>
                )
            }
        </ProgressCardContainer>
    )
}

export default ProgressCardPlaceholder