import { PropsWithChildren, useEffect, useState } from "react";
import { ButtonContainer, Container, TextContainer } from "./style"
import BaseModal from "../base-modal";
import { useTranslation } from 'react-i18next';
import PbeButton from "@/components/base/button";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";

interface IConfirmationModalProps extends PropsWithChildren<any> {
  title?: string
  show?: boolean
  inConfirm?: boolean
  confirmButtonText: string
  onConfirm?: () => Promise<boolean>
  onClose?: () => void
}

const ConfirmationModal = ({title, show, onClose, onConfirm, inConfirm, confirmButtonText, children}: IConfirmationModalProps) => {
  const {t} = useTranslation(['translation']);
  const [_show, setShow] = useState(show)
  const [inSave, setInSave] = useState(false)

  useEffect(() => {
    setShow(show)
  }, [show]);

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setShow(false)
  }

  const handleConfirm = async () => {
    if (onConfirm) {
      setInSave(true)
      onConfirm().then(ok => {
        setInSave(false)
        if (ok)
          handleClose()
      })
    }

  }

  return (
    <BaseModal modalType={'confirmation'} title={title} show={_show} onClose={handleClose}>
      <Container>
        <TextContainer>
          {children}
        </TextContainer>
        <ButtonContainer>
          {
            inConfirm || inSave ? (
              <PbeButton icon={faSpinnerThird} spin={true}>
              </PbeButton>
            ) : (
              <PbeButton onClick={handleConfirm}>
                {confirmButtonText}
              </PbeButton>
            )
          }
          <PbeButton variant={"primary-outline"} onClick={handleClose}>
            {t('common.cancel')}
          </PbeButton>
        </ButtonContainer>
      </Container>
    </BaseModal>
  );
}
export default ConfirmationModal;
