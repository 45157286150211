import { useEffect, useState } from "react"
import { Progress, ProgressBarContainer, ProgressBarEntryContainer } from "./style"
import { colors } from "@/ui/colors"
import { getProgressColor } from "@/helpers/progressHelpers"

interface IProgressBarProps {
    progress?: number
    color?: string
}

interface IProgressEntry {
    flex: number
    progress: number
    color: string
}

const ProgressBar = ({ progress, color }: IProgressBarProps) => {
    const [progressEntries, setProgressEntries] = useState<IProgressEntry[]>([])

    useEffect(() => {
        const newProgress = progress ?? 0;
        const newEntries: IProgressEntry[] = [];
        const lastPart = newProgress % 100
        if (newProgress <= 100) {
            newEntries.push({
                flex: 1,
                progress: newProgress,
                color: color ?? getProgressColor(newProgress),
            });
        } else {
            newEntries.push({
                flex: 1,
                progress: 100,
                color: colors.darkGreen,
            });
            newEntries.push({
                flex: 0.5,
                progress: 100,
                color: colors.bringGreen,
            });
        }
        setProgressEntries(newEntries);
    }, [progress]);


    return (
        <ProgressBarContainer>
            {
                progressEntries.map((entry, idx) => (
                    <ProgressBarEntryContainer key={idx} flex={entry.flex}>
                        <Progress color={entry.color} progress={entry.progress} />
                    </ProgressBarEntryContainer>
                ))
            }
        </ProgressBarContainer>
    )
}

export default ProgressBar