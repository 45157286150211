import styled from "styled-components";
import { spacing } from "@/ui/spacing"

export const Container = styled.div`

`;

export const TextContainer = styled.p`

`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: ${spacing.small4};
    margin-top: ${spacing.medium3};
`;
