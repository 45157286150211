import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const ActiveProcessesContainer = styled.div`
    margin-bottom: ${spacing.large2};
`;

export const TotalCount = styled.div`
`;

export const RowCountManager = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-right: ${spacing.small4};;
    }
    select {
        padding: 0 0 0 10px;
        height: 40px;
    }
`;

export const CardsContainer = styled.div`
    margin-top: ${spacing.small4};
`;

export const CountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;