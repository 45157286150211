export const hedwigAccordion = `
    .hw-accordion {
        &__trigger {
            > svg {
                display: none;
            }
        }
        &__icon {
            &:after {
                top: 0;
            }
            &:before {
                top: 0;
            }
        }
    }
`