import { Container, IconContainer } from './style';
import { fontClasses } from "@/ui/typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from "react-router-dom";

interface ISavedReportCard {
  title: string
  icon: IconProp
  link: string
};

const SavedReportCard = ({title, icon, link}: ISavedReportCard) => {
  return (
    <Link to={link}>
      <Container>
        <p className={fontClasses.technicalTitle}>{title}</p>
        {icon && <IconContainer>
            <FontAwesomeIcon className='fa-x' icon={icon}/>
        </IconContainer>}
      </Container>
    </Link>
  );
}

export default SavedReportCard;