import { barColors, colors } from "@/ui/colors";
import { fontClasses } from "@/ui/typography";
import { useEffect, useState } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { FloatingPieLabels } from "../customization";
import { ChartContainer } from "./style"

export interface IData {
  name: string,
  value: number,
  link?: string,
  fill?: string
}

interface IPieChart {
  data: IData[],
  chartTitle?: string,
  totalCount?: string
  titleTop?: boolean
  xLabelFormatter?: (data: IData) => string
}

const SimplePieChart = ({ data, totalCount, chartTitle, titleTop, xLabelFormatter }: IPieChart) => {
  const [dataToShow, setDataToShow] = useState<IData[]>([]);

  useEffect(() => {
    const isZero = data.every(item => item.value === 0);
    const updatedData = data?.map((d, index) => {
      return {
        value: isZero ? 1 : d.value,
        name: xLabelFormatter ? xLabelFormatter(d) : d.name,
        link: d.link ? d.link : undefined,
        fill: d.fill ? d.fill : barColors[index]
      }
    });
    setDataToShow(updatedData);
  }, [data])


  return (
    <ChartContainer>
      <ResponsiveContainer minHeight={430} width="100%">
        <PieChart>
          <Pie
            data={dataToShow}
            strokeWidth={'0px'}
            innerRadius={'80%'}
            outerRadius={'100%'}
            fill="#8884d8"
            dataKey="value"
          >
            <Label
              value={totalCount} position="centerBottom" className={fontClasses.hw1} dy={titleTop ? 40 : 5} fill={colors.primaryDarker}
            />
            <Label
              value={chartTitle} position="centerTop" className={fontClasses.technical} dy={titleTop ? -30 : 10} fill={colors.primaryDark}
            />
            {dataToShow.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <FloatingPieLabels labels={dataToShow.map((data) => {
        return {
          label: data.name,
          link: data.link,
          color: data.fill
        }
      })} />
    </ChartContainer>
  )
}

export default SimplePieChart;