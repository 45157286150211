import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const ReportsContainer = styled.div`
    margin-bottom: ${spacing.large2};
`;

export const InfoGraphicsContainer = styled.div `
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: nowrap;
    overflow: auto;
    align-self: stretch;
`;