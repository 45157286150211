import { StrictMode, Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, } from "react-router-dom";
import Error500 from "@/pages/Error/500";
import Error404 from "@/pages/Error/404";
import Dashboard from "@/pages/Dashboard"
import GlobalStyle from "@/ui/styles/globalStyle";
import UnitPageLayout from "@/pages/UnitPage/_Layout";
import DefaultLayout from "@/pages/_Layout";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getLoginRequest, msal } from "@/utils/auth";
import OperationalSurveyAdminLayout from "@/pages/Administration/OperationalSurvey";
import AdminLayout from '@/pages/Administration/AdminPanel/_Layout';
const supportedLanguages = ["no", "en"];

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    supportedLngs: supportedLanguages,
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      convertDetectedLanguage: language => {
        if (language.startsWith("no") || language === "nb" || language === "nn") return "no";
        if (language.startsWith("en")) return "en";
        return supportedLanguages.includes(language) ? language : "en";
      }
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    }
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<DefaultLayout />} handle={{ crumb: () => "dashboard.pageTitle" }}>
      <Route path="Unit/:unitNumber" element={<UnitPageLayout />}>
        <Route path="General" lazy={() => import("./pages/UnitPage/General")} handle={{ crumb: () => "unitPage.sideBarMenu.general" }} />
        <Route path="Contact" lazy={() => import("./pages/UnitPage/Contact")} handle={{ crumb: () => "unitPage.sideBarMenu.contact" }} />
        <Route path="MapAndArea" lazy={() => import("./pages/UnitPage/MapAndArea")} handle={{ crumb: () => "unitPage.sideBarMenu.mapAndArea" }} />
        <Route path="LegalInformation" lazy={() => import("./pages/UnitPage/LegalInformation")} handle={{ crumb: () => "unitPage.sideBarMenu.legal" }} />
        <Route path="ServicesAndEquipments" lazy={() => import("./pages/UnitPage/ServicesAndEquipments")} handle={{ crumb: () => "unitPage.sideBarMenu.servicesAndTools" }} />
        <Route path="OpeningHours" lazy={() => import("./pages/UnitPage/OpeningHours")} handle={{ crumb: () => "unitPage.sideBarMenu.openingTimeAndDeadline" }} />
        <Route path="Deals" lazy={() => import("./pages/UnitPage/Deals")} handle={{ crumb: () => "unitPage.sideBarMenu.appointments" }} />
        <Route path="OperationalSurvey" lazy={() => import("./pages/UnitPage/OperationalSurvey")} handle={{ crumb: () => "unitPage.sideBarMenu.operationalSurvey" }} />
      </Route>
      <Route path="IntlShipments" lazy={() => import("./pages/IntlShipments/_Layout")} handle={{ crumb: () => "intlShipments.title" }}>
        <Route path="Countries" lazy={() => import("./pages/IntlShipments/Countries")} handle={{ crumb: () => "intlShipments.countries.navTitle" }}></Route>
        <Route path="ChangeTexts" lazy={() => import("./pages/IntlShipments/ChangeTexts")} handle={{ crumb: () => "intlShipments.changeTexts.navTitle" }}></Route>
        <Route path="ChangeProperties" lazy={() => import("./pages/IntlShipments/ChangeProperties")} handle={{ crumb: () => "intlShipments.changeProperties.navTitle" }}></Route>
        <Route path="BulkUpdate" lazy={() => import("./pages/IntlShipments/BulkUpdate")} handle={{ crumb: () => "intlShipments.bulkUpdate.navTitle" }}></Route>
        <Route path="PriceZones" lazy={() => import("./pages/IntlShipments/PriceZones")} handle={{ crumb: () => "intlShipments.priceZones.navTitle" }}></Route>
        <Route path="Products" lazy={() => import("./pages/IntlShipments/Products")} handle={{ crumb: () => "intlShipments.products.navTitle" }}></Route>
      </Route>
      <Route path='' element={<Dashboard />} />
      <Route path="Error404" element={<Error404 />} handle={{ crumb: () => "404" }} />
      <Route path="*" element={<Error500 />} handle={{ crumb: () => "500" }} />
      <Route path='Reports' lazy={() => import("./pages/Reports")} handle={{ crumb: () => "reports.pageTitle" }} />
      <Route path='Reports/:idReport' lazy={() => import("./pages/Reports")} handle={{ crumb: () => "reports.pageTitle" }} />
      <Route path='OperationalSurvey/:idOperationalSurvey' lazy={() => import("./pages/OperationalSurvey")}
        handle={{ crumb: () => "operationalSurvey.operationalSurvey" }} />
      <Route path='OperationalVisit/:idOperationalVisit' lazy={() => import("./pages/OperationalVisit")} handle={{ crumb: () => "operationalVisit.visit" }} />
      <Route path="OperationalSurveyAdmin" element={<OperationalSurveyAdminLayout />} handle={{ crumb: () => "operationalSurveyAdmin.title" }}>
        <Route path="" lazy={() => import("./pages/Administration/OperationalSurvey/LandingPage")} />
        <Route path="OperationalSurveyTemplate/:operationalSurveyTemplateId"
          lazy={() => import("./pages/Administration/OperationalSurvey/TemplatePage")}
          handle={{ crumb: () => "operationalSurveyAdmin.template" }} />
      </Route>
      <Route path="Admin" element={<AdminLayout />} handle={{ crumb: () => "admin.title" }}>
        <Route path='' lazy={() => import("./pages/Administration/AdminPanel/Pages/Home")} handle={{ crumb: () => "admin.sideBarMenu.deals.title" }} />
        <Route path="Deals" handle={{ crumb: () => "admin.sideBarMenu.deals.title" }} >
          <Route path="DocumentType" lazy={() => import("./pages/Administration/AdminPanel/Pages/Deals/DocumentType")} handle={{ crumb: () => "admin.sideBarMenu.deals.documentType" }} />
          <Route path="ConsumerPriceIndex" lazy={() => import("./pages/Administration/AdminPanel/Pages/Deals/ConsumerPriceIndex")} handle={{ crumb: () => "admin.sideBarMenu.deals.consumerPriceIndex" }} />
          <Route path="OptionTypes" lazy={() => import("./pages/Administration/AdminPanel/Pages/Deals/OptionTypes")} handle={{ crumb: () => "admin.sideBarMenu.deals.optionTypes" }} />
        </Route>
        <Route path="OpeningHoursAndDeadlines" handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.title" }} >
          <Route path="OpeningStatusType" lazy={() => import("./pages/Administration/AdminPanel/Pages/OpeningHoursAndDeadlines/OpeningStatusType")} handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.openingStatusType" }} />
          <Route path="OpeningHoursType" lazy={() => import("./pages/Administration/AdminPanel/Pages/OpeningHoursAndDeadlines/OpeningHoursType")} handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.openingHoursType" }} />
          <Route path="DeadlinePeriodType" lazy={() => import("./pages/Administration/AdminPanel/Pages/OpeningHoursAndDeadlines/DeadlinePeriodType")} handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.deadlinePeriodType" }} />
          <Route path="DeadlineType" lazy={() => import("./pages/Administration/AdminPanel/Pages/OpeningHoursAndDeadlines/DeadlineType")} handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.deadlineType" }} />
          <Route path="SpecialOpeningHourTemplate" lazy={() => import("./pages/Administration/AdminPanel/Pages/OpeningHoursAndDeadlines/SpecialOpeningHourTemplate")} handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.specialOpeningHourTemplate" }} />
          <Route path="MessageTemplate" lazy={() => import("./pages/Administration/AdminPanel/Pages/OpeningHoursAndDeadlines/MessageTemplate")} handle={{ crumb: () => "admin.sideBarMenu.openingHoursAndDeadlines.messageTemplate" }} />
        </Route>
        <Route path="Units" handle={{ crumb: () => "admin.sideBarMenu.units.title" }} >
          <Route path="UnitTypes" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/UnitTypes")} handle={{ crumb: () => "admin.sideBarMenu.units.unitTypes" }} />
          <Route path="UnitGroups" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/UnitGroups")} handle={{ crumb: () => "admin.sideBarMenu.units.unitGroups" }} />
          <Route path="AdditionalFunctionality" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/AdditionalFunctionality")} handle={{ crumb: () => "admin.sideBarMenu.units.additionalFunctionality" }} />
          <Route path="OrganizationTypes" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/OrganizationTypes")} handle={{ crumb: () => "admin.sideBarMenu.units.organizationTypes" }} />
          <Route path="History" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/History")} handle={{ crumb: () => "admin.sideBarMenu.units.history" }} />
          <Route path="OptionalMailOfficeShown" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/OptionalMailOffice")} handle={{ crumb: () => "admin.sideBarMenu.units.optionalMailOfficeShown" }} />
          <Route path="DistributionType" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/DistributionType")} handle={{ crumb: () => "admin.sideBarMenu.units.distributionType" }} />
          <Route path="EstablishmentStatusType" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/EstablishmentStatusType")} handle={{ crumb: () => "admin.sideBarMenu.units.establishmentStatusType" }} />
          <Route path="ContactPointType" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/ContactPointType")} handle={{ crumb: () => "admin.sideBarMenu.units.contactPointType" }} />
          <Route path="Role" lazy={() => import("./pages/Administration/AdminPanel/Pages/Units/Role")} handle={{ crumb: () => "admin.sideBarMenu.units.role" }} />
        </Route>
        <Route path="PbeSystem" handle={{ crumb: () => "admin.sideBarMenu.pbeSystem.title" }} >
          <Route path="Users" lazy={() => import("./pages/Administration/AdminPanel/Pages/PbeSystem/Users")} handle={{ crumb: () => "admin.sideBarMenu.pbeSystem.users" }} />
          <Route path="Configuration" lazy={() => import("./pages/Administration/AdminPanel/Pages/PbeSystem/Configuration")} handle={{ crumb: () => "admin.sideBarMenu.pbeSystem.configuration" }} />
          <Route path="ArticlesAndFooter" lazy={() => import("./pages/Administration/AdminPanel/Pages/PbeSystem/ArticlesAndFooter")} handle={{ crumb: () => "admin.sideBarMenu.pbeSystem.articlesAndFooter" }} />
        </Route>
        <Route path="Areas" handle={{ crumb: () => "admin.sideBarMenu.areas.title" }} >
          <Route path="County" lazy={() => import("./pages/Administration/AdminPanel/Pages/Areas/County")} handle={{ crumb: () => "admin.sideBarMenu.areas.county" }} />
          <Route path="Land" lazy={() => import("./pages/Administration/AdminPanel/Pages/Areas/Land")} handle={{ crumb: () => "admin.sideBarMenu.areas.land" }} />
          <Route path="Municipality" lazy={() => import("./pages/Administration/AdminPanel/Pages/Areas/Municipality")} handle={{ crumb: () => "admin.sideBarMenu.areas.municipality" }} />
        </Route>
        <Route path="PostalCodes" handle={{ crumb: () => "admin.sideBarMenu.postalCodes.title" }} >
          <Route path="Denmark" lazy={() => import("./pages/Administration/AdminPanel/Pages/PostalCodes/Denmark")} handle={{ crumb: () => "admin.sideBarMenu.postalCodes.denmark" }} />
          <Route path="Finland" lazy={() => import("./pages/Administration/AdminPanel/Pages/PostalCodes/Finland")} handle={{ crumb: () => "admin.sideBarMenu.postalCodes.finland" }} />
          <Route path="Norway" lazy={() => import("./pages/Administration/AdminPanel/Pages/PostalCodes/Norway")} handle={{ crumb: () => "admin.sideBarMenu.postalCodes.norway" }} />
          <Route path="Sweden" lazy={() => import("./pages/Administration/AdminPanel/Pages/PostalCodes/Sweden")} handle={{ crumb: () => "admin.sideBarMenu.postalCodes.sweden" }} />
        </Route>
        <Route path="ServicesAndTools" handle={{ crumb: () => "admin.sideBarMenu.servicesAndTools.title" }} >
          <Route path="Services" lazy={() => import("./pages/Administration/AdminPanel/Pages/ServicesAndTools/Services")} handle={{ crumb: () => "admin.sideBarMenu.servicesAndTools.services" }} />
          <Route path="ServiceCategories" lazy={() => import("./pages/Administration/AdminPanel/Pages/ServicesAndTools/ServicesCategories")} handle={{ crumb: () => "admin.sideBarMenu.servicesAndTools.serviceCategories" }} />
          <Route path="Tools" lazy={() => import("./pages/Administration/AdminPanel/Pages/ServicesAndTools/Tools")} handle={{ crumb: () => "admin.sideBarMenu.servicesAndTools.tools" }} />
          <Route path="ToolCategories" lazy={() => import("./pages/Administration/AdminPanel/Pages/ServicesAndTools/ToolCategories")} handle={{ crumb: () => "admin.sideBarMenu.servicesAndTools.toolCategories" }} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  const request = getLoginRequest()
  const { login, error } = useMsalAuthentication(InteractionType.Silent, request);
  const { inProgress } = useMsal();

  useEffect(() => {
    if (error) login(InteractionType.Redirect, request)
  }, [error])

  useEffect(() => {
    const spinnerText = document.getElementById("spinner-text")
    if (!spinnerText) return
    switch (inProgress) {
      case "login":
      case "handleRedirect":
      case "acquireToken":
      case "ssoSilent":
        spinnerText.innerText = "Logging in";
        break
      default:
        spinnerText.innerText = "Loading PBE";
        break
    }
  }, [inProgress]);

  return (
    <div className="App">
      <GlobalStyle />
      <AuthenticatedTemplate>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </AuthenticatedTemplate>
    </div>
  );
}

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Suspense fallback={"Loading..."}>
      <MsalProvider instance={msal}>
        <App />
      </MsalProvider>
    </Suspense>
  </StrictMode>
)
