import styled from 'styled-components';

export const headerStyles = `
    .hw-navbar {
        &__menu {
            &-button-label-menu {
                min-width: 70px;
            }
        }
        &__search {
            & .hw-search-input {
                width: 490px;
                width: calc(70% - 40px);
            }
        }
    }
`