import SectionTitle from '@/components/dashboard/section-title';
import { NewServicesAndEquipmentsContainer, TileContainer } from './style';
import { t } from 'i18next';
import NewServiceAndEquipmentTile from '@/components/dashboard/new-service-and-equipment-tile';

interface INewServicesAndEquipments {
    newServicesAndTools: {
        name: string,
        note?: string,
    }[]
}

const NewServicesAndEquipments = ({ newServicesAndTools }: INewServicesAndEquipments) => {
    return (
        <>
            {newServicesAndTools?.length > 0 && <NewServicesAndEquipmentsContainer>
                <SectionTitle title={t('dashboard.newServicesAndEquipments.title')} />
                <TileContainer>
                    {newServicesAndTools.map((nsat, index) =>
                        <NewServiceAndEquipmentTile name={nsat.name} note={nsat.note} key={index} />)}
                </TileContainer>
            </NewServicesAndEquipmentsContainer>}
        </>

    );
}

export default NewServicesAndEquipments;