import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const SectionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.medium3};
    margin-bottom: ${spacing.small4};
`;

export const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        padding-left: ${spacing.small2};
    }
    path {
        color: ${colors.primaryDarker};
    }
`;