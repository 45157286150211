import BaseDropdown from "@/components/form-components/base-dropdown"
import { InputVariants } from "@/models/forms/formTypes"

interface IDropdownProps {
  name?: string
  id?: string
  label?: string
  errorMessage?: string
  options?: {
    label: string
    value: string | number
  }[] | undefined
  required?: boolean
  disabled?: boolean
  selected?: number | string | undefined
  variant?: InputVariants
  undefinedLabel?: string
  onChange?: (e: any) => void
  onErrorMessage?: (text: string) => void
  readOnly?: boolean
  ariaControls?: string,
  ariaLabel?: string,
  // Size for Drop Down => Small, Medium, Large(Default)
  size?: string
}

const Dropdown = ({
  label,
  name,
  variant,
  id,
  selected,
  disabled,
  undefinedLabel,
  onChange,
  options,
  errorMessage,
  ariaControls,
  ariaLabel,
  size
}: IDropdownProps) => {

  let width, height;
  switch (size) {
    case 'xs':
      width = "74";
      break;
    case 'small':
      width = "99";
      break;
    case 'medium':
      width = "113.5";
      break;
    default:
      break;
  }
  return (
    <BaseDropdown
      variant={variant}
      label={label}
      name={name}
      id={id}
      undefinedLabel={undefinedLabel}
      selected={selected}
      disabled={disabled}
      onChange={onChange}
      options={options}
      errorMessage={errorMessage}
      ariaControls={ariaControls}
      ariaLabel={ariaLabel}
      width={width}
      height={height}
    />
  )
}
export default Dropdown;