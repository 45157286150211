interface IFontSize {
  tiny: string;
  small: string;
  regular: string;
  medium: string;
  large: string;
}

interface IFontFamily {
  light: string;
  regular: string;
  medium: string;
  bold: string;
}

export const fontSize: IFontSize = {
  tiny: `font-size: 0.875rem; line-height: 1.3125rem`,
  small: `font-size: 1rem; line-height: 1.5rem`,
  regular: `font-size: 1.125rem; line-height: 1.6875rem`,
  medium: `font-size: 1.25rem; `,
  large: `font-size: 1.375rem; line-height: 2.0625rem`,
};

export const fontFamily: IFontFamily = {
  light: 'font-family: var(--hw-font-primary-light)',  // `font-family: Posten Sans Light`,
  regular: 'font-family: var(--hw-font-primary-regular)',  // `font-family: 'Posten Sans Regular'`,
  medium: 'font-family: var(--hw-font-primary-medium)', //  `font-family: 'Posten Sans Medium'`,
  bold: 'font-family: var(--hw-font-primary-bold)', //  `font-family: 'Posten Sans Bold'`,
}; 

interface IHewdigFontClasses {
  hw1: string
  hw2: string
  hw3: string
  technical: string
  technicalTitle: string
  caption: string
  captionTitle: string
  bodyTitle: string
  bodySmallTitle: string
  small: string
  uppercase: string
  center: string
  right: string
}

interface IHedwigFontAdjustmentClasses {
  small: string
  uppercase: string
  center: string
  right: string
}

interface IHedwigFontSizes {
  h1Large: string
  h1: string
  h2: string
  h3: string
  h4: string
  h5: string
  h6: string
  p: string
  lead: string
  caption: string
  technical: string
  small: string
  smaller: string
  smallest: string
  cardTitle: string
}

export const hedwigFontSizes: IHedwigFontSizes = {
  h1Large: 'var(--hw-font-size-h1-large)',
  h1: 'var(--hw-font-size-h1)',
  h2: 'var(--hw-font-size-h2)',
  h3: 'var(--hw-font-size-h3)',
  h4: 'var(--hw-font-size-h4)',
  h5: 'var(--hw-font-size-h5)',
  h6: 'var(--hw-font-size-h6)',
  p: 'var(--hw-font-size-p)',
  lead: 'var(--hw-font-size-lead)',
  caption: 'var(--hw-font-size-caption)',
  technical: 'var(--hw-font-size-technical)',
  small: 'var(--hw-font-size-small)',
  smaller: 'var(--hw-font-size-smaller)',
  smallest: 'var(--hw-font-size-smallest)',
  cardTitle: 'var(--hw-font-size-cardTitle)',
}

export const fontAdjustmentClasses: IHedwigFontAdjustmentClasses = {
  small: 'hw-text-small',
  uppercase: 'hw-text-uppercase',
  center: 'hw-text-center',
  right: 'hw-text-right'
}

export const fontClasses: IHewdigFontClasses = {
  hw1: 'hw-h1',
  hw2: 'hw-h2',
  hw3: 'hw-h3',
  technical: 'hw-text-technical',
  technicalTitle: 'hw-text-technical-title',
  caption: 'hw-text-caption',
  captionTitle: 'hw-text-caption-title',
  bodyTitle: 'hw-text-title',
  bodySmallTitle: `hw-text-title ${fontAdjustmentClasses.small}`,
  small: fontAdjustmentClasses.small,
  uppercase: fontAdjustmentClasses.uppercase,
  center: fontAdjustmentClasses.center,
  right: fontAdjustmentClasses.right
}
