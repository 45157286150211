import { useSearchParams } from "react-router-dom";
import SearchField from "@/components/base/search/search-field";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputVariants } from "@/models/forms/formTypes";

interface ISearch {
  variant?: InputVariants;
  searchParamName?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
  onSearchButtonClick?: (searchTerm?: string) => void
  onSearch?: (searchTerm?: string) => void;
}

const Search = ({variant, searchParamName, onSearchButtonClick, onBlur, onSearch}: ISearch) => {
  const {t} = useTranslation(['translation']);
  const [searchValue, setSearchValue] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setSearchValue(searchParams.get(searchParamName ?? 'q') ?? '');
  }, [searchParams])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  }

  const handleSearch = () => {
    updateQueryParameter(searchValue);
    if (typeof onSearch === "function") onSearch(searchValue);
  }

  function _onBlur(e: FocusEvent<HTMLInputElement>) {
    updateQueryParameter(searchValue);
    if (typeof onBlur === "function") onBlur(e);
  }

  const updateQueryParameter = (value: string) => {
    if (value && value !== '') {
      searchParams.set(searchParamName ?? 'q', value);
    } else {
      searchParams.delete(searchParamName ?? 'q');
    }
    setSearchParams(searchParams, {replace: true});
  };

  const handleClear = () => {
    setSearchValue('');
    updateQueryParameter('');
  };

  return (
    <SearchField
      value={searchValue}
      variant={variant}
      placeholder={t('reports.findUnit')}
      onChange={handleChange}
      onBlur={_onBlur}
      onEnter={handleSearch}
      onClear={handleClear}
      onSearchButtonClick={onSearchButtonClick}
      buttonLabel={t('common.search')}
    />
  );
};

export default Search;
