import styled from "styled-components";
import { spacing } from "@/ui/spacing"
import { colors } from "@/ui/colors";

export const MessageCard = styled.div`
        
`;

export const ButtonContainer = styled.div`
`;

export const MessageListContainer = styled.div`
    &.with-border > div:not(:first-child) {
        border-top: 1px solid ${colors.borderColor};
    }
`;

export const ContentWrapper = styled.div`
    flex: 1 0 auto;
`;