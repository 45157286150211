import SectionTitle from "@/components/dashboard/section-title";
import { useTranslation } from "react-i18next";
import { InfoGraphicsContainer, ReportsContainer } from "./style";
import InfoGraphicCard from '@/components/dashboard/info-graphic-card'
import { faSitemap } from "@fortawesome/pro-regular-svg-icons/faSitemap";
import { faHashtag } from "@fortawesome/pro-regular-svg-icons/faHashtag";
import { faAddressBook } from "@fortawesome/pro-regular-svg-icons/faAddressBook";

const Reports = () => {
  const {t} = useTranslation(['translation']);
  return (<>
    <ReportsContainer>
      <SectionTitle title={t('dashboard.reports.title')} link={"/Reports"} linkText={t('dashboard.reports.toReports')}/>
      <InfoGraphicsContainer>
        <InfoGraphicCard title={t('dashboard.reports.organization')} icon={faSitemap} link="#"/>
        <InfoGraphicCard title={t('dashboard.reports.postNumber')} icon={faHashtag} link="#"/>
        <InfoGraphicCard title={t('dashboard.reports.contactInformation')} icon={faAddressBook} link="#"/>
      </InfoGraphicsContainer>
    </ReportsContainer>
  </>)
}

export default Reports;