import { IFooter } from '@/models/UnitPage/basePage';
import { BottomContainer, FooterWrapper, LogoLinkWrapper } from "./style";
import FooterContent from "./content";
import { Link } from "react-router-dom";
import { PostenBringLogo } from "@/ui/icons"
import { useCheckMobileScreen } from "@/helpers/screenSizeDetector";

type Props = {
  content: IFooter
}

export default function Footer({content}: Props) {
  const isMobile = useCheckMobileScreen();

  return (
    <>
      <FooterWrapper className="hw-footer">
        <div className="hw-container">
          <div className="hw-block">
            <div className="hw-footer__desktop">
              <div className="hw-block hw-flex">
                <div className="hw-block">
                  <FooterContent articleItems={content?.leftColumn?.articleItems}/>
                </div>
                <div className="hw-block">
                  <FooterContent articleItems={content?.rightColumn?.articleItems}/>
                </div>
              </div>
            </div>
            <div className="hw-footer__mobile">
              <div className="hw-block">
                <FooterContent articleItems={content?.leftColumn?.articleItems}/>
              </div>
              <div className="hw-block">
                <FooterContent articleItems={content?.rightColumn?.articleItems}/>
              </div>
            </div>
            <div className="hw-footer__shared">
              <div className="hw-container">
                <div className="hw-block">
                  <div className="hw-footer__logo">
                    <Link to={"/"}>
                      <LogoLinkWrapper className="hw-footer__logo-link" aria-label={'Posten Bring Logo'}>
                        <PostenBringLogo/>
                      </LogoLinkWrapper>
                    </Link>
                  </div>
                  <div className="hw-hr hw-block--mt-smallest"></div>
                  <BottomContainer className="hw-block hw-block--mt">
                    <span className="hw-copyright">© Posten Bring</span>
                    {isMobile && <br/>}
                    <a href="#" className="hw-link">Privacy and security</a>
                    <a href="#" className="hw-link">Cookies</a>
                    {isMobile && <br/>}
                  </BottomContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterWrapper>
    </>
  )
}
