import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { scrollToTop } from "@/helpers/scroll";

export default function OperationalSurveyAdminLayout() {
  useEffect(()=>{
    scrollToTop()
  },[])
  return (<Outlet/>)
}
