import styled from "styled-components";
import { spacing } from "@/ui/spacing"

export const DatePickerContainer = styled.div`
    max-width: 350px;
`;

export const DateRangeLabels = styled.div`
    display: flex;
`;

export const FromLabel = styled.label`
    width: 111px;
    margin-right: 45px;
`;

export const ToLabel = styled.label`
    width: 111px;

`;

export const ErrorMessage = styled.span`
    &::before {
        top: ${spacing.small1};
        left: 0;
    }
`;

export const InputGroup = styled.span`
    display: flex;
`;
