import SearchSuggestion, { ISearchSuggestion } from "../search-suggestion"

interface ISearchSuggestionsProps {
  suggestions?: ISearchSuggestion[]
  focusIdx?: number
}

const SearchSuggestions = ({suggestions, focusIdx}: ISearchSuggestionsProps) => {
  return (
    <ul className="hw-search__suggestions" style={{zIndex: 400}}>
      {
        suggestions?.map((s, idx) => (
          <SearchSuggestion key={idx}
                            title={s.title}
                            scrollSuggestionIntoView={s.scrollSuggestionIntoView}
                            focus={idx === focusIdx}
                            href={s.href}
                            subTitle={s.subTitle}
                            onClick={s.onClick}/>
        ))
      }
    </ul>
  )
}

export default SearchSuggestions
