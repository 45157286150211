import { useTranslation } from "react-i18next";
import { InfoGraphicsContainer, ReportsContainer } from "./style";
import SectionTitle from "@/components/dashboard/section-title";
import SavedReportCard from '@/components/dashboard/saved-reports-card'
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";

interface IMyReports {
  savedReports: {
    name: string,
    id: number,
  }[]
}

const MyReports = ({savedReports}: IMyReports) => {
  const {t} = useTranslation(['translation']);
  return (<>
    <ReportsContainer>
      <SectionTitle title={t('dashboard.myReports.title')} link={"/Reports"} linkText={t('dashboard.myReports.toReports')}/>
      <InfoGraphicsContainer>
        {savedReports.length >= 1 ?
          savedReports.map(sr => {
            return (
              <div key={sr.id}>
                <SavedReportCard title={sr.name} icon={faPaperclip} link={`/Reports/${sr.id}`}/>
              </div>)
          }) :
          <>
            <SavedReportCard title={t('dashboard.myReports.createNewFavouriteReport')} icon={faArrowRight} link={`/Reports`}/>
          </>
        }
      </InfoGraphicsContainer>
    </ReportsContainer>
  </>)
}


export default MyReports;
