export const saveFile = (blob: Blob, fileName: string) => {
    var a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url);
}