import { Footer, Header, PartText, ProgressPart, UnitCardContainer, UnitInformation } from './style'
import { fontClasses } from '@/ui/typography'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProgressBar from '@/components/progress-bar'
import { Link } from "react-router-dom";

interface IUnitCard {
  unitNumber: string,
  unitName: string,
  noOfUnits?: number,
  progress?: number
  currentCompleted?: number
  goal?: number
}

const UnitCard = ({ unitNumber, unitName, progress, currentCompleted, goal, noOfUnits }: IUnitCard) => {
  return (
    <Link to={`/unit/${unitNumber}/General`}>
      <UnitCardContainer>
        <Header>
          <UnitInformation>
            <p className={fontClasses.technicalTitle + ' unit-number'}>{unitNumber}</p>
            <p className={fontClasses.technicalTitle}>{unitName}</p>
            {noOfUnits && <p className={fontClasses.captionTitle}>&nbsp;{`(${noOfUnits})`}</p>}
          </UnitInformation>
          {progress != null ? <p className={fontClasses.bodySmallTitle}>{progress === 0 ? `0 %` : `${Math.round(progress)} %`}</p> :
            <FontAwesomeIcon icon={faArrowRight} />}
        </Header>
        {progress != null && <ProgressPart>
          <ProgressBar progress={progress} />
        </ProgressPart>}

        {progress != null && <Footer>
          <PartText className={fontClasses.hw2}>
            {currentCompleted}/{goal ?? 0}
          </PartText>
          <FontAwesomeIcon icon={faArrowRight} />
        </Footer>
        }
      </UnitCardContainer>
    </Link>
  )
}
export default UnitCard
