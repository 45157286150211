import { fontClasses } from "@/ui/typography";
import { BannerContainer, H1Loading, LoadingHeaderContainer, PLoading } from "./style"

interface IHeroBannerProps {
  topTitle?: string
  title?: string
  loading?: boolean
}

const HeroBanner = ({topTitle, title, loading}: IHeroBannerProps) => {
  return (
    <BannerContainer>
      {
        loading ? (
          <>
            <PLoading className="loading-content"/>
            <LoadingHeaderContainer>
              <H1Loading width={400} className="loading-content"/>
            </LoadingHeaderContainer>
          </>
        ) : (
          <>
            <p className={fontClasses.bodySmallTitle}>{topTitle ?? ''}</p>
            <h1 className={fontClasses.hw1}>{title ?? ''}</h1>
          </>
        )
      }
    </BannerContainer>
  );
}
export default HeroBanner;
