import { ButtonLoading, CloseIcon, Icon, IconLoading, MenuButtonWrapper, SideBarContainer, SideBarItem, SpanLoading } from "./style"
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowFromLeft } from "@fortawesome/pro-regular-svg-icons/faArrowFromLeft";
import { faArrowLeftFromLine } from "@fortawesome/pro-regular-svg-icons/faArrowLeftFromLine";
import { useLocation } from "react-router-dom";
import PbeButton from "@/components/base/button";

interface ISideBarProps extends PropsWithChildren<any> {
  loading: boolean
  loadingSpinners?: number
}

const SideBar = ({ loading, loadingSpinners, children }: ISideBarProps) => {
  const { t } = useTranslation(['translation']);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  function onOpen() {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  return <>
    <MenuButtonWrapper>
      {
        loading ? (
          <ButtonLoading className="loading-content" />
        ) : (
          <PbeButton variant={"secondary"} size={'large'} onClick={onOpen} iconRigth={true} icon={faArrowFromLeft}>{t('common.menu')}</PbeButton>
        )
      }
    </MenuButtonWrapper>
    <SideBarContainer className="hw-container" sidebaropens={isOpen ? true : undefined}>
      <CloseIcon onClick={onOpen}><FontAwesomeIcon icon={faArrowLeftFromLine} /></CloseIcon>
      {
        loading ? (
          <>
            {[...new Array(loadingSpinners ?? 1)].map((_, i) => (
              <SideBarItem key={i}>
                <IconLoading className="loading-content" />
                <SpanLoading className="loading-content" width={4} />
              </SideBarItem>
            ))}
          </>
        ) : children
      }
    </SideBarContainer>
  </>
}

export { SideBarItem, Icon };
export default SideBar;
