import { useTranslation } from 'react-i18next';
import { Column, Container, LogoutButton } from './style';
import { fontClasses } from '@/ui/typography';
import { Link } from "react-router-dom";
import { INavbarDrawerLinkGroup } from "@/components/navbar-drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/pro-regular-svg-icons/faDoorOpen";
import { logout } from "@/utils/auth";
import { useEffect, useState } from 'react';
import Dropdown from '../form-components/dropdown';
import RoleChanger from "@/components/role-changer";

const HeaderUserInfo = ({ groups, showRoleChanger }: { groups: INavbarDrawerLinkGroup[], showRoleChanger: boolean }) => {
  const { t, i18n } = useTranslation(['translation']);
  const [language, setLanguage] = useState(i18n.language);
  useEffect(() => {
    i18n.changeLanguage(language)
    return;
  }, [language]);

  const languageOptions = [
    { label: 'Norsk', value: 'no' },
    { label: 'English', value: 'en' }]

  return (
    <Container>
      {
        (groups ?? []).map((group, idx) => (
          <Column key={idx}>
            <h5>{group.translate ? t(group.text) : group.text}</h5>
            <ul>
              {
                (group.links ?? []).map((link, idx) => (
                  <li key={idx}>
                    {link.href && link.href !== "#" ?
                      <Link to={link.href} className={fontClasses.technical}>{link.translate ? t(link.text) : link.text}</Link>
                      : <span className={fontClasses.technical}>{link.translate ? t(link.text) : link.text}</span>}
                  </li>
                )
                )
              }
              {(group.text == "header.loggedInAs" && <LogoutButton title={t("common.logOutTitle")} onClick={() => logout()} onTouchEnd={() => logout()}>
                {t("common.logOut")}<FontAwesomeIcon icon={faDoorOpen} />
              </LogoutButton>)}
            </ul>
          </Column>
        ))
      }
      {showRoleChanger &&
        <Column>
          <RoleChanger />
        </Column>}
      <Column>
        <h5>{t('header.language')}</h5>
        <Dropdown
          id="languageChange"
          name="languageChange"
          label={t('header.chooseLanguage')}
          options={languageOptions}
          selected={languageOptions?.find(l => l.value == i18n.language).value}
          onChange={(e) => setLanguage(e.target.value)}
        />
      </Column>
    </Container>
  );
};

export default HeaderUserInfo;
