export const formatDate = (date: string | Date | undefined): string | undefined => {
  if (!date) return undefined;
  if (typeof date === "string") return date.toString();
  if (!(date instanceof Date)) return undefined;
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  let mmString = mm.toString();
  let ddString = dd.toString();
  if (dd < 10) ddString = '0' + dd;
  if (mm < 10) mmString = '0' + mm;
  return `${ddString}/${mmString}/${yyyy}`;
}

export const isNowInsideDateRange = (startDate: Date, endDate: Date) => {
  const now = new Date()
  return startDate < now && endDate > now
}

export function todayPlusDays(days: number, zeroHours: boolean = false) {
  const date = new Date()
  date.setDate(date.getDate() + days)
  if (zeroHours) date.setHours(0, 0, 0, 0)
  return date
}

export function formatLocalTime(date: string | Date): Date | undefined {
  if (!date) return undefined;
  if (date instanceof Date) return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
  if (typeof date == "string") return new Date(date + "Z");
  throw new Error("Invalid type on date argument: " + typeof date);
}

export const dateToUTC = (date: Date) => {
  if (!date) return date;
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}
