import { spacing, cardSpacing } from "@/ui/spacing";
import styled from "styled-components";

export const AccordionContainer = styled.div`
    border-radius: 2px;
`;

export const AccordionContent = styled.div`
    display: none;
    &.open {
        display: block;
    }
    ${cardSpacing.padding};
    padding-top: 0!important;
`;

export const AccordionHeader = styled.div`
    display: flex;
    align-items: center;
    height: 88px;
    cursor: pointer;
    ${cardSpacing.padding};
`;

export const HeaderIcon = styled.div`
    display: flex;
`;

export const HeaderText = styled.span`
    flex: 1;
    margin-left: ${spacing.medium2};
`;

export const HeaderCollapse = styled.span`
    & {
        .open-close-icon {
            transform: rotate(180deg);
        }
    }
    &.open {
        .open-close-icon {
            transform: rotate(0);
        }
    }
`;