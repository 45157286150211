import { getLoginRequest, getToken, msal } from '@/utils/auth';
import { saveFile } from '@/utils/file-saver'
import { IDeleteResponse, IFetchResponse, IPostResponse } from '@/models/api/apiResponse';

export const HTTP_STATUS_CODES = {
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  ServerError: 500
}

interface ISimpleFetch {
  endpoint: string;
  onSuccess?: () => void;
}

const ERROR_STATUS = HTTP_STATUS_CODES.ServerError
export const impersonatingAsKey = "impersonatingAs"
export const impersonatingAsHeader = "X-Impersonating-As"

async function handle401(res: Response) {
  if (res.status === 401) {
    console.log("Server returned 401, performing loginRedirect")
    const loginFailCountKey = "lfck"
    let failCount = sessionStorage.getItem(loginFailCountKey)
    if (!failCount) failCount = "0";
    else failCount = (parseInt(failCount) + 1).toString();
    if (failCount === "3") {
      alert("Login failed 3 times, please have patience as we resolve this error");
      sessionStorage.removeItem(loginFailCountKey)
      return
    } else sessionStorage.setItem(loginFailCountKey, failCount);
    await msal.clearCache()
    await msal.loginRedirect(getLoginRequest())
  }
}

async function commonHeaders() {
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Authorization': "Bearer " + await getToken()
  }
  const impersonatingAs = sessionStorage.getItem(impersonatingAsKey)
  if (impersonatingAs) headers[impersonatingAsHeader] = impersonatingAs;
  return headers;
}

export function getApiUrl(path: string) {
  return process.env.BACKEND_API_URL + path;
}

export async function pureAuthenticatedFetch(endpoint: string, init?: RequestInit) {
  if (!init) init = {}
  if (!init.headers) init.headers = {}
  init.headers = {...init.headers, ...await commonHeaders()}
  return fetch(getApiUrl(endpoint), init)
}

export const simpleFetch = async <T>({
                                       endpoint,
                                       onSuccess,
                                     }: ISimpleFetch): Promise<IFetchResponse<T>> => {
  if (!endpoint) return {ok: false, status: -1}
  try {
    const res = await fetch(getApiUrl(endpoint), {
      headers: await commonHeaders(),
    });

    await handle401(res);

    if (res.status === 200) {
      const resjson = await res.json();

      if (onSuccess) {
        onSuccess()
      }

      return {
        body: resjson,
        ok: res.ok,
        status: res.status
      };

    }
    return {
      ok: res.ok,
      status: res.status
    }
  } catch (err) {
    return {
      ok: false,
      status: ERROR_STATUS
    }
  }
};

interface ISimpleFileFetch {
  endpoint: string;
  fileName: string;
}

export const simpleFileFetch = async ({
                                        endpoint,
                                        fileName,
                                      }: ISimpleFileFetch): Promise<IFetchResponse<undefined>> => {
  if (!endpoint) return {ok: false, status: -1}
  try {
    const res = await fetch(getApiUrl(endpoint), {
      headers: await commonHeaders()
    });

    await handle401(res);

    if (res.status === 200) {
      const blob = await res.blob();
      saveFile(blob, fileName)
    }
    return {
      ok: res.ok,
      status: res.status
    }
  } catch (err) {
    return {
      ok: false,
      status: ERROR_STATUS
    }
  }
};

interface ISimpleBlobFetch {
  endpoint: string;
}

export const simpleBlobFetch = async ({
                                        endpoint,
                                      }: ISimpleBlobFetch): Promise<IFetchResponse<Blob>> => {
  if (!endpoint) return {ok: false, status: -1}
  try {
    const res = await fetch(getApiUrl(endpoint), {
      headers: await commonHeaders()
    });

    await handle401(res);

    if (res.status === 200) {
      const blob = await res.blob();
      return {
        ok: res.ok,
        body: blob,
        status: res.status
      }
    }
    return {
      ok: res.ok,
      status: res.status
    }
  } catch (err) {
    return {
      ok: false,
      status: ERROR_STATUS
    }
  }
};

interface ISimplePost {
  endpoint: string;
  body?: any;
  contentType?: string;
  onSuccess?: () => void;
}

export const simplePost = async <T>({
                                      endpoint,
                                      contentType = 'application/json',
                                      body,
                                      onSuccess,
                                    }: ISimplePost): Promise<IPostResponse<T>> => {
  if (!endpoint) return {ok: false, status: -1}
  try {
    const res = await fetch(getApiUrl(endpoint), {
      method: "POST",
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': contentType,
        ...await commonHeaders()
      }
    })

    await handle401(res);

    if (res.status === 200) {
      if (onSuccess) onSuccess();
      let json;
      try {
        json = await res.json();
      } catch { }
      return {
        body: json,
        ok: res.ok,
        status: res.status
      };
    }

    return {
      ok: res.ok,
      status: res.status
    }
  } catch (err) {
    return {
      ok: false,
      status: ERROR_STATUS
    }
  }
};

interface ISimpleFormDataPost {
  endpoint: string;
  body: FormData;
  contentType?: string;
  onSuccess?: () => void;
}

export const simpleFormDataPost = async <T>({
                                              endpoint,
                                              body,
                                              onSuccess,
                                            }: ISimpleFormDataPost): Promise<IPostResponse<T>> => {
  if (!endpoint) return {ok: false, status: -1}
  try {
    const res = await fetch(getApiUrl(endpoint), {
      method: "POST",
      headers: await commonHeaders(),
      body: body,
    });

    await handle401(res);

    if (res.status === 200) {
      if (onSuccess)
        onSuccess();

      let resjson;
      try {
        resjson = await res.json();
      } catch {
        console.error("Failed to parse response as json", res);
      }
      return {
        body: resjson,
        ok: res.ok,
        status: res.status
      };
    }

    return {
      ok: res.ok,
      status: res.status
    }

  } catch (err) {
    return {
      ok: false,
      status: ERROR_STATUS
    }
  }
};

export const simpleDelete = async <T>({
                                        endpoint,
                                        onSuccess,
                                      }: {
  endpoint: string;
  onSuccess?: () => void;
}): Promise<IDeleteResponse<T>> => {
  if (!endpoint) return {ok: false, status: -1}
  try {
    const res = await fetch(getApiUrl(endpoint), {
      method: 'DELETE',
      headers: await commonHeaders(),
    });

    await handle401(res);

    if (res.status === 200) {
      if (onSuccess)
        onSuccess();

      let resjson;
      try {
        resjson = await res.json();
      } catch { }
      return {
        body: resjson,
        ok: res.ok,
        status: res.status
      };
    }

    return {
      ok: res.ok,
      status: res.status
    }

  } catch (err) {
    return {
      ok: false,
      status: ERROR_STATUS
    }
  }
};
