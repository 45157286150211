import { colors } from "@/ui/colors";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    min-width: 206px;
    max-width: 264px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.lightGreyStroke};
    gap: 8px;
    border-radius: 5px;
    flex: 1 0 0;
    p, path {
        color: ${colors.primaryDark};
        text-align: center;
    }
    :hover {
        background: ${colors.hoverLightRed};
        transition: 1s;
        p, path {
            color: ${colors.black};
        }
    }
`;

export const IconContainer = styled.div`
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
`;