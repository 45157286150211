import styled from "styled-components";
import { mediaqueries } from "@/ui/mediaqueries"
import { colors } from "@/ui/colors"
import { fontFamily, hedwigFontSizes } from "@/ui/typography"
import { spacing } from "@/ui/spacing";

export const SideBarContainer = styled.div<{
    sidebaropens?: boolean
}>`
    position: absolute;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 324px;
    text-align: start;
    margin: 0;
    background: white;
    border-bottom: 1px solid ${colors.white};
    padding: 0;
    z-index: 10;

    ${mediaqueries.lgMax} {
        display: ${(props) => (props.sidebaropens ? '' : 'none')};
        margin-top: -34px;
        padding-top: 0;
        min-height: calc(100% + 34px);
    }

    ${mediaqueries.xsMax} {
        display: ${(props) => (props.sidebaropens ? '' : 'none')};
        width: 291px;
        padding-top: 0;
        min-height: calc(100% + 34px);
    }
    & .hds-accordion {
        & .hds-accordion-item-header{
            padding-left: ${spacing.medium2};
        }
        & .hds-accordion-item-content{
            padding-left: 0;
        }
    }
`;

export const SideBarItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: ${spacing.small4};
    padding-bottom: ${spacing.small4};
    padding-left: 24px;

    span, path {
        color: ${colors.black};
    }

    &:hover {
        background: ${colors.hoverLightRed};
    }

    &.active {
        background: ${colors.grey};

        span, path {
            ${fontFamily.medium};
        }
    }
    &.accordionParent {
        padding: 0;
    & > div:first-child {
        width: 100%;
        padding: 0;
        & > div{
            padding-top: ${spacing.small4};
            padding-bottom: ${spacing.small4};
            padding-left: 0px;
            padding-right: 0px;
        }
        & > div:first-child {
            max-height: 60px;
            padding-right: ${spacing.small4};
        }
    }
    }
`;

export const MenuButtonWrapper = styled.div`
    margin-top: -34px;

    ${mediaqueries.xlMin} {
        display: none;
    }
`;
export const Icon = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CloseIcon = styled.div`
    ${mediaqueries.xlMin} {
        & svg {
            display: none;
        }
    }

    & svg {
        cursor: pointer;
    }

    width: 100%;
    padding: ${spacing.medium2};
    display: flex;
    justify-content: flex-end;
    float: right;

    path {
        color: ${colors.black};
    }
`;

export const IconLoading = styled.div`
    height: calc(${hedwigFontSizes.p} * 2);
    width: calc(${hedwigFontSizes.p} * 2);
`;

export const SpanLoading = styled.div<{ width: number }>`
    margin-left: ${spacing.small2};
    height: calc(${hedwigFontSizes.p} * 2);
    width: calc(${hedwigFontSizes.p} * 2 * ${props => props.width});
`;

export const ButtonLoading = styled.div`
    height: 58px;
    width: 130px;
`;
