import { LinkContainer, SectionTitleContainer } from './style'
import { fontClasses } from '@/ui/typography'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";

interface ISectionTitle {
  title: string
  link?: string
  linkText?: string
}

const SectionTitle = ({title, link, linkText}: ISectionTitle) => {
  return (
    <SectionTitleContainer>
      <h3 className={fontClasses.hw3}>{title}</h3>
      {link &&
          <Link to={link}>
              <LinkContainer>
                {
                  linkText && <p className={fontClasses.bodyTitle}> {linkText} </p>
                }
                  <FontAwesomeIcon
                      icon={faArrowRight}
                  />
              </LinkContainer>
          </Link>
      }
    </SectionTitleContainer>
  )
}
export default SectionTitle