import { mediaqueries } from "@/ui/mediaqueries";
import styled from "styled-components";
import { colors } from "@/ui/colors";
import { fontSize } from "@/ui/typography";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    padding-bottom: 20px;
`;

export const Column = styled.div`
    ${mediaqueries.mdMax} {
        width: 100%;
    }

    ${mediaqueries.mdMin} {
        width: 288px;
        select {
            width: 80%;
        }
    }
`;


export const LogoutButton = styled.button`
    display: flex;
    ${fontSize.tiny} !important;
    align-items: baseline;
    color: ${colors.primaryDarker};

    :hover {
        color: ${colors.primary};
    }

    svg {
        padding-left: 12px;

        :hover {
            color: ${colors.black};
        }
    }
`
