import { ChangeEvent, FocusEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle"
import { InputVariants } from '@/models/forms/formTypes'
import { Input, StandaloneSearch } from './style'
import SearchSuggestions from "@/components/base/search/search-suggestions";
import { ISearchSuggestion } from "@/components/base/search/search-suggestion";

interface ISearchFieldProps {
  ariaLabel?: string
  placeholder?: string
  autoFocus?: boolean
  variant?: InputVariants
  label?: string
  disabled?: boolean
  buttonLabel?: string
  value?: string
  extendedSuggestions?: boolean
  suggestions?: ISearchSuggestion[]
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
  onSearchButtonClick?: (searchTerm?: string) => void
  onEnter?: () => void
  onClear?: () => void
  suggestionClicked?: (id?: string | number) => void
}

const SearchField = ({
                       placeholder,
                       label,
                       buttonLabel,
                       value,
                       variant,
                       disabled,
                       suggestions,
                       onChange,
                       onBlur,
                       onSearchButtonClick,
                       onEnter,
                       onClear,
                     }: ISearchFieldProps) => {
  const [hasValue, setHasValue] = useState(false)
  const inputRef = useRef<HTMLInputElement>()
  const activeSuggs = useRef<ISearchSuggestion[]>([])
  let variation = variant ? 'hw-search--' + variant : ''
  let labelVariation = variant === 'line' ? 'hw-label--line' : ''
  const focused = useRef(-1)
  const loaded = useRef(false)
  const [focusedElement, setFocusedElement] = useState(-1)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      setHasValue(inputRef.current.value !== '')
    }
    if (typeof onChange === "function") onChange(e)
  }

  const reset = () => {
    inputRef.current.value = ''
    setHasValue(false)
    if (onClear) {
      onClear()
    }
  }

  const onKeyUp = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      if (typeof onEnter === "function") onEnter()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleNavigationKeys)
    setTimeout(() => {
      loaded.current = true
    }, 200)
    return () => {
      window.removeEventListener('keydown', handleNavigationKeys)
    }
  }, [])

  useEffect(() => {
    activeSuggs.current = suggestions ?? []
  }, [suggestions])

  const handleNavigationKeys = (e: KeyboardEvent) => {
    if (activeSuggs.current.length > 0 && (e.code === "ArrowDown" || e.code === "ArrowUp")) {
      let nextKey = focused.current
      if (e.code === "ArrowDown") {
        nextKey++
      } else if (e.code === "ArrowUp") {
        nextKey--
      }
      if (nextKey < -1) {
        nextKey = activeSuggs.current.length - 1
      } else if (nextKey >= activeSuggs.current.length) {
        nextKey = -1
      }
      focused.current = nextKey
      setFocusedElement(nextKey)
      e.stopPropagation()
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (focusedElement === -1 && loaded.current) {
      setTimeout(() => {
        inputRef.current.focus()
      })
    }
  }, [focusedElement])

  return (
    <label className={'hw-label ' + labelVariation}>
      {label}
      <StandaloneSearch
        className={`hw-search hw-search--standalone ${hasValue ? 'hw-search--hasvalue' : ''
        } ${variation}`}
        data-hw-search
        data-hw-search-placeholders=''
      >
        <div className='hw-search__inner'>
          <Input
            data-hw-search-input
            className='hw-search__input'
            type='text'
            aria-label='Search'
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={handleOnChange}
            ref={inputRef}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className='hw-search__icon'
          />
          <button
            className='hw-search__button-inside'
            data-hw-search-reset
            onClick={() => reset()}>
            <FontAwesomeIcon icon={faTimesCircle}/>
          </button>
        </div>
        <SearchSuggestions focusIdx={focusedElement} suggestions={suggestions}/>
        {
          buttonLabel && typeof onSearchButtonClick === "function" && (
            <button
              type='submit'
              onClick={() => onSearchButtonClick(inputRef.current.value)}
              className='hw-button hw-button--primary hw-search__button'
            >
              {buttonLabel}
            </button>
          )
        }
      </StandaloneSearch>
    </label>
  )
}

export default SearchField;
