import { mediaqueries } from "@/ui/mediaqueries";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const ErrorContainer = styled.div`
    width: 648px;
    display: flex;
    flex-direction: column;
    h1 {
        padding-top: ${spacing.large2}; 
        text-align: center;
    }
    p:first-child {
        padding-top: ${spacing.large2}; 
        padding-bottom: ${spacing.medium2}; 
    };
    a {
        cursor: pointer;
    };
    margin-left: 72px;
    ${mediaqueries.xsMax} {
      margin-left: 0px;
    }
`;