import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { CloseContainer, ModalBackground, ModalBody, ModalContainer, ModalHeader, ModalTitle } from "./style"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fontClasses } from "@/ui/typography";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";

interface IBaseModalProps extends PropsWithChildren<any> {
  title?: string
  show?: boolean
  modalType?: 'confirmation' | 'form' | 'simple' | ''
  scrollable?: boolean
  onClose?: () => void
}

const BaseModal = ({title, show, modalType, scrollable, onClose, children}: IBaseModalProps) => {
  const [_show, setShow] = useState(show)
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => setShow(show), [show]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = _show ? 'hidden' : 'auto'
    if (_show) {
      backgroundRef.current.classList.add("open")
      containerRef.current.focus();
    } else {
      backgroundRef.current.classList.remove("open")
    }
    document.body.addEventListener('focusin', _onFocus);
    show = false;
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = 'auto'
      document.body.removeEventListener('focusin', _onFocus);
    }
  }, [_show])

  function _onFocus(event) {
    if (!_show) return;
    if (!containerRef.current.contains(event.target)) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      containerRef.current.focus();
    }
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setShow(false)
  }

  return (
    <ModalBackground aria-hidden={"true"} ref={backgroundRef}>
      <ModalContainer className={modalType} ref={containerRef} tabIndex={-1} role={"dialog"} aria-modal={"true"}>
        <ModalHeader>
          <ModalTitle className={fontClasses.bodyTitle}>{title}</ModalTitle>
          <CloseContainer onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </CloseContainer>
        </ModalHeader>
        <ModalBody scrollable={scrollable}>
          {children}
        </ModalBody>
      </ModalContainer>
    </ModalBackground>
  );
}
export default BaseModal;