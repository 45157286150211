import { PropsWithChildren, useEffect, useState } from "react"
import { CountContainer, PaginationComponentContainer, RowCountManager } from "./style"
import Pagination from "../pagination"
import Dropdown from "@/components/form-components/dropdown"
import { useTranslation } from "react-i18next"
import { fontClasses } from "@/ui/typography"

interface IPaginationComponentProps extends PropsWithChildren<any> {
  totalCount: number
  recordsToDisplay?: number
  showRecordsToDisplay?: boolean
  numberToShowOptions?: number[]
  hide?: boolean
  currentPage?: number
  setCurrentPage?: (page: number) => void
  onNumberOfRecordsChanged?: (number: number) => void
}

const DEFAULT_OPTIONS = [5, 10, 15]
const DEFAULT_NUMBER_OF_RECORDS = 5

const PaginationComponent = ({
                               children,
                               showRecordsToDisplay,
                               currentPage,
                               setCurrentPage,
                               numberToShowOptions,
                               totalCount,
                               hide,
                               recordsToDisplay,
                               onNumberOfRecordsChanged
                             }: IPaginationComponentProps) => {
  const {t} = useTranslation()
  const [numberOfRecords, setNumberOfRecords] = useState(DEFAULT_NUMBER_OF_RECORDS)
  const [numberOptions, setNumberOptions] = useState<{ value: number, label: string }[]>()
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    if (recordsToDisplay) {
      setNumberOfRecords(recordsToDisplay)
    } else if (numberToShowOptions && numberToShowOptions.length > 0) {
      setNumberOfRecords(numberToShowOptions[0])
    } else {
      setNumberOfRecords(DEFAULT_NUMBER_OF_RECORDS)
    }
  }, [recordsToDisplay, numberToShowOptions])

  useEffect(() => {
    setNumberOptions((numberToShowOptions ?? DEFAULT_OPTIONS).map(o => ({
      label: o.toString(),
      value: o
    })))
  }, [numberToShowOptions])

  const updateRecordsToDisplay = (e: any) => {
    const value = parseInt(e.currentTarget.value)
    setNumberOfRecords(value)
    if (onNumberOfRecordsChanged) {
      onNumberOfRecordsChanged(value)
    }
  }

  useEffect(() => {
    setTitle(t("pagination.paginationTitle", {
      totalPages: totalCount > 0 ? Math.min(((currentPage - 1) * numberOfRecords) + numberOfRecords, totalCount) : 1,
      currentPage: totalCount > 0 ? ((currentPage - 1) * numberOfRecords) + 1 : 1,
      totalRecords: totalCount
    }))
  }, [currentPage, totalCount, numberOfRecords]);

  return (
    <PaginationComponentContainer>
      {
        hide ? (
          children
        ) : (
          <>
            <CountContainer>
              <div className={fontClasses.technical}>
                {title}
              </div>
              {showRecordsToDisplay && <RowCountManager>
                  <p className={fontClasses.technical} id={"rowsPerPage"}>{t(`dashboard.rowsPerPage`)}</p>
                  <Dropdown
                      ariaLabel="rowsPerPage"
                      options={numberOptions}
                      onChange={updateRecordsToDisplay}
                      size='xs'
                  />
              </RowCountManager>}
            </CountContainer>
            {children}
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalCount={totalCount} recordsToDisplay={numberOfRecords}/>
          </>
        )
      }
    </PaginationComponentContainer>
  )
}

export default PaginationComponent
