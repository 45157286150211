import { simpleDelete, simpleFetch, simplePost } from '@/utils/api'
import { IMessage } from '@/models/UnitPage/message'
import { IDeleteResponse, IFetchResponse, IPostResponse } from '@/models/api/apiResponse';

export const getMessages = async (unitNumber: string) =>  {
  const res: IFetchResponse<IMessage[]> = await simpleFetch({
    endpoint: `/api/Units/${unitNumber}/Message`,
  });
  return {
    ok: res.ok,
    body: mapMessages(res.body),
    status: res.status
  };
}

export const mapMessages = (messages?: IMessage[]) => {
  return messages ? messages.map(msg => ({
    ...msg,
    fromDate: msg.fromDate ? new Date(msg.fromDate) : undefined,
    toDate: msg.toDate ? new Date(msg.toDate) : undefined
  })) : [];
}

export const addMessage = async (unitNumber: string, message: IMessage) => {
  const res: IPostResponse = await simplePost({
    endpoint: `/api/Units/${unitNumber}/Message`,
    body: message,
  });
  return res.ok
}

export const updateMessage = async (unitNumber: string, message: IMessage) => {
  const res: IPostResponse = await simplePost({
    endpoint: `/api/Units/${unitNumber}/Message/${message.idMessage}`,
    body: message,
  });
  return res.ok
}

export const deleteMessage = async (unitNumber: string, idMessage: number) => {
  const res: IDeleteResponse<boolean> = await simpleDelete({
    endpoint: `/api/Units/${unitNumber}/Message/${idMessage}`
  });
  return res.ok
}