import { PropsWithChildren } from "react"
import { BottomContainer, ChevronIcon, Dropdown, HeaderLeftText, HeaderRightContainer, HeaderRightText, NumberContainer, NumberPlaceholder, PaginationContainer, PaginationHeaderContainer } from "./style"

interface IPaginationPlaceholderProps extends PropsWithChildren<any> {
    excludeHeader?: boolean
    numberOfPages?: number
    hide?: boolean
} 

const PaginationPlaceholder = ({ children, hide, excludeHeader, numberOfPages } : IPaginationPlaceholderProps) => {
    return (
        <>
            {
                hide ? children : (
                    <PaginationContainer>
                        {
                            !excludeHeader && (
                                <PaginationHeaderContainer>
                                    <HeaderLeftText className="loading-content" />
                                    <HeaderRightContainer>
                                        <HeaderRightText className="loading-content" />
                                        <Dropdown className="loading-content" />
                                    </HeaderRightContainer>
                                </PaginationHeaderContainer>
                            )
                        }
                        { children }
                        <BottomContainer>
                            <ChevronIcon className="loading-content"/>
                            <NumberContainer>
                            {
                                new Array(numberOfPages).fill(0).map((_, idx) => (
                                    <NumberPlaceholder key={idx} className="loading-content" />
                                ))
                            }
                            </NumberContainer>
                            <ChevronIcon className="loading-content"/>
                        </BottomContainer>
                    </PaginationContainer>
                )
            }
        </>
    )
}

export default PaginationPlaceholder