import { simpleFetch } from '@/utils/api';
import { IActiveProcesses, IDashboardPage, ILastVisitedUnits } from '@/models/dashboard/dashboardPage';
import { IFetchResponse } from '@/models/api/apiResponse';

export const getDashboardPage = async () => {
  const res: IFetchResponse<IDashboardPage> = await simpleFetch({
    endpoint: `/api/Dashboard`
  });
  return res;
}

export const getLastVisitedUnits = async (page = 1, pageSize = 5): Promise<IFetchResponse<ILastVisitedUnits>> => {
  return await simpleFetch({
    endpoint: `/api/Dashboard/LastVisitedUnits?page=${page}&pageSize=${pageSize}`
  });
}

export const getActiveProcesses = async (page = 1, pageSize = 5): Promise<IFetchResponse<IActiveProcesses>> => {
  return await simpleFetch({
    endpoint: `/api/Dashboard/ActiveProcesses?page=${page}&pageSize=${pageSize}`
  });
}