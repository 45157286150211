import { IMessage } from "@/models/UnitPage/message";
import { MessageListContainer } from './style'
import MessageCard from '@/components/unitPage/messages/message-card';


interface IMessageListProps {
    messages: IMessage[]
    editable?: boolean
    onRestore?: (idMessage: number) => void
    onEdit?: (idMessage: number) => void
    onDelete?: (idMessage: number) => void
}

const MessageList = ({ messages, editable, onDelete, onEdit, onRestore }: IMessageListProps) => {
    let isHistory = messages.length > 0 && messages[0].deleted
    return (
        <MessageListContainer className={isHistory ? "history" : "with-border"}>
            {
                messages.map((message) => (
                    <MessageCard key={message.idMessage} message={message} onDelete={onDelete} onEdit={onEdit} onRestore={onRestore} editable={editable}  deleteable={editable}/>
                ))
            }
        </MessageListContainer>
    );
}
export default MessageList;


interface IMessageCard {
    message: IMessage
}