import SectionTitle from '@/components/dashboard/section-title';
import { CardsContainer, LastVisitedUnitsContainer } from './style';
import { getLastVisitedUnits } from '@/services/dashboard';
import { ILastVisitedUnits } from '@/models/dashboard/dashboardPage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '@/components/dashboard/pagination-component';
import UnitCard from "@/components/dashboard/unit-card";
import DashboardComponentPlaceholder from "@/components/loading/dashboard-component-placeholder";
import ProgressCardListPlaceholder from "@/components/loading/progress-card-list-placeholder";

const LastVisitedUnits = () => {
  const {t} = useTranslation(['translation']);
  const [lastVisitedUnits, setLastVisitedUnits] = useState<ILastVisitedUnits>({units: [], totalCount: 0});
  const [recordsToDisplay, setRecordsToDisplay] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (currentPage == 0) return
    getLastVisitedUnits(currentPage, recordsToDisplay).then(response => {
      if (response.ok) setLastVisitedUnits(response.body)
      setLoading(false)
    })
  }, [currentPage, recordsToDisplay])

  return (<LastVisitedUnitsContainer>
    <SectionTitle title={t('dashboard.lastVisitedUnitsin30days')}/>
    {loading ? <DashboardComponentPlaceholder>
        <ProgressCardListPlaceholder numberOfItems={5} includeHeader={false} numerOfPagination={3}/>
      </DashboardComponentPlaceholder>
      :
      <PaginationComponent numberToShowOptions={[5, 10, 15]}
                           totalCount={lastVisitedUnits.totalCount}
                           recordsToDisplay={recordsToDisplay}
                           currentPage={currentPage == 0 ? 1 : currentPage}
                           showRecordsToDisplay={true}
                           setCurrentPage={setCurrentPage}
                           onNumberOfRecordsChanged={setRecordsToDisplay}>
        <CardsContainer>
          {
            lastVisitedUnits.units?.map((lvu, idx) => (
              <UnitCard unitName={lvu.unitName} unitNumber={lvu.unitNumber} key={lvu.unitNumber + lvu.lastVisitedUnitTimestamp}/>
            ))
          }
        </CardsContainer>
      </PaginationComponent>
    }
  </LastVisitedUnitsContainer>)
}

export default LastVisitedUnits;
