import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const H2Loading = styled.div<{width: string}>`
    height: calc(${hedwigFontSizes.h2} * 1.2);
    width: calc(${hedwigFontSizes.h2} * 1.6 * ${props => props.width});
    margin-top: calc(${hedwigFontSizes.h2} * 0.2);
    margin-bottom: calc(${hedwigFontSizes.h2} * 0.2);
    max-width: 100%;
`;