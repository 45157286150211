import { useTranslation } from 'react-i18next';
import { Column, Container } from './style';
import Accordion from "@/components/base/accordion";
import AccordionItem from '../base/accordion-item';
import { useCheckMobileScreen } from '@/helpers/screenSizeDetector';
import { fontClasses } from '@/ui/typography';
import { Link } from "react-router-dom";
import { INavbarDrawerLinkGroup } from "@/components/navbar-drawer";

const HeaderContent = ({groups}: { groups: INavbarDrawerLinkGroup[] }) => {
  const {t} = useTranslation(['translation']);
  const isMobile = useCheckMobileScreen();
  return (
    <Container>
      {
        (groups ?? []).map((group, idx) => (
          <Column key={idx}>
            {isMobile ? <Accordion>
                <AccordionItem title={<span>{group.translate ? t(group.text) : group.text}</span>}>
                  <ul>
                    {
                      (group.links ?? []).map((link, idx) => (
                        <li key={idx}>
                          {link.href && link.href !== "#" ?
                            <Link to={link.href} className={fontClasses.technical}>{link.translate ? t(link.text) : link.text}</Link>
                            : <span className={fontClasses.technical}>{link.translate ? t(link.text) : link.text}</span>}
                        </li>
                      ))
                    }
                  </ul>
                </AccordionItem>
              </Accordion>
              :
              <>
                <h5>{group.translate ? t(group.text) : group.text}</h5>
                <ul>
                  {
                    (group.links ?? []).map((link, idx) => (
                      <li key={idx}>
                        {link.href && link.href !== "#" ?
                          <Link to={link.href} className={fontClasses.technical}>{link.translate ? t(link.text) : link.text}</Link>
                          : <span className={fontClasses.technical}>{link.translate ? t(link.text) : link.text}</span>}
                      </li>
                    ))
                  }
                </ul>
              </>
            }
          </Column>
        ))
      }
    </Container>
  );
};

export default HeaderContent;
