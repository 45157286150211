import { InputVariants } from "@/models/forms/formTypes"
import { FormEvent, PropsWithChildren, useEffect, useRef, useState } from "react";

interface BaseTextAreaProps extends PropsWithChildren<any> {
  name?: string
  id?: string
  label?: string
  disabled?: boolean
  cols?: number
  variant?: InputVariants
  errorMessage?: string
  rows?: number
  maxLength?: number
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  readOnly?: boolean
  required?: boolean
  value?: string
}

export const BaseTextarea = ({
                               id,
                               name,
                               label,
                               variant,
                               placeholder,
                               rows,
                               cols,
                               maxLength,
                               onChange,
                               onBlur,
                               disabled,
                               readOnly,
                               errorMessage,
                               required,
                               value
                             }: BaseTextAreaProps) => {
  let labelVariant = variant === 'line' ? 'hw-label--line' : ''
  let variation = variant !== '' ? 'hw-textarea--' + variant : ''
  const textareaElement = useRef<HTMLTextAreaElement>(null);
  const [scrollHeight, setScrollHeight] = useState(textareaElement?.current?.scrollHeight ?? 0);
  if (errorMessage && errorMessage !== '') {
    labelVariant += ' hw-label--error'
    variation += ' hw-textarea--error'
  }

  useEffect(() => {
    const {scrollHeight} = textareaElement.current;
    const outOfBound = scrollHeight > 450;
    if (outOfBound) textareaElement.current.style.overflowY = "auto";
    else textareaElement.current.style.overflowY = "hidden";
    setScrollHeight(outOfBound ? 450 : scrollHeight);
  }, [textareaElement]);

  function onInput(event: FormEvent<HTMLTextAreaElement>) {
    event.currentTarget.style.height = "auto";
    event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";
    event.currentTarget.style.overflowY = "hidden";
  }

  return (
    <label className={'hw-label ' + labelVariant}>
      {label}
      <textarea
        id={id}
        name={name}
        className={'hw-textarea ' + variation}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onInput={onInput}
        style={
          {
            minHeight: "calc(1.5em + .75rem + 2px)",
            height: scrollHeight > 0 ? scrollHeight + "px" : undefined
          }
        }
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        value={value}
        ref={textareaElement}
      >
            </textarea>
      {errorMessage && (
        <div className='hw-error hw-error--align-right'>
          {errorMessage}
        </div>
      )}
    </label>
  )
}
export default BaseTextarea;