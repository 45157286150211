class Events extends EventTarget {
  on(name: EventName, callback: CustomEventListener | EventListener | EventListenerObject | null, options?: AddEventListenerOptions | boolean) {
    this.addEventListener(name, callback, options)
  }

  off(name: EventName, callback: CustomEventListener | EventListener | EventListenerObject | null, options?: EventListenerOptions | boolean) {
    this.removeEventListener(name, callback, options)
  }

  fire(name: EventName, detail?: DetailType) {
    this.dispatchEvent(new CustomEvent(name, {detail: detail}));
  }
}

export const events = new Events();

type EventName = "perform-report-page-search";
type DetailType = string | number | boolean | null | object | Array<unknown>;

interface CustomEventListener {
  (event: CustomEvent<DetailType>): void
}
