import { borderStyle } from "@/ui/border";
import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const Card = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    min-width: 212px;
    padding: ${spacing.medium2};
    gap: ${spacing.small4};
    border: ${borderStyle};
`;

export const Icon = styled.div`
    height: calc(${hedwigFontSizes.p} * 3);
    width: calc(${hedwigFontSizes.p} * 3);
`;

export const Text = styled.div<{width: number}>`
    height: calc(${hedwigFontSizes.technical} * 1.6);
    width: calc(${hedwigFontSizes.technical} * 1.6 * ${props => props.width});
    max-width: 100%;
`;