import {ValidationInformation, FieldList, Field, ValidationContainer} from "./style"
import InfoSubtle from "../info-subtle"
import { useTranslation } from "react-i18next";
import { fontClasses } from "@/ui/typography";


interface ValidationErrorInformationProps {
    fields?: string[]
}

const ValidationErrorInformation = ({fields}: ValidationErrorInformationProps) => {
    const { t } = useTranslation()
    return (
        <>
        {
            fields && fields.length > 0 ? (
                <ValidationContainer>
                    <InfoSubtle>
                        <div>
                            <ValidationInformation className={fontClasses.bodySmallTitle}>
                                {t('validationError.fieldsErrorMessage')}
                            </ValidationInformation>
                            <FieldList>
                                {
                                    fields.map((field, idx) => (
                                        <Field className={fontClasses.caption} key={idx}>{field}</Field>
                                    ))
                                }
                            </FieldList>
                        </div>
                    </InfoSubtle>
                </ValidationContainer>
            ) : <></>
        }
        </>
    )
}
export default ValidationErrorInformation;
