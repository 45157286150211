import styled from "styled-components";
import { colors } from "@/ui/colors"
import { spacing } from "@/ui/spacing"
import { fontFamily, fontClasses } from "@/ui/typography"

export const ButtonContainer = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin-top: 3px;
    &:hover span {
        color: ${colors.primaryDarker};
        text-decoration: underline;
        ${fontFamily.bold}
    }

    &:hover path {
        color: ${colors.primaryDarker}
    }

`;

export const TextContainer = styled.span<{ fontSize?: string }>`
    color: ${colors.primaryDark};
    padding: ${spacing.small2};
    ${(props) => (props.fontSize == 'technical' ? fontClasses.technicalTitle : fontFamily.medium)};
  
`;

export const IconContainer = styled.span<{ isDisabled?: boolean }>`
    & > svg {
        & > path {
            color: ${props => (props.isDisabled ? colors.darkGrey : colors.primaryDark)};
        }
    }
`;