import SearchField from "@/components/base/search/search-field"
import { REPORT_QUERY_NAMES } from "@/helpers/reports/reportsHelpers"
import { InputVariants } from "@/models/forms/formTypes"
import { globalSearch } from "@/services/searchService"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ISearchSuggestion } from "@/components/base/search/search-suggestion";

interface IUnitSearchProps {
  variant?: InputVariants
}

const UnitSearch = ({variant}: IUnitSearchProps) => {
  const [suggestions, setSuggestions] = useState<ISearchSuggestion[]>([])
  const [searchValue, setSearchValue] = useState('')
  const {t} = useTranslation(['translation']);
  const navigate = useNavigate()

  const handleChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  function navigateToReportsPageWithSearch(_searchValue?: string) {
    const query = _searchValue ?? searchValue
    if (query !== '') navigate(`/Reports?${REPORT_QUERY_NAMES.queryString}=${query}&${REPORT_QUERY_NAMES.initSearch}=true`)
  }

  useEffect(() => {
    if (searchValue === '' || searchValue.length < 3) {
      setSuggestions([])
    } else {
      globalSearch(searchValue).then(result => {
        if (!result) setSuggestions([])
        else setSuggestions(result.map(m => ({
          title: m.name,
          subTitle: m.unitNumber,
          scrollSuggestionIntoView: true,
          href: `/Unit/${m.unitNumber}/General`
        })))
      })
    }
  }, [searchValue])

  const handleClear = () => {
    setSearchValue('')
  }

  return (
    <SearchField
      value={searchValue}
      variant={variant}
      suggestions={suggestions}
      placeholder={t('reports.findUnit')}
      onChange={handleChange}
      onSearchButtonClick={navigateToReportsPageWithSearch}
      onEnter={navigateToReportsPageWithSearch}
      onClear={handleClear}
      buttonLabel={t('common.search')}
    />
  )
}

export default UnitSearch
