import { createContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Toast, ToastContainer, ToastWrapper} from './style';
import { useTranslation } from 'react-i18next';

interface IToast {
    message: string;
    type: 'success' | 'error' | 'error-fetch';
    id: number;
}

interface IToastContext {
    toasts: IToast[];
    showToast: (message: string, type: 'success' | 'error' | 'error-fetch') => void;
}

export const ToastContext = createContext<IToastContext>({
    toasts: [],
    showToast: () => { },
});

export function ToastProvider({ children }) {
    const [toasts, setToasts] = useState<IToast[]>([]);
    const { t } = useTranslation(['translation']);
    const showToast = (message: string, type: 'success' | 'error' | 'error-fetch') => {
        const id = Date.now();
        setToasts((prevToasts) => [...prevToasts, { message, type, id }]);
        if (type === 'success')
            setTimeout(() => {
                setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
            }, 4000);
        else
        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        }, 6000);

    };

    return (
        <ToastContext.Provider value={{ toasts, showToast }}>
            <ToastContainer>
                {toasts.map((toast) => (
                    <ToastWrapper key={toast.id} className={`toast ${toast.type}`}>
                        {toast.type === 'success' ? (
                            <FontAwesomeIcon icon={faCircleCheck} />
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                            </>
                        )}
                        <div>
                            <Toast>{toast.message}</Toast>
                            {toast.type == 'error-fetch' &&
                                <Toast>
                                    {t('toast.reloadMsg')}
                                </Toast>}
                        </div>
                    </ToastWrapper>
                ))}
            </ToastContainer>
            {children}
        </ToastContext.Provider>
    );
}
