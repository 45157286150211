import { simpleFetch } from '@/utils/api'
import { ISearchResultUnit } from '@/models/search/search'
import { IBaseUnit, IEstablishmentStatusType, IUnitType } from '@/models/UnitPage/unit';
import { IFetchResponse } from '@/models/api/apiResponse';

export const globalSearch = async (query: string) => {
  const res: IFetchResponse<IBaseUnit[]> = await simpleFetch({
    endpoint: `/api/Search/Units?queryString=${query}`
  });
  return res.body;
}

export const getUnitSuggestions = async (query: string) => {
  const res: IFetchResponse<IBaseUnit[]> = await simpleFetch({
    endpoint: `/api/Search/Units?queryString=${query}`
  })
  return res.body
}

export const getUnitTypes = async () => {
  const res: IFetchResponse<IUnitType[]> = await simpleFetch({
    endpoint: `/api/Search/UnitTypes`
  });
  return res.body
}

export const getEstablishmentStatusTypes = async () => {
  const res: IFetchResponse<IEstablishmentStatusType[]> = await simpleFetch({
    endpoint: `/api/Search/EstablishmentStatusTypes`
  });
  return res.body
}

export const getUnitSearchSearchResults = async (query: string) => {
  const res: IFetchResponse<ISearchResultUnit[]> = await simpleFetch({
    endpoint: `/api/Search/Units/Result?queryString=${query}`
  });
  return res.body
}