import { mediaqueries } from "@/ui/mediaqueries";
import styled from "styled-components";

export const Container = styled.div`
    ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    ${mediaqueries.smMax} {
        ul {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    display: flex;
    flex-wrap: wrap;
`;

export const Column = styled.div`
    ${mediaqueries.mdMax} {
        width: 100%;
    }

    ${mediaqueries.mdMin} {
        width: 288px;
    }
`;
