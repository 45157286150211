export const getUnitUrl = (unitNumber: string, page: string) => {
  return `/Unit/${unitNumber}/${page}`
}

export const ROUTES = {
  Page404: '/Error404',
  Page500: '/Error500',
};

export function slug(value: string): string {
  return value.toLowerCase().replace("/ /g", "").replace(/ +/g, "-");
}
