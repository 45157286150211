import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const CustomTooltipContainer = styled.div`
    background-color: #fff5f0;
    padding: 1px 5px 1px 5px;
    border-radius: 12px;
    border: 0.5px solid black;
`;

export const Legend = styled.div<{
  color?: string
}>`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .square {
        background-color: ${p => p.color};
        height: 20px;
        width: 20px;
        margin-right: 5px;
        clear: both;
    }

    a {
        margin-right: 24px;
        color: ${colors.primaryDark};
    }
`;


export const FloatingLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: ${spacing.small4};
    padding-left: ${spacing.large2};
    margin-top: calc(-1 * ${spacing.medium2});
`;

export const FloatingPieLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: ${spacing.small4};
    margin-top: ${spacing.medium2};
`;

export const LabelContainer = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    gap: ${spacing.small2};

    svg {
        font-size: 1.2em;

        path {
            color: ${props => props.color ? props.color : colors.black};
        }
    }
`;

export const Label = styled.span`
`;