import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import { fontFamily } from "@/ui/typography";
import styled from "styled-components";
import { mediaqueries } from "@/ui/mediaqueries";

export const NameContainer = styled.div`
    margin-left: ${spacing.medium2};
    align-items: center;
    top: auto;
    width: auto;

    span {
        color: ${colors.primaryDarker};

        &.postenBringEnheter {
            display: none;
        }

        &.enheter {
            font-weight: 500;
        }

        &.postenBring {
            ${fontFamily.light}
        }

        ${mediaqueries.xsMax} {
            &.postenBringEnheter {
                display: inline;
            }

            &.enheter {
                display: none;
            }

            &.postenBring {
                display: none;
            }
        }
    }

    ${mediaqueries.xsMax} {
        margin-left: ${spacing.small4};
        padding-left: ${spacing.small4};
    }
`;
export const LogoAndNameContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderContainer = styled.div`
    & .hw-navbar__menu-button-label-menu {
        padding-right: 0 !important;
        min-width: auto;
    }

    & .hw-navbar__menu-button-label-close {
        padding-right: 0 !important;
        min-width: auto;
    }

    & .hw-navbar__menu {
        button:hover {
            span {
                color: ${colors.primary};
            }

            svg {
                path {
                    color: ${colors.primary};
                }
            }
        }
    }
`;

export const LogoWrapper = styled.div`
    & > svg {
        width: 104px;

        ${mediaqueries.xsMax} {
            width: 70px;
        }
    }
`;

