import { getRandomNumberBetween } from "@/helpers/random"
import { ComponentContainer, HeaderContainer, HeaderLinkContainer, HeaderLinkIcon, HeaderLinkText, HeaderText } from "./style"
import { PropsWithChildren } from "react"

interface IDashboardComponentPlaceholderProps extends PropsWithChildren<any> {
    link?: boolean
} 

const DashboardComponentPlaceholder = ({children, link } : IDashboardComponentPlaceholderProps) => {
    return (
        <ComponentContainer>
            <HeaderContainer>
                <HeaderText width={getRandomNumberBetween(5, 8)} className="loading-content" />
                {
                    link && (
                        <HeaderLinkContainer>
                            <HeaderLinkText width={getRandomNumberBetween(3, 7)} className="loading-content" />
                            <HeaderLinkIcon className="loading-content" />
                        </HeaderLinkContainer>
                    )
                }
            </HeaderContainer>
            {children}
        </ComponentContainer>
    )

}

export default DashboardComponentPlaceholder