import { spacing } from "@/ui/spacing";
import { colors } from "@/ui/colors";
import styled from "styled-components";

export const UnitStatusContainer = styled.div`
    margin-bottom: ${spacing.large2};
    display: flex;
    flex-direction: column;
`;

export const TotalCountContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    @media (max-width: 750px) {
        &.large-screen {
            display: none;
        }
    }
`;


export const IconContainer = styled.div`
    path {
        color: ${colors.primaryDarker};
    }
`;


export const TotalCount = styled.div`
    p, h1 {
        color: ${colors.primaryDark}
    }
`;


export const ChartContainer = styled.div`
    &.large-screen {
        display: block;
    }

    &.small-screen {
        display: none;
    }

    @media (max-width: 750px) {
        &.large-screen {
            display: none;
        }

        &.small-screen {
            display: block;
        }
    }
`;