import styled from "styled-components";
import { spacing } from "@/ui/spacing"

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: normal;
    gap: ${spacing.small2};
    color: inherit;

    & svg {
        margin-right: ${spacing.small2};
        color: inherit;

        path {
            color: inherit;
        }
    }
`;
