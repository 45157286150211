import { colors } from "@/ui/colors";
import { buttonGroupSpacing, spacing } from "@/ui/spacing";
import styled from "styled-components";
export const MessageText = styled.p`
    margin-top: 0;
    margin-bottom: ${spacing.small2};
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: ${buttonGroupSpacing.gap};
`;

export const MessageInformationContainer = styled.div`
    display: flex;
    gap: ${spacing.small2};
    align-items: center;
    margin-top:  ${spacing.small2};
    margin-bottom: ${spacing.medium1};
    & > span, path {
        color: ${colors.darkGrey};
    }
    svg {
        font-size: 1.25em;
    }
`;

export const MessageContainer = styled.div`
    padding-top: ${spacing.medium2};
    padding-bottom: ${spacing.medium2};
    &.history {
        padding: ${spacing.small4};
        background: ${colors.primaryLighter};
        margin-top: ${spacing.small4};
    }
    &:not(.history):first-child {
        padding-top: 0;
    }
`;

