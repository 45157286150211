import { Configuration, InteractionRequiredAuthError, LogLevel, PublicClientApplication, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { getApiUrl } from "@/utils/api";

const config: Configuration = {
  auth: {
    clientId: process.env.FRONTEND_CLIENT_ID ?? "",
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    authority: 'https://login.microsoftonline.com/postennorge.onmicrosoft.com'
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
}

export const msal = new PublicClientApplication(config)

export async function logout() {
  sessionStorage.clear()
  localStorage.clear()
  await msal.clearCache()
  location.href = getApiUrl("/api/post-logout-redirect")
}

export function getLoginRequest(): SilentRequest | RedirectRequest {
  return {
    scopes: [`api://${process.env.BACKEND_CLIENT_ID}/API.All`],
    account: msal.getAllAccounts()?.at(0) ?? undefined
  }
}

export async function getToken() {
  const now = new Date()
  try {
    const inProgressResult = await msal.handleRedirectPromise()
    if (inProgressResult && inProgressResult?.accessToken && inProgressResult?.expiresOn >= now) return inProgressResult.accessToken
    const request = getLoginRequest()
    const response = await msal.acquireTokenSilent(request)
    return response.accessToken
  } catch (error) {
    console.error(error)
    if (error instanceof InteractionRequiredAuthError) {
      const inProgressResult = await msal.handleRedirectPromise()
      if (inProgressResult && inProgressResult?.accessToken && inProgressResult?.expiresOn >= now) return inProgressResult.accessToken
    }
    await msal.clearCache();
    await msal.loginRedirect()
  }
}
