import SectionTitle from "@/components/dashboard/section-title";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChartContainer, IconContainer, TotalCount, TotalCountContainer, UnitStatusContainer } from './style';
import { faStore } from "@fortawesome/pro-solid-svg-icons/faStore";
import { fontClasses } from "@/ui/typography";
import SimpleBarChart, { IData } from '@/components/charts/bar-chart';
import SimplePieChart from '@/components/charts/pie-chart';

interface IUnitStatus {
  unitStatus: {
    name: string,
    count: number,
    link: string
  }[]
}

const UnitStatus = ({unitStatus}: IUnitStatus) => {
  const {t} = useTranslation(['translation']);
  const updatedUnitStatus = unitStatus.filter(unit => unit.count > 0);
  const totalCount = updatedUnitStatus.reduce((total, unit) => total + unit.count, 0);
  const xLabelFormatter = (data: IData) => {
    return `${data.name} (${data.value})`
  }

  return (
    <UnitStatusContainer>
      <SectionTitle title={t('dashboard.unitStatus.title')}/>
      <TotalCountContainer className="large-screen">
        <IconContainer>
          <FontAwesomeIcon className='fa-3x' icon={faStore}/>
        </IconContainer>
        <TotalCount>
          <p className={fontClasses.technical}>
            {t('dashboard.unitStatus.unitCount')}
          </p>
          <h1 className={fontClasses.hw1}>
            {totalCount}
          </h1>
        </TotalCount>
      </TotalCountContainer>
      <ChartContainer className="large-screen">
        <SimpleBarChart xAxisFormatter={xLabelFormatter} data={updatedUnitStatus.map((status) => ({...status, value: status.count}))}/>
      </ChartContainer>
      <ChartContainer className="small-screen">
        <SimplePieChart xLabelFormatter={xLabelFormatter} data={updatedUnitStatus.map((status) => ({...status, value: status.count}))} chartTitle={t('dashboard.unitStatus.unitCount')}
                        totalCount={totalCount?.toString()}/>
      </ChartContainer>
    </UnitStatusContainer>

  )
};
export default UnitStatus;