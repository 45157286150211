import styled from 'styled-components';
import { colors, labelColor } from '../colors';
import { spacing } from '../spacing';


export const inputStyles = `  
  input,
  input[type="text"], 
  input[type="date"],
  input[type="number"],
  input[type="email"],
  textarea {
    border-radius: 2px;
    transition: 250ms ease;
    outline: none;
    border: 0;
    color: ${colors.black};
    max-width: 100%;
    border-bottom: 1px solid transparent;
    
    &:focus {
      border-color: var(--hw-color-primary-dark);
    }
    &:focus ~ label {
      color: var(--hw-color-primary-dark);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .unit-col {
    width: 70%;
  }
  .unit-name-text {
    padding-top: 20px;
  }

  .hw-phone-input .hw-input {
      padding-left: 5px;
  }

  .hw-phone-input__group {
    &.error-border {
      border-bottom: var(--hw-border-thickness-thick) solid var(--hw-color-alert-yellow)!important;
    }
    & input {
      border-bottom: none;
      position: relative;
      flex: 1;
    }
  }
`;

export const selectStyles = `
  .hw-phone-input {
    max-width: 300px;
    & .hw-label:nth-child(2) {
      flex: 1;
    }
  }

  .hw-phone-input {
    & .hw-dropdown {
      position: relative;
      color: ${colors.black};
      background-size: 10px 10px;
      background-position: calc(100% - 10px) 45%;
      &:focus {
        color: ${colors.black};
      }
      &--white {
        background-image: linear-gradient(45deg,transparent 74%,${colors.white} 0),linear-gradient(135deg,${colors.white} 26%,transparent 0),linear-gradient(135deg,transparent,transparent 72%,${colors.white} 73%,${colors.white}),linear-gradient(45deg,${colors.white},${colors.white} 28%,${colors.darkGrey} 29%,${colors.darkGrey} 37%,transparent 38%,transparent),linear-gradient(135deg,transparent,transparent 62%,${colors.darkGrey} 63%,${colors.darkGrey} 71%,${colors.white} 72%,${colors.white});
      }
    }
    &__group > .hw-label {
      margin-top: 0;
    }
  }

  .hw-search--standalone {
    &.hw-search--dropdown {
      .hw-search__input {
        padding-left: ${spacing.small4};
        padding-right: 48px;
        background-size: 16px 16px;
        background-position: calc(100% - 10px) 45%;
        background-repeat: no-repeat;
        background-image: linear-gradient(45deg,transparent 74%,${colors.lightGreyFill} 0),linear-gradient(135deg,${colors.lightGreyFill} 26%,transparent 0),linear-gradient(135deg,transparent,transparent 72%,${colors.lightGreyFill} 73%,${colors.lightGreyFill}),linear-gradient(45deg,${colors.lightGreyFill},${colors.lightGreyFill} 28%,${colors.black} 29%,${colors.black} 37%,transparent 38%,transparent),linear-gradient(135deg,transparent,transparent 62%,${colors.black} 63%,${colors.black} 71%,${colors.lightGreyFill} 72%,${colors.lightGreyFill});
      }
      &--white {
        background-image: linear-gradient(45deg,transparent 74%,${colors.white} 0),linear-gradient(135deg,${colors.white} 26%,transparent 0),linear-gradient(135deg,transparent,transparent 72%,${colors.white} 73%,${colors.white}),linear-gradient(45deg,${colors.white},${colors.white} 28%,${colors.darkGrey} 29%,${colors.darkGrey} 37%,transparent 38%,transparent),linear-gradient(135deg,transparent,transparent 62%,${colors.darkGrey} 63%,${colors.darkGrey} 71%,${colors.white} 72%,${colors.white});
      }
    }
    .hw-error:before {
      right: 35px;
    }
    .hw-search__suggestions {
      z-index: 2;
    }
  }
`;

export const textAreaStyles = `
  .hw-textarea--white {
    background: ${colors.white};
  }
`;


export const labelStyles = `
  label {
    color: ${colors.darkGrey};
    margin-bottom: 0px;
    margin-top: ${spacing.small4};
  }
  .hw-label {
    color: ${labelColor};
  }
`;

export const whiteDropdownChevron = `linear-gradient(45deg,transparent 74%,${colors.white} 0),linear-gradient(135deg,${colors.white} 26%,transparent 0),linear-gradient(135deg,transparent,transparent 72%,${colors.white} 73%,${colors.white}),linear-gradient(45deg,${colors.white},${colors.white} 28%,${colors.darkGrey} 29%,${colors.darkGrey} 37%,transparent 38%,transparent),linear-gradient(135deg,transparent,transparent 62%,${colors.darkGrey} 63%,${colors.darkGrey} 71%,${colors.white} 72%,${colors.white})`
export const whiteDropdownDisabledChevron = `linear-gradient(45deg,transparent 74%,${colors.grey} 0),linear-gradient(135deg,${colors.grey} 26%,transparent 0),linear-gradient(135deg,transparent,transparent 72%,${colors.grey} 73%,${colors.grey}),linear-gradient(45deg,${colors.grey},${colors.grey} 28%,${colors.darkGrey} 29%,${colors.darkGrey} 37%,transparent 38%,transparent),linear-gradient(135deg,transparent,transparent 62%,${colors.darkGrey} 63%,${colors.darkGrey} 71%,${colors.grey} 72%,${colors.grey})`
export const greyDropdownChevron = `linear-gradient(45deg,transparent 74%,${colors.lightGreyFill} 0),linear-gradient(135deg,${colors.lightGreyFill} 26%,transparent 0),linear-gradient(135deg,transparent,transparent 72%,${colors.lightGreyFill} 73%,${colors.lightGreyFill}),linear-gradient(45deg,${colors.lightGreyFill},${colors.lightGreyFill} 28%,${colors.black} 29%,${colors.black} 37%,transparent 38%,transparent),linear-gradient(135deg,transparent,transparent 62%,${colors.black} 63%,${colors.black} 71%,${colors.lightGreyFill} 72%,${colors.lightGreyFill})`

export const UnitRow = styled.div`
    flex-direction: row;
    align-items: center;
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
    margin-bottom: 24px;
`;
