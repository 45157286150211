import { mediaqueries } from "@/ui/mediaqueries";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const ReportsContainer = styled.div`
    margin-bottom: ${spacing.large2};
`;

export const InfoGraphicsContainer = styled.div`
    ${mediaqueries.xsMax} {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        flex-wrap: nowrap;
        overflow: auto;
        align-self: stretch;
    }
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
`;