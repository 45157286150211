export const resets = `
  button {
    cursor: pointer;
    border: 0;
    background: none;
    padding: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
`;
