import { CheckboxVariants } from "@/models/forms/formTypes"
import { PropsWithChildren } from "react";
import BaseCheckbox from '../base-checkbox'

interface CheckboxProps extends PropsWithChildren<any> {
    name?: string
    id?: string
    disabled?: boolean
    checked?: boolean
    variants?: CheckboxVariants[]
    errorMessage?: string
    onChange?: (e: any) => void
    readOnly?: boolean
    ref?: any
}

const Checkbox = ({
    name,
    id,
    disabled,
    checked,
    errorMessage,
    variants,
    onChange,
    readOnly,
    children,
    ref
}: CheckboxProps) => {

    return (
        <BaseCheckbox
            id={id}
            name={name}
            disabled={disabled}
            checked={checked}
            variants={variants}
            errorMessage={errorMessage}
            onChange={onChange}
            readOnly={readOnly}
        >
            {children}
        </BaseCheckbox>
    )
}
export default Checkbox;
