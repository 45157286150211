import { colors } from "@/ui/colors";
import { mediaqueries } from "@/ui/mediaqueries";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
        color: ${colors.white};
        margin-left: 0;
    }

    li {
        color: ${colors.white};
        display: flex;
        align-items: center;
        font-size: 18px;
    }

    li > a {
        margin-left: 0;
    }

    li > p {
        font-size: 16px;
    }

    &.mail {
        font-size: 18px;
    }

    path {
        color: ${colors.white};
    }

    &.iconItem {
        display: inline;
    }

    ${mediaqueries.xlMin} {
        margin-bottom: 72px;
    }

    ${mediaqueries.xlMax} {
        & .hw-block {
            border-bottom: .2px solid white;
        }
    }
`;

export const Icon = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
