import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CustomizedAxisTick, CustomTooltip, FloatingBarLabels } from '@/components/charts/customization';
import { useEffect, useState } from "react";
import { barColors, colors } from "@/ui/colors";
import { ChartContainer } from "./style"


export interface IData {
  name: string,
  value: number,
  link?: string,
  fill?: string

}

interface ISimpleBarChart {
  data: IData[]
  showYAxis?: boolean
  yAxisFormatter?: (value: number) => string
  xAxisFormatter?: (data: IData) => string
  yAxisRange?: number[]
  horizontalGrid?: boolean
  height?: number
  floatingLabel?: boolean
}

const SimpleBarChart = ({data, showYAxis, yAxisFormatter, yAxisRange, horizontalGrid, height, floatingLabel, xAxisFormatter}: ISimpleBarChart) => {
  const [dataToShow, setDataToShow] = useState<IData[]>([]);

  useEffect(() => {
    const updatedData = data?.map((d, index) => {
      return {
        ...d,
        name: xAxisFormatter ? xAxisFormatter(d) : d.name,
        link: d.link ? d.link : undefined,
        fill: d.fill ? d.fill : barColors[index]
      }
    }) ?? [];
    setDataToShow(updatedData);
  }, [data])


  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={height ?? 300}>
        <BarChart
          data={dataToShow}
          margin={{
            bottom: 50
          }}
        >
          {
            horizontalGrid && (
              <CartesianGrid vertical={false}/>
            )
          }
          {
            !floatingLabel && (
              <XAxis dataKey="name" tick={<CustomizedAxisTick data-column={dataToShow.map((d, idx) => ({fill: d.fill ? d.fill : barColors[idx], link: d.link}))}/>} interval={0} axisLine={false}
                     tickLine={false}/>
            )
          }
          <Tooltip content={<CustomTooltip/>} cursor={{fill: colors.lightGreyStroke}}/>
          <Bar dataKey="value" animationDuration={2000}/>
          {
            showYAxis && (
              <YAxis axisLine={false} type="number" domain={yAxisRange} tickFormatter={yAxisFormatter}/>
            )
          }
        </BarChart>
      </ResponsiveContainer>
      {
        floatingLabel && (
          <FloatingBarLabels labels={dataToShow.map((data) => {
            return {
              label: data.name,
              link: data.link,
              color: data.fill
            }
          })}/>
        )
      }
    </ChartContainer>
  )
};

export default SimpleBarChart;