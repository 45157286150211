import { colors } from "@/ui/colors";
import { mediaqueries } from "@/ui/mediaqueries";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const FooterWrapper = styled.div`
    flex-shrink: 0;
`;

export const PageWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const ContentWrapper = styled.div`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
`;

export const pageContentPaddings = {
    lgMin: "156px",
    mdMax: "32px",
    xsMax: "24px"
}
export const PageContentWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 600px;

    &.padded {
        ${mediaqueries.lgMin} {
            padding-left: ${pageContentPaddings.lgMin};
            padding-right: ${pageContentPaddings.lgMin};
        }

        ${mediaqueries.mdMax} {
            padding-left: ${pageContentPaddings.mdMax};
            padding-right: ${pageContentPaddings.mdMax};
        }

        ${mediaqueries.xsMax} {
            justify-content: center;
            padding-left: ${pageContentPaddings.xsMax};
            padding-right: ${pageContentPaddings.xsMax};
        }
    }
`;

export const PageInnerWrapper = styled.div`
    width: 100%;
    max-width: 1300px;
`;

export const Banner = styled.div`
    width: 100%;
    background: ${colors.yellow};
    display: flex;
    justify-content: center;
    padding-top: ${spacing.small4};
    padding-bottom: ${spacing.small4};
`;