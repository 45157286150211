import { getRandomNumberBetween } from "@/helpers/random"
import { Card, Icon, Text } from "./style"

interface IInfoGraphicCardPlaceholderProps {
    includeIcon?: boolean
    textWidth?: number
}

const InfoGraphicCardPlaceholder = ({includeIcon, textWidth}: IInfoGraphicCardPlaceholderProps) => {
    return (
        <Card>
            {
                includeIcon && (
                    <Icon className="loading-content" />
                )
            }
            <Text width={textWidth ?? getRandomNumberBetween(6, 10)} className="loading-content" />
        </Card>
    )
}

export default InfoGraphicCardPlaceholder