import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import Header from '@/components/header'
import { Banner, ContentWrapper, FooterWrapper, PageContentWrapper, PageWrapper } from "./style"
import { ToastProvider } from "@/components/toast";
import { useEffect, useState } from "react";
import { getMetaDataForMenus } from "@/services/UnitPage/commonService";
import { IFooter, IHeader } from "@/models/UnitPage/basePage";
import { ROUTES } from "@/helpers/urlHelpers";
import Footer from '@/components/footer';
import Breadcrumbs from '@/components/breadcrumbs';
import { fontClasses } from "@/ui/typography";
import { t } from "i18next";
import { impersonatingAsKey } from "@/utils/api";
import "@postenbring/hedwig-css";

const DefaultLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [headerContent, setHeaderContent] = useState<IHeader>(null);
  const [footerContent, setFooterContent] = useState<IFooter>(null);
  const [isPadded, setIsPadded] = useState(true);

  useEffect(() => {
    const pathname = location.pathname.toLowerCase();
    setIsPadded(
      !pathname.startsWith("/unit") &&
      !pathname.includes("/operationalsurvey/") &&
      !pathname.includes("/operationalvisit/") &&
      !pathname.startsWith("/admin")
    );
  }, [location]);


  useEffect(() => {
    getMetaDataForMenus().then(res => {
      if (!res.ok && !location.pathname.startsWith(ROUTES.Page500)) {
        navigate(ROUTES.Page500)
        return
      }

      setHeaderContent(res.body.header);
      setFooterContent(res.body.footer);

      try {
        document.getElementById("loading-container").style.display = "none"
      } catch (e) {
        console.error(e)
      }
    })
  }, []);

  return (
    <>
      {headerContent && <PageWrapper>
        <ContentWrapper>
          <Header headerItems={headerContent?.headerItems} showRoleChanger={headerContent?.showRoleChanger} />
          {sessionStorage.getItem(impersonatingAsKey) && <Banner>
            <p className={fontClasses.bodyTitle}>{`${t('header.simulatingText')}: ${sessionStorage.getItem(impersonatingAsKey)}`}</p>
          </Banner>}
          <Breadcrumbs />
          <PageContentWrapper className={`${isPadded ? "padded" : ""}`}>
            <ToastProvider>
              <Outlet />
            </ToastProvider>
          </PageContentWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <Footer content={footerContent} />
        </FooterWrapper>
        <ScrollRestoration getKey={location => location.pathname} />
      </PageWrapper>}
    </>
  );
};

export default DefaultLayout;
