import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonContainer, IconContainer, TextContainer } from './style'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IIconLinkProps {
  text?: string
  icon?: IconProp
  fontSize?: string
  spin?: boolean
  onClick: (e: any) => void
  isDisabled?: boolean
  className?: string
}

const DescreteButton = ({ icon, text, fontSize, spin, onClick, isDisabled, className }: IIconLinkProps) => {
  return (
    <ButtonContainer onClick={onClick} disabled={isDisabled} className={className}>
      {icon ? <IconContainer isDisabled={isDisabled}><FontAwesomeIcon spin={spin} icon={icon} /></IconContainer> : <></>}
      {text && <TextContainer fontSize={fontSize}>{text}</TextContainer>}
    </ButtonContainer>
  );
}
export default DescreteButton;
