import styled from "styled-components";
import { colors } from "@/ui/colors";

export const Label = styled.label`
    &.label_disabled {
        color: ${colors.darkGrey};
    }

    input:focus ~ .hw-checkbox__indicator,
    input:focus-within ~ .hw-checkbox__indicator {
        outline: 2px solid var(--hw-color-checkbox-accent);
        outline-offset: 2px;
    }
`;

export const Input = styled.input`

`