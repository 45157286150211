import { IBaseUnitPage } from '@/models/UnitPage/baseUnitPage'
import { simpleFetch } from '@/utils/api'
import { mapMessages } from '@/services/UnitPage/messageService';
import { IFetchResponse } from '@/models/api/apiResponse';
import { IMenu } from "@/models/UnitPage/basePage";

export const getBaseUnitPage = async (unitNumber: string) => {
  const res: IFetchResponse<IBaseUnitPage> = await simpleFetch({
    endpoint: `/api/Units/${unitNumber}`
  });
  if (res.ok) {
    if (res.body?.messages) res.body.messages = mapMessages(res.body.messages);
    return res;
  }
  return res
}

export const getMetaDataForMenus = async () => {
  return await simpleFetch<IMenu>({
    endpoint: `/api/MetaData`
  })
}
