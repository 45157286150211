import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const H1Loading = styled.div<{width: number}>`
    height: calc(${hedwigFontSizes.h1} * 1.2);
    margin-top: calc(${hedwigFontSizes.h1} * 0.2);
    margin-bottom: calc(${hedwigFontSizes.h1} * 0.2);
    width: calc(${hedwigFontSizes.h1} * 1.4 * ${props => props.width});
    max-width: 100%;
`;