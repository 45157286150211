import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ErrorContainer } from './style';
import { fontClasses } from "@/ui/typography";
import Search from "@/components/search";
import { REPORT_QUERY_NAMES } from "@/helpers/reports/reportsHelpers";

const Error404 = () => {
  const {t} = useTranslation(['translation'])
  const navigate = useNavigate()

  function navigateToReportsPageWithSearch(searchValue?: string) {
    if (searchValue !== '') navigate(`/Reports?${REPORT_QUERY_NAMES.queryString}=${searchValue}&${REPORT_QUERY_NAMES.initSearch}=true`)
  }

  return (
    <ErrorContainer>
      <h1 className={fontClasses.hw1}>{t('error.error404.heading')}</h1>
      <h2 className={fontClasses.hw2}>{t('error.error404.subHeading')}</h2>
      <p className={fontClasses.caption}>
        <Link to="/">{t('error.searchText')}</Link>
      </p>
      <Search onSearchButtonClick={navigateToReportsPageWithSearch} onSearch={navigateToReportsPageWithSearch}/>
      <div>
        <p className={fontClasses.caption}>
          <a onClick={() => navigate(-1)}>{t('error.toPreviousPage')}</a>
        </p>
        <p className={fontClasses.caption}>
          <Link to="/">{t('error.toHomePage')}</Link>
        </p>
      </div>
    </ErrorContainer>
  );
}
export default Error404;
