import styled from "styled-components";
import { mediaqueries } from "@/ui/mediaqueries";

export const FooterWrapper = styled.div`
    & .hw-footer__shared {
        padding-bottom: 72px;
    }
`;
export const LogoLinkWrapper = styled.div`
    & > svg {
        width: 104px;

        ${mediaqueries.xsMax} {
            width: 70px;
        }
    }
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`
