
import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import { fontClasses } from "@/ui/typography";
import styled from "styled-components";


export const ToastWrapper = styled.div<{ type?: string }>`
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    div {
        display: flex;
        flex-direction: column;
    }

    &.success {
        border: 1px solid var(--bring-signature-green, #7BC144);
        background: var(--bring-light-green, #C8DC8C);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        path {
        color: green;
        }
        opacity: 0;
        animation: in ease forwards, out ease forwards ;
        animation-duration: 1s, 1s;
        animation-delay: 0s, 2s;
        animation-iteration-count: 1, 1; 
    }
    &.error, &.error-fetch {
        border: 1px solid ${colors.primary};
        background: ${colors.primaryLight};
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        path {
        color: ${colors.primaryDarker};
        }
        opacity: 0;
        animation: in ease forwards, out ease forwards ;
        animation-duration: 1s, 1s;
        animation-delay: 0s, 4s;
        animation-iteration-count: 1, 1; 
    }
    @keyframes in  {from{opacity: 0}to {opacity: 1}}
    @keyframes out {from{opacity: 1}to {opacity: 0}}
`;


export const Toast = styled.div`
    border-radius: 2px;
    ${fontClasses.technical};
    font-size: 16px;
`;

export const ToastContainer = styled.div`
    z-index: 9999;
    display: flex;
    bottom: 5%;
    right: 5.6%;
    gap: 8px;
    position: fixed;
    flex-direction: column;
`;
