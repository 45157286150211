import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const PaginationContainer = styled.div`
    margin-top: ${spacing.small4};
    display: flex;
    flex-wrap: wrap;

    button {
        display: flex;
        height: 48px;
        padding: ${spacing.small2} ${spacing.small4};
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    & .active {
        border-bottom: 2px solid ${colors.primary};
        color: ${colors.primaryDark}
    }

    & .ellipsis {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 10px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;