import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const NewServicesAndEquipmentsContainer = styled.div`
    background: ${colors.bringLighterGreen};
    padding-right: ${spacing.medium3};
    padding-bottom: ${spacing.medium3};
    .hw-h3 {
        padding-top: ${spacing.medium3};
        padding-left: ${spacing.medium3};
    }
`;


export const TileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;