import {colors} from "../ui/colors"

export const borderColor = colors.lightGreyStroke;

interface IBorderWidth {
    normal: string
    thick: string
}

export const borderWidths: IBorderWidth = {
    normal: 'var(--hw-border-thickness)',
    thick: 'var(--hw-border-thickness-thick)'
}

export const borderStyle = `${borderWidths.normal} solid ${borderColor}`;
