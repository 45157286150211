import { pageContentPaddings } from "@/pages/_Layout/style";
import { colors } from "@/ui/colors";
import { mediaqueries } from "@/ui/mediaqueries";
import styled from "styled-components";

export const BreadcrumbsContainer = styled.div`
    display: flex;

    a:not(.disabled) {
        text-decoration: 1px underline ${colors.primary};
        text-underline-offset: 10px;
        color: ${colors.black}
    }

    ${mediaqueries.lgMin} {
        padding-left: ${pageContentPaddings.lgMin};
        padding-right: ${pageContentPaddings.lgMin};
    }

    ${mediaqueries.mdMax} {
        padding-left: ${pageContentPaddings.mdMax};
        padding-right: ${pageContentPaddings.mdMax};
    }

    ${mediaqueries.xsMax} {
        padding-left: ${pageContentPaddings.xsMax};
        padding-right: ${pageContentPaddings.xsMax};
    }
`;