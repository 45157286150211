interface IColors {
  primaryDarker: string;
  primaryDark: string;
  primary: string;
  primaryLight: string;
  primaryLighter: string;
  hoverLightRed: string;
  black: string;
  white: string;
  yellow: string;
  darkGreen: string;
  darkerGrey: string;
  darkGrey: string;
  grey: string;
  lightGreyStroke: string;
  lightGreyFill: string;
  light: string;
  mint: string;
  completed: string;
  started: string;
  borderColor: string;
  bringGreen: string;
  bringLighterGreen: string
}

export const colors: IColors = {
  primaryDarker: 'var(--hw-color-primary-darker)', // '#4A1011'
  primaryDark: 'var(--hw-color-primary-dark)', // '#980000'
  primary: 'var(--hw-color-primary)', //  '#E32D22'
  primaryLight: 'var(--hw-color-primary-light)', // '#FF8A5F'
  primaryLighter: 'var(--hw-color-primary-lighter)', // '#FFF5F0'
  hoverLightRed: 'var(--hw-color-button-primary-light-hover)',
  black: 'var(--hw-color-black)', //  '#000000'
  white: 'var(--hw-color-white)', //  '#FFFFFF',
  yellow: '#FDBB2F',
  darkGreen: '#00643A',
  darkGrey: 'var(--hw-color-gray-dark)', //  '#6E6E6E',
  darkerGrey: '#505050',
  grey: '#D6D6D6',
  lightGreyStroke: '#E4E4E4',
  lightGreyFill: 'var(--hw-color-gray-light-fill)',//  '#F2F2F2'
  mint: '#47D7AC',
  light: "#FFF5F0",
  completed: '#19B216',
  started: '#FF9820',
  borderColor: '#E4E4E4',
  bringGreen: "#7BC144",
  bringLighterGreen: 'var(--Bring-Lighter-green, #F1F7E9)', // #F1F7E9
};

export const barColors = ["#FF8A5F", "#980000", "#E32D22", "#002F19", "#00643A", "#C18300", "#D3E593", "#6E6E6E", "#4A1011", "#000",];

export const labelColor = colors.darkerGrey