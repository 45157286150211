import { colors } from "../colors";
import { spacing } from "../spacing";

export const datePickerStyles = `
    .pbe-datepicker {
        .react-date-picker {
            width: 100%;
            &__calendar {
                width: 375px;
                max-width: 100vw;
                z-index: 2;
            }
            &__wrapper {
                border: none;
                background: ${colors.lightGreyFill};
                
                .react-date-picker__button {
                  align-items: center;
                }
            }
            &__calendar-button {
                &:disabled {
                    path {
                        color: ${colors.darkGrey}!important;
                    }
                }
                &:enabled:hover {
                    path {
                        color: ${colors.primaryDarker};
                    }
                }

            }
            &__inputGroup {
                padding-left: ${spacing.small2};
                padding-top: ${spacing.small4};
                padding-bottom: ${spacing.small4};
                padding-right: ${spacing.small2};
                width: auto;
                height: auto;
                &__input {
                    font-size: 18px;
                }
                &__leadingZero {
                    font-size: 18px;
                }
            }

            &--disabled {
                background-color: transparent;
            }
        }
        &--white {
            .react-date-picker {
                &__wrapper {
                    background: ${colors.white};
                }
            }
        }
        &--disabled {
            .react-date-picker {
                &__wrapper {
                    background: ${colors.grey};
                } 
            }
            &__home
        }
        &--error {
            .react-date-picker {
                &__wrapper {
                    border-bottom: ${colors.yellow} solid 2px;
                }
            }
        }
    }
`;

export const timePickerStyles = `
    .time-picker {
        & div[class$="menu"] {
            z-index: 2;
        }
    }
`;
