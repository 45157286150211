import styled from "styled-components";
import { spacing } from "@/ui/spacing"

export const ValidationInformation = styled.p`
`;

export const ValidationContainer = styled.div`
    margin-top: ${spacing.medium2};
`;


export const FieldList = styled.ul`
    margin-top: ${spacing.small2};
`;

export const Field = styled.li`
    &::before {
        content: "* ";
    }
`;
