import { colors } from "../colors";
import { spacing } from "../spacing";

export const tableStyle = `
    .pbe-table {
        &__container {
            overflow-x: auto;
            & > table {
                min-width: auto!important;
            }
        }
        &__head-cell {
            & > svg {
                margin-left: ${spacing.small2};
                & > path {
                    color: ${colors.darkGrey};
                }
            }
        }
    }
`;