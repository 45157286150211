import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState, } from "react";
import { globalSearch } from '@/services/searchService'
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { REPORT_QUERY_NAMES } from "@/helpers/reports/reportsHelpers";
import { HeaderItem } from "@/models/UnitPage/basePage";
import { ISearchSuggestion } from "@/components/base/search/search-suggestion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import SearchSuggestions from "@/components/base/search/search-suggestions";
import { HeaderContainer, LogoAndNameContainer, LogoWrapper, NameContainer } from './style'
import { fontClasses, fontSize } from "@/ui/typography";
import NavbarDrawer, { INavbarDrawerLinkGroup, IUserInfoItem } from "@/components/navbar-drawer"
import { PostenBringLogo } from "@/ui/icons"
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlass";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { events } from "@/utils/events";

interface IHeaderContent {
  headerItems: HeaderItem[],
  showRoleChanger: boolean
}

export default function Header({headerItems, showRoleChanger}: IHeaderContent) {
  const [suggestions, setSuggestions] = useState<ISearchSuggestion[]>([])
  const navigate = useNavigate();
  const location = useLocation()
  const locationPath = useRef('')
  const [linkGroups, userInfo] = mapHeaderItems(headerItems);
  const [searchValue, setSearchValue] = useState("");
  const [lastSearchValue, setLastSearchValue] = useState("");
  const searchValueChangeTimer = useRef(null);
  const {t} = useTranslation(['translation']);
  const [searchIsActive, setSearchIsActive] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>()
  const headerRef = useRef<HTMLElement>()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [focusedElement, setFocusedElement] = useState(-1)

  useEffect(() => {
    if ((suggestions?.length ?? 0) > 0) {
      setFocusedElement(-1)
    }
  }, [suggestions])

  function handleKeyUp(event: KeyboardEvent) {
    if (event.code == "Escape" && menuIsOpen) setMenuIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleNavigationKeys)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleNavigationKeys)
      window.removeEventListener('keyup', handleKeyUp)
    }
  })

  useEffect(() => {
    if (!searchValue) clearInput()
    if (searchValue.length < 3) {
      setSuggestions([])
      return
    }
    if (searchValue != lastSearchValue) {
      clearTimeout(searchValueChangeTimer.current)
      searchValueChangeTimer.current = setTimeout(() => {
        globalSearch(searchValue).then(result => {
          if (result) {
            setSuggestions(result.map(m => ({
              title: m.name,
              subTitle: m.unitNumber,
              scrollSuggestionIntoView: true,
              href: `/Unit/${m.unitNumber}/${locationPath.current !== '' ? locationPath.current : 'General'}`
            })))
          } else {
            setSuggestions([])
          }
        })
        setLastSearchValue(searchValue)
      }, 400);
    }
  }, [searchValue]);

  useEffect(() => {
    const {pathname} = location
    const pathSegments = pathname.split('/')
    let lastPathEntry = ''
    let idx = pathSegments.length - 1
    while (idx > 0 && lastPathEntry === '') {
      lastPathEntry = pathSegments[idx].trim().toLocaleLowerCase()
      idx--
    }
    if (lastPathEntry !== '') {
      const startOfPath = pathname.substring(0, pathname.toLowerCase().indexOf(lastPathEntry))
      const match = matchPath("/Unit/:unitNumber", startOfPath)
      if (match !== null) {
        locationPath.current = lastPathEntry.charAt(0).toUpperCase() + lastPathEntry.slice(1)
      }
    }
    setMenuIsOpen(false)
    setSearchIsActive(false)
  }, [location])

  useEffect(() => {
    if (focusedElement === -1) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      })
    }
  }, [focusedElement])

  useEffect(() => {
    if (menuIsOpen) headerRef.current.classList.add("hw-navbar--active")
    else headerRef.current.classList.remove("hw-navbar--active")
    if (searchIsActive) headerRef.current.classList.add("hw-navbar--searching")
    else headerRef.current.classList.remove("hw-navbar--searching")
  }, [searchIsActive, menuIsOpen])

  function handleNavigationKeys(e: KeyboardEvent) {
    if (suggestions.length > 0 && (e.code === "ArrowDown" || e.code === "ArrowUp")) {
      let nextKey = focusedElement
      if (e.code === "ArrowDown") {
        nextKey++
      } else if (e.code === "ArrowUp") {
        nextKey--
      }
      if (nextKey < -1) {
        nextKey = suggestions.length - 1
      } else if (nextKey >= suggestions.length) {
        nextKey = -1
      }
      setFocusedElement(nextKey)
      e.preventDefault()
    }
  }

  function handleOverlayClicked() {
    clearInput()
    setSearchIsActive(false)
  }

  function handleSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.currentTarget.value)
  }

  function clearInput() {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
      searchInputRef.current.focus()
      setSearchValue("")
    }
    setSuggestions([])
  }

  function handleSearchBtnClicked() {
    clearInput()
    setSearchIsActive(true)
    // timeout because searchInput is attached to the DOM after searchIsActive==true
    setTimeout(() => {
      searchInputRef.current.focus()
    }, 200)
  }

  function handleSearchInputKeyUp(event: ReactKeyboardEvent<HTMLInputElement>) {
    if (event.code == "Enter" && document.activeElement === searchInputRef.current && searchValue) {
      if (location.pathname.toLowerCase().startsWith("/reports")) events.fire("perform-report-page-search", {query: searchValue})
      else navigate(`/Reports?${REPORT_QUERY_NAMES.queryString}=${searchValue}&${REPORT_QUERY_NAMES.initSearch}=true`)
      setSearchIsActive(false)
      clearInput()
    }
  }

  function handleSearchInputKeyDown(event: ReactKeyboardEvent<HTMLInputElement>) {
    if (["ArrowDown", "ArrowUp"].includes(event.code)) {
      event.preventDefault()
    }
  }

  function handleCloseSearchBtnClicked() {
    setSearchIsActive(false)
    clearInput()
  }

  return (
    <header className={`hw-navbar`} ref={headerRef}>
      <div className='hw-navbar__content'>
        <HeaderContainer className='hw-navbar__top hw-navbar__top--service'>
          <LogoAndNameContainer className='hw-navbar__logo hw-navbar__logo--service'>
            <Link to={"/"} aria-label={'Posten Bring Logo'}>
              <LogoWrapper>
                <PostenBringLogo/>
              </LogoWrapper>
            </Link>
            <NameContainer className="hw-navbar__logo-flagship">
              <span className={`postenBringEnheter ${fontSize.large}`}>PBE</span>
              <span className={`postenBring ${fontSize.large}`}>Posten Bring </span>
              <span className={`enheter ${fontClasses.bodyTitle}`}>Enheter</span>
            </NameContainer>
          </LogoAndNameContainer>
          <nav className='hw-navbar__menu'>
            <button onClick={handleSearchBtnClicked} className='hw-navbar__search-button' aria-label={t('common.findUnit')}>
              <span className='hw-navbar__search-text'>{t('common.findUnit')}</span>
              <FontAwesomeIcon className='hw-navbar__icon' size={'2x'} icon={faMagnifyingGlass}/>
            </button>
            <button onClick={() => setMenuIsOpen(!menuIsOpen)} className='hw-navbar__menu-button' aria-label={t('common.menu')}>
              {
                menuIsOpen ? (
                  <>
                    <span className='hw-navbar__menu-button-label-close'>{t('common.close')}</span>
                    <span className="hw-navbar__menu-button-toggle">
                      <FontAwesomeIcon className='hw-navbar__icon' size={'2x'} icon={faXmark}/>
                    </span>
                  </>
                ) : (
                  <>
                    <span className='hw-navbar__menu-button-label-menu'>{t('common.menu')}</span>
                    <span className="hw-navbar__menu-button-toggle">
                      <FontAwesomeIcon className='hw-navbar__icon hw-navbar__bars' icon={faBars}/>
                    </span>
                  </>
                )
              }
            </button>
            <NavbarDrawer linkGroups={linkGroups} userInfo={userInfo} showRoleChanger={showRoleChanger}/>
          </nav>
        </HeaderContainer>
        {
          searchIsActive && (
            <>
              <div onClick={handleOverlayClicked} className="hw-navbar__overlay"></div>
              <section className="hw-navbar__search">
                <div className={`hw-search hw-search--large hw-search--dirty ${searchValue ? 'hw-search--hasvalue' : ''}`}>
                  <div className="hw-search__inner">
                    <input onKeyUp={handleSearchInputKeyUp}
                           onKeyDown={handleSearchInputKeyDown}
                           ref={searchInputRef}
                           onChange={handleSearchInputChange}
                           name="q"
                           className="hw-search__input"
                           type="search"
                           aria-label={t('common.search')}
                           placeholder={t('common.search')}
                           autoComplete="off"/>
                    <FontAwesomeIcon className='hw-search__icon' icon={faSearch}/>
                    <button onClick={clearInput} className='hw-search__button-inside' aria-label="Search">
                      <FontAwesomeIcon className='hw-navbar__icon' icon={faTimesCircle}/>
                    </button>
                  </div>
                  <SearchSuggestions focusIdx={focusedElement} suggestions={suggestions}/>
                </div>
                <button onClick={handleCloseSearchBtnClicked} className="hw-navbar__close-search">
                  <FontAwesomeIcon className='fa-2x' icon={faTimes}/>
                </button>
              </section>
            </>
          )
        }
      </div>
    </header>
  )
}

function mapHeaderItems(headerItems: HeaderItem[]): [INavbarDrawerLinkGroup[], IUserInfoItem[]] {
  const userInfo: IUserInfoItem[] = [];
  const linkGroups: INavbarDrawerLinkGroup[] = headerItems.reduce((acc, item) => {
    if (item.heading === "loggedInAs" || item.heading === "roles") {
      userInfo.push({
        text: `header.${item.heading}`,
        translate: true,
        links: item.links.map(link => ({
          href: link.href,
          translate: item.heading === "roles",
          text: item.heading === "roles" ? `header.${link.text}` : link.text
        }))
      });
    } else {
      const group: INavbarDrawerLinkGroup = {
        text: `header.${item.heading}`,
        translate: true,
        links: item.links.map(link => ({
          href: link.href,
          translate: true,
          text: `header.${link.text}`
        }))
      };
      acc.push(group);
    }
    return acc;
  }, [] as INavbarDrawerLinkGroup[]);

  return [linkGroups, userInfo];
}
