import styled from "styled-components";
import { modalSpacing, spacing } from "@/ui/spacing"
import { colors } from "@/ui/colors";
import { mediaqueries } from "@/ui/mediaqueries";

export const ModalBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    align-items: center;
    justify-content: center;

    &.open {
        display: flex;
    }

    z-index: 11;
`;

export const ModalContainer = styled.div`
    background-color: ${colors.white};

    ${modalSpacing.yPadding}
    &.confirmation {
        width: 369px;

        ${mediaqueries.mdMax} {
            width: 342px;
        }
    }

    max-width: 100vw;

    &.form {
        width: 450px;

        ${mediaqueries.mdMax} {
            width: 370px;
        }
    }
`;

export const CloseContainer = styled.div`
    cursor: pointer;
`;

export const ModalTitle = styled.h3`
    
`;


export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${modalSpacing.xPadding};
`;

export const ModalBody = styled.div<{
  scrollable?: boolean
}>`
    margin-top: ${spacing.small4};
    ${modalSpacing.xPadding};
    max-height: ${props => props.scrollable ? '80vh' : 'none'};
    overflow: ${props => props.scrollable ? 'auto' : 'initial'};
`;
