import { colors } from "../colors";

export const loading = `
    @keyframes loadingColor {
        0%   {background-color: ${colors.lightGreyStroke};}
        50%  {background-color: ${colors.grey};}
        100% {background-color: ${colors.lightGreyStroke};}
    }

    .loading-content {
        background-color: ${colors.lightGreyStroke};
        animation-name: loadingColor;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
`