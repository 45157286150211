import { Header, SecondaryInformation, TimeInformation, UnitCardContainer, UnitInformation } from './style'
import { fontClasses } from '@/ui/typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons/faCalendarDays";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import ProgressBar from '@/components/progress-bar'
import { Link } from "react-router-dom";
import { getDeadlineColor } from '@/helpers/progressHelpers';

interface IActiveProcessCard {
  unitNumber: string,
  unitName: string,
  url: string
  title?: string
  deadline?: Date
  createdDate: Date
}
function calculateProgress(createdDate, deadline) {
  const currentDate = new Date();
  const startDate = new Date(createdDate);
  const deadlineDate = new Date(deadline);

  startDate.setHours(0, 0, 0, 0);
  deadlineDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const startTimestamp = startDate.getTime();
  const deadlineTimestamp = deadlineDate.getTime();
  const currentTimestamp = currentDate.getTime();

  const oneDayMs = 24 * 60 * 60 * 1000;
  const totalDays = Math.ceil((deadlineTimestamp - startTimestamp) / oneDayMs);
  let elapsedDays = Math.floor((currentTimestamp - startTimestamp) / oneDayMs);

  if (currentTimestamp < deadlineTimestamp) {
    const now = new Date();
    const fractionTodayPassed = (now.getHours() + now.getMinutes() / 60 + now.getSeconds() / 3600) / 24;
    elapsedDays += fractionTodayPassed;
  }

  let progressPercentage;
  if (currentTimestamp >= deadlineTimestamp) {
    progressPercentage = 100;
  } else {
    progressPercentage = (elapsedDays / totalDays) * 100;
    progressPercentage = Math.max(0, Math.min(100, progressPercentage));
  }

  return progressPercentage.toFixed(2);
}

const ActiveProcessCard = ({ unitNumber, unitName, url, title, deadline, createdDate }: IActiveProcessCard) => {
  const progressPercentage = calculateProgress(createdDate, deadline)
  const color = getDeadlineColor(progressPercentage)
  return (
    <Link to={url}>
      <UnitCardContainer>
        <Header>
          <UnitInformation>
            <p className={fontClasses.technicalTitle + ' unit-number'}>{unitNumber}</p>
            <p className={fontClasses.technicalTitle}>{unitName}</p>
          </UnitInformation>

          <FontAwesomeIcon icon={faArrowRight} />
        </Header>
        {title && <SecondaryInformation>
          <FontAwesomeIcon icon={faCircleInfo} />
          <p className={fontClasses.technical}>{`Tiltak: ${title}`}</p>
        </SecondaryInformation>}
        {deadline && <TimeInformation>
          <FontAwesomeIcon icon={faCalendarDays} />
          <div className='progress'>
            <ProgressBar progress={progressPercentage} color={color} />
          </div>
        </TimeInformation>}
      </UnitCardContainer>
    </Link>
  )
}
export default ActiveProcessCard