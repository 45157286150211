import { colors } from "@/ui/colors";

export const getProgressColor = (progress: number): string => {
  if (progress >= 100) {
    return colors.bringGreen;
  } else if (progress >= 50) {
    return colors.darkGreen;
  } else if (progress >= 30 && progress < 50) {
    return colors.yellow;
  } else {
    return colors.primary;
  }
}
export const getDeadlineColor = (progress: number): string => {
  if (progress >= 100) {
    return colors.primary;
  } else if (progress >= 50) {
    return colors.yellow;
  } else if (progress >= 30 && progress < 50) {
    return colors.darkGreen;
  } else {
    return colors.bringGreen;
  }
}