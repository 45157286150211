import { useTranslation } from "react-i18next"
import HeaderContent from '@/components/navbar-drawer-content';
import HeaderUserInfo from '@/components/navbar-drawer-user-info';

interface INavbarDrawerProps {
  linkGroups: INavbarDrawerLinkGroup[]
  userInfo: IUserInfoItem[],
  showRoleChanger: boolean
}

export interface INavbarDrawerLinkGroup {
  text: string
  translate?: boolean
  links: INavbarDrawerLink[]
}

export interface IUserInfoItem {
  text: string;
  translate?: boolean
  links: INavbarDrawerLink[]
}

export interface INavbarDrawerLink {
  text: string
  href: string
  translate?: boolean
}

const NavbarDrawer = ({ linkGroups, userInfo, showRoleChanger }: INavbarDrawerProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <section className="hw-navbar__drawer">
      <section className="hw-navbar__drawer">
        <div className="hw-navbar__drawer-content">
          <div className="hw-navbar__drawer-mobile">
            <HeaderContent groups={linkGroups} />
            <HeaderUserInfo groups={userInfo} showRoleChanger={showRoleChanger} />
          </div>
          <div className="hw-navbar__drawer-desktop">
            <HeaderContent groups={linkGroups} />
            <HeaderUserInfo groups={userInfo} showRoleChanger={showRoleChanger} />
          </div>
        </div>
      </section>
    </section>
  )
}

export default NavbarDrawer
