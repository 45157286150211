import styled from "styled-components";
import { colors } from "@/ui/colors"
import { spacing } from "@/ui/spacing"
import {fontFamily, fontClasses} from "@/ui/typography"

export const ListElement = styled.li`
    & button {
        display: flex;
        justify-content: space-between;
    }
    & svg {
        transition: 200ms;
    }
    &.hw-accordion__item--expanded {
        .close-icon {
            transform: rotate(180deg);
        }
    }
    & button {
        &:hover {
            svg {
                path {
                    color: ${colors.primaryDark};
                }
            }
            span {
                color: ${colors.primaryDark};
            }
        }
    }
`;

export const IconContainer = styled.div`
    width: 40px;
    display: flex;
    justify-content: end;
`;