import { InputVariants } from "@/models/forms/formTypes"
import { useEffect, useState } from "react";
import BaseTextArea from "@/components/form-components/base-text-area"
import { useTranslation } from "react-i18next"

interface TextAreaProps {
  name?: string
  id?: string
  label?: string
  disabled?: boolean
  cols?: number
  variant?: InputVariants
  errorMessage?: string
  rows?: number
  maxLength?: number
  value?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onErrorMessage?: (message: string) => void
  readOnly?: boolean
  required?: boolean
  placeholder?: string
}

const TextArea = ({
                    name,
                    id,
                    label,
                    disabled,
                    errorMessage,
                    variant,
                    cols,
                    rows,
                    value,
                    onChange,
                    onBlur,
                    onErrorMessage,
                    readOnly,
                    required,
                    placeholder
                  }: TextAreaProps) => {
  const {t} = useTranslation(['translation']);
  const [errorMessageToShow, setErrorMessageToShow] = useState('')
  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e)
    }
    validate(e)
  }

  const setErrorMessage = (text: string) => {
    if (onErrorMessage) {
      onErrorMessage(text)
    }
    setErrorMessageToShow(text)
  }

  const validate = (e: any) => {
    if (!errorMessage) {
      if (!e.currentTarget.checkValidity()) {
        if (e.currentTarget.value === '' && required) {
          setErrorMessage(label + t('validationError.requiredPostFix'))
        }
      } else {
        setErrorMessage('')


      }    }
  }

  useEffect(() => {
    if (errorMessage !== undefined) {
      setErrorMessageToShow(errorMessage)
    }
  }, [errorMessage])

  return (
    <BaseTextArea
      placeholder={placeholder}
      id={id}
      name={name}
      label={label}
      disabled={disabled}
      cols={cols}
      rows={rows}
      variant={variant}
      required={required}
      errorMessage={errorMessageToShow}
      onChange={handleOnChange}
      onBlur={onBlur}
      readOnly={readOnly}
      value={value}
    />
  )
}
export default TextArea;