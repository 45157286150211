import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const ProgressCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.lightGreyStroke};
    border-radius: ${spacing.small1};
    padding: ${spacing.small4};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderText = styled.div<{ width: number }>`
    max-width: 100%;
    width: calc(${hedwigFontSizes.technical} * 1.6 * ${props => props.width});
    height: calc(${hedwigFontSizes.technical} * 2);
`;

export const InfoContainer = styled.div`
    display: flex;
    margin-top: ${spacing.small1};
    gap: ${spacing.small2};
    align-items: center;
    justify-content: start;
`

export const InfoText = styled.div<{ width: number }>`
    width: calc(${hedwigFontSizes.technical} * 1.6 * ${props => props.width});
    max-width: 100%;
    height: calc(${hedwigFontSizes.technical} * 2);
`;

export const Icon = styled.div`
    width: calc(${hedwigFontSizes.technical} * 1.6);
    height: calc(${hedwigFontSizes.technical} * 2);
`;

export const ProgressBar = styled.div<{ progress: string }>`
    max-width: 100%;
    height: 4px;
    border-radius: 2px;
    width: ${props => props.progress}%;
`;

export const PorgressBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: ${spacing.small2};
    margin-top: ${spacing.small1};
`;