import { PropsWithChildren, useEffect, useState } from "react";
import { colors } from "@/ui/colors"
import { AccordionContainer, AccordionContent, AccordionHeader, HeaderCollapse, HeaderText } from "./style";
import { Info } from '@/ui/icons';
import { fontClasses } from '@/ui/typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";

interface AccordianProps extends PropsWithChildren<any> {
  title?: string
  icon?: any
  defaultOpen?: boolean
  backgroundColor?: string
  showInfoIcon?: boolean
}

const Accordian = ({ title, icon, backgroundColor, defaultOpen, children, showInfoIcon }: AccordianProps) => {
  const backgroundColorToUse: string = backgroundColor ?? colors.lightGreyFill
  const displayTitle = title ?? "";
  const infoIcon = showInfoIcon ?? true;
  const [open, setOpen] = useState(defaultOpen ?? false);

  const openClose = () => {
    setOpen(!open);
  }
  useEffect(() => {
    setOpen(defaultOpen ?? false);
  }, [defaultOpen])

  return (
    <AccordionContainer style={{ background: backgroundColorToUse }}>
      <AccordionHeader onClick={openClose}>
        {infoIcon && <div>
          <Info />
        </div>}
        <HeaderText className={fontClasses.bodyTitle}>
          {displayTitle}
        </HeaderText>
        <HeaderCollapse className={open ? 'open' : null}>
          <FontAwesomeIcon className="open-close-icon" icon={faChevronUp} />
        </HeaderCollapse>
      </AccordionHeader>
      <AccordionContent className={open ? 'open' : null}>
        {children}
      </AccordionContent>
    </AccordionContainer>
  );
}
export default Accordian;
