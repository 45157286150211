import { InputVariants } from "@/models/forms/formTypes"
import { useEffect, useState } from "react";
import { DatePickerContainer, DateRangeLabels, ErrorMessage, FromLabel, ToLabel } from './style'
import DateRangePickerComponent from '@wojtekmaj/react-daterange-picker'
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Value } from "@wojtekmaj/react-daterange-picker/dist/cjs/shared/types"
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons/faCalendarDays";

interface DatePickerProps {
  labelFrom?: string
  labelTo?: string
  disabled?: boolean
  selectedFrom?: Date
  selectedTo?: Date
  variant?: InputVariants
  required?: boolean
  minFrom?: Date
  maxFrom?: Date
  minTo?: Date
  maxTo?: Date
  errorMessage?: string
  onChange?: (value: Date[] | null) => void
  onErrorMessage?: (message: string) => void
  readOnly?: boolean
  ref?: any
}

const DateRangePicker = ({
  labelFrom,
  labelTo,
  disabled,
  selectedFrom,
  selectedTo,
  errorMessage,
  variant,
  required,
  onChange,
  minTo,
  minFrom,
  maxTo,
  maxFrom,
  onErrorMessage,
}: DatePickerProps) => {
  const { t } = useTranslation(['translation']);
  const [value, setValue] = useState<Value>([selectedFrom ?? new Date(), selectedTo ?? new Date()] as Value);
  const [errorMessageToShow, setErrorMessageToShow] = useState('');

  function setTimelessValue(_value: Value) {
    try {
      if (_value && _value[0] && _value[1]) {
        const time = [new Date((_value[0] as Date).setHours(0, 0, 0, 0)),
        new Date((_value[1] as Date).setHours(0, 0, 0, 0))];
        setValue(time as Value)
        return time
      } else {
        setValue(null)
      }
    } catch (e) {
      console.error(_value, e)
    }
  }

  useEffect(() => {
    if (selectedFrom === undefined || selectedTo === undefined) {
      setTimelessValue(null)
    } else {
      setTimelessValue([selectedFrom, selectedTo])
    }
  }, [selectedFrom, selectedTo])

  useEffect(() => {
    validate()
  }, [value, required])

  function handleOnChange(newValue: Value) {
    onChange(setTimelessValue(newValue))
  }

  const validate = () => {
    let errors = [] as { key: string, value: string }[]
    if (required && !value) {
      errors.push({ key: "required", value: t('validationError.dateRange.required') })
    } else {
      errors = errors.filter(v => v.key !== "required");
    }
    if (minTo && value[1] < minTo) {
      errors.push({ key: "minTo", value: t("validationError.dateRange.minDate", { date: minTo.toLocaleDateString(), label: labelTo }) })
    } else {
      errors = errors.filter(v => v.key !== "minTo")
    }
    if (maxTo && value[1] > maxTo) {
      errors.push({ key: "maxTo", value: t("validationError.dateRange.maxDate", { date: maxTo.toLocaleDateString(), label: labelTo }) })
    } else {
      errors = errors.filter(v => v.key !== "maxTo")
    }
    if (minFrom && value[0] < minFrom) {
      errors.push({ key: "minFrom", value: t("validationError.dateRange.minDate", { date: minFrom.toLocaleDateString(), label: labelFrom }) })
    } else {
      errors = errors.filter(v => v.key !== "minFrom")
    }
    if (maxFrom && value[0] > maxFrom) {
      errors.push({ key: "maxFrom", value: t("validationError.dateRange.maxDate", { date: maxFrom.toLocaleDateString(), label: labelFrom }) })
    } else {
      errors = errors.filter(v => v.key !== "maxFrom")
    }
    if (!errors.length) {
      setErrorMessage("")
      return
    }
    const ul = document.createElement("ul")
    for (const error of errors) {
      const li = document.createElement("li")
      li.innerText = error.value
      ul.appendChild(li)
    }
    setErrorMessage(ul.innerHTML)
  }

  useEffect(() => {
    if (errorMessage) {
      setErrorMessageToShow(errorMessage as string);
    }
  }, [errorMessage])

  const setErrorMessage = (text: string) => {
    if (onErrorMessage) {
      onErrorMessage(text)
    }
    setErrorMessageToShow(text)
  }

  function renderError(_errorMessage: any) {
    if (!_errorMessage) return <></>
    if (_errorMessage.match("<li>")) return (
      <ErrorMessage>
        <ul className="hw-error" dangerouslySetInnerHTML={{ __html: _errorMessage }}></ul>
      </ErrorMessage>
    )
    return (<ErrorMessage className="hw-error"></ErrorMessage>)
  }

  return (
    <DatePickerContainer
      className={`pbe-datepicker
            ${variant ? ` pbe-datepicker--` + variant : ''}
            ${errorMessageToShow && errorMessageToShow !== '' ? ' pbe-datepicker--error' : ''}
            ${disabled ? ' pbe-datepicker--disabled' : ''}
            `}>
      <DateRangeLabels>
        <FromLabel className="hw-label">{labelFrom}</FromLabel>
        <ToLabel className="hw-label">{labelTo}</ToLabel>
      </DateRangeLabels>
      <DateRangePickerComponent
        disabled={disabled}
        format={"dd/MM/yy"}
        calendarProps={{
          showFixedNumberOfWeeks: true,
          formatDay: (_, date) => date.getDate().toString(),
          nextLabel: <FontAwesomeIcon icon={faChevronRight} />,
          prevLabel: <FontAwesomeIcon icon={faChevronLeft} />
        }}
        showLeadingZeros={true}
        clearIcon={
          <FontAwesomeIcon icon={faTimes} />
        }
        value={value as Value}
        calendarIcon={
          <FontAwesomeIcon icon={faCalendarDays} />
        }
        onChange={handleOnChange}
      />
      {renderError(errorMessageToShow)}
    </DatePickerContainer>
  )
}


export default DateRangePicker;
