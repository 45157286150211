import { colors } from '../colors';
import { whiteDropdownChevron, whiteDropdownDisabledChevron } from './input';

export const searchStyles = `
  .hw-search__suggestions {
    display: none;
    
    .hw-search__suggestion:focus,
    .hw-search__suggestion:hover {
      background-color: ${colors.lightGreyFill} !important;
    }
  }
  
  .hw-search:focus-within .hw-search__suggestions {
    display: block;
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    } 
  .hw-search {
    &--white {
      &.hw-search--standalone .hw-search__input {
        background-color: ${colors.white};
        background-image: ${whiteDropdownChevron};
        
        &:disabled {
          background-color: ${colors.grey};
          background-image: ${whiteDropdownDisabledChevron};
        }
      }
      .hw-search__suggestions {
        background-color: ${colors.white};
      }
    }
    &.hw-search--standalone .hw-search__input {
      &:disabled {
        background-color: ${colors.grey};
        background-image: ${whiteDropdownDisabledChevron};
      }
    }
  }
`;
