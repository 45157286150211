import { useEffect, useState } from "react";
import { getDashboardPage } from '@/services/dashboard';
import { IComponent } from '@/models/dashboard/dashboardPage';
import { fontClasses } from "@/ui/typography";
import { ComponentsContainer, DashboardPageContainer, GreetingContainer, SearchContainer } from './style';
import { useTranslation } from "react-i18next";
import H1Placeholder from "@/components/loading/h1-placeholder"
import H2Placeholder from "@/components/loading/h2-placeholder"
import SearchPlaceholder from "@/components/loading/search-placeholder"
import ProgressCardListPlaceholder from "@/components/loading/progress-card-list-placeholder"
import GraphicCardListPlaceholder from "@/components/loading/graphic-card-list-placeholder"
import DashboardComponentPlaceholder from "@/components/loading/dashboard-component-placeholder"
import { spacing } from "@/ui/spacing";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/helpers/urlHelpers";
import { IUser } from "@/models/user/user";
import UnitSearch from "@/components/unit-search"
import LastVisitedUnits from "@/pages/Dashboard/Sections/LastVisitedUnits";
import Reports from "@/pages/Dashboard/Sections/Reports";
import UnitStatus from "@/pages/Dashboard/Sections/UnitStatus";
import MyReports from "@/pages/Dashboard/Sections/MyReports";
import NewServicesAndEquipments from "@/pages/Dashboard/Sections/NewServicesAndEquipments";
import ActiveProcesses from '@/pages/Dashboard/Sections/ActiveProcesses';

const Dashboard = () => {
  const { t } = useTranslation(['translation']);
  const [userInfo, setUserInfo] = useState<IUser>();
  const [dashboardComponents, setDashboardComponents] = useState<IComponent[]>([]);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  function getWelcomeMessage() {
    const time = new Date().getHours();
    if (6 <= time && time <= 11)
      return `goodMorning`;
    else if (12 <= time && time <= 17)
      return `goodDay`;
    return `goodEvening`;
  }

  const componentMapping = {
    LastVisitedUnits: LastVisitedUnits,
    Reports: Reports,
    UnitStatus: UnitStatus,
    MyReports: MyReports,
    NewServicesAndEquipments: NewServicesAndEquipments,
    ActiveProcesses: ActiveProcesses
  };

  useEffect(() => {
    getDashboardPage().then(res => {
      if (res.ok) {
        setUserInfo(res?.body.user);
        setDashboardComponents(res?.body.components)
      } else {
        navigate(ROUTES.Page500)
      }
      setLoading(false)
    });
  }, [])

  return (
    <DashboardPageContainer>
      {
        loading ? (
          <>
            <div style={{ paddingTop: spacing.large1 }}>
              <H1Placeholder width={8} />
            </div>
            <div style={{ paddingTop: spacing.medium3 }}>
              <H2Placeholder width={6} />
            </div>
            <div style={{ marginTop: spacing.small3 }}>
              <SearchPlaceholder />
            </div>
            <DashboardComponentPlaceholder>
              <ProgressCardListPlaceholder numberOfItems={5} includeHeader={true} numerOfPagination={3} />
            </DashboardComponentPlaceholder>
            <DashboardComponentPlaceholder link={true}>
              <GraphicCardListPlaceholder numberOfCards={3} includeIcon={true} />
            </DashboardComponentPlaceholder>
          </>
        ) : (
          <>
            <GreetingContainer>
              <h1 className={fontClasses.hw1}>{`${t('dashboard.greetings.' + getWelcomeMessage())}, ${userInfo?.firstName}`}</h1>
            </GreetingContainer>
            <SearchContainer>
              <h2 className={fontClasses.hw2}>{t('dashboard.searchHeading')}</h2>
              <UnitSearch />
            </SearchContainer>
            <ComponentsContainer>
              {
                dashboardComponents?.map((comp, index) => {
                  const Component = componentMapping[comp.name];
                  return (<Component  key={index}  {...comp} />);
                })
              }
            </ComponentsContainer>
          </>
        )
      }
    </DashboardPageContainer>
  );
}
export default Dashboard;
