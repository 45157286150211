import styled from "styled-components";

export const Label = styled.label`
`;
export const Select = styled.select`
    &:disabled {
        cursor: not-allowed;
    }
`;
export const Container = styled.div<{ width?: string, height?: string; }>`
    width: ${ (props) => (props.width ? `${ props.width }px` : 'auto') };
`;