import { colors } from "@/ui/colors";
import styled from "styled-components";

export const Suggestion = styled.li`
    cursor: pointer;

    a:focus > .hw-search__suggestion-link,
    a:hover > .hw-search__suggestion-link {
        background-color: ${colors.lightGreyFill};

        p {
            color: ${colors.primary};
        }
    }
`