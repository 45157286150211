import { H2Loading } from "./style"
interface IH2PlaceholderProps {
    width: number
}

const H2Placeholder = ({ width }: IH2PlaceholderProps) => {
    return (
        <H2Loading className="loading-content" width={width.toString()} />
    )
}

export default H2Placeholder