import { FunctionComponent } from 'react';
import { CustomTooltipContainer, FloatingLabelContainer, FloatingPieLabelContainer, Label, LabelContainer } from './style';
import { fontClasses } from '@/ui/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import {faSquare} from "@fortawesome/pro-solid-svg-icons/faSquare";

export const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const {x, y, payload} = props;
  const text = payload.value;
  const words = text.split(' ');
  const lines = [];
  let line = [];

  words.forEach((word) => {
    const testLine = line.join(' ');
    if (testLine.length > 10) {
      lines.push(line.join(' '));
      line = [word];
    } else {
      line.push(word);
    }
  });

  lines.push(line.join(' '));

  return (
    <g transform={`translate(${x},${y})`}>
      <rect x={-12} y={5} width="24" height="24" fill={props['data-column'][payload.index].fill}/>
      {lines.map((l, i) => (
        <Link to={props['data-column'][payload.index].link} key={`a-${i}`}>
          <text
            x={0}
            y={32}
            dy={16 * (i + 1)}
            fontSize={16}
            textAnchor="middle"
            fill="#980000"
            textDecoration={props['data-column'][payload.index].link != null && "underline"}
          >
            {l}
          </text>
        </Link>
      ))}
    </g>
  );
};


interface ICustomTooltipProps {
  active?: any;
  payload?: any;
  tooltipName?: any;
  label?: any;
}

export const CustomTooltip = ({active, payload, label,}: ICustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipContainer className="custom-tooltip">
        <p className={fontClasses.technical}>{`${label}`}</p>
      </CustomTooltipContainer>
    );
  }

  return null;
}

interface IFloatingLabel {
  color: string
  label: string
  link?: string
}

interface IFloatingBarLabelsProps {
  labels: IFloatingLabel[]
}

export const FloatingBarLabels = ({labels}: IFloatingBarLabelsProps) => {
  return (
    <FloatingLabelContainer>
      {
        (labels ?? []).map((label, idx) => (
          <LabelContainer color={label.color} key={idx}>
            <FontAwesomeIcon icon={faSquare} color={label.color}/>
            {
              label.link ? (
                <Link to={label.link} style={{textDecoration: "underline"}}>
                  <Label>{label.label}</Label>
                </Link>
              ) : (
                <Label>{label.label}</Label>
              )
            }
          </LabelContainer>
        ))
      }
    </FloatingLabelContainer>
  )
}

export const FloatingPieLabels = ({labels}: IFloatingBarLabelsProps) => {
  return (
    <FloatingPieLabelContainer>
      {
        (labels ?? []).map((label, idx) => (
          <LabelContainer color={label.color} key={idx}>
            <FontAwesomeIcon icon={faSquare} color={label.color}/>
            {
              label.link ? (
                <Link to={label.link} style={{textDecoration: "underline"}}>
                  {label.label}
                </Link>
              ) : (
                <Label>{label.label}</Label>
              )
            }
          </LabelContainer>
        ))
      }
    </FloatingPieLabelContainer>
  )
}