
import { H1Loading } from "./style"

interface IH1PlaceholderProps {
    width: number
}

const H1Placeholder = ({ width }: IH1PlaceholderProps) => {
    return (
        <H1Loading className="loading-content" width={width} />
    )
}

export default H1Placeholder