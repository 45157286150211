import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoSquare } from '@fortawesome/pro-solid-svg-icons';

interface IInfoSubtleProps extends PropsWithChildren<any> {
  icon?: boolean
  light?: boolean
}

export default function InfoSubtle({icon, light, children}: IInfoSubtleProps) {
  return (
    <div className={`hw-info-subtle ${light ? 'hw-info-subtle--light' : ''}`}>
      {icon && <FontAwesomeIcon icon={faInfoSquare} className="hw-info-subtle__icon"/>}
      {children}
    </div>
  )
};