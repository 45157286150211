import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const SearchContainer = styled.div`
    display: flex;
    gap: ${spacing.small1};

`;

export const ButtonPlaceholder = styled.div`
    height: 58px;
    width: calc(${hedwigFontSizes.p} * 8);
`;

export const SearchFieldPlaceholder = styled.div`
    height: 58px;
    flex: 1;
`;