import styled from "styled-components";

export const Input = styled.input`
    background-image: none!important;
`;

export const StandaloneSearch = styled.div`
    & .hw-search__button {
        margin-top: 0;
    }
`;