import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const ProgressBarContainer = styled.div`
   display: flex;
   gap: ${spacing.small1};
`;

export const ProgressBarEntryContainer = styled.div<{ flex: number}>`
    width: 100%;
    flex: ${props => props.flex};
    background-color: ${colors.white};
    height: 8px;
    border-radius: 2px;
`;

export const Progress = styled.div<{ progress: number, color: string}>`

    border-radius: 2px;
    height: 8px;
    width: ${props => props.progress}%;
    background-color: ${props => props.color};
`;