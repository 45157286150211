import { spacing } from "@/ui/spacing";
import styled from "styled-components";
export const FormBody = styled.div`
    max-width: 450px;
`;

export const SectionTitle = styled.div`
    margin-top: ${spacing.medium3};
`;

export const ShowInMapTitle = styled.span`
`;

export const ShowInMapSpec = styled.p`
`;

export const InfoSubtleContainer = styled.div`
  
`;

