interface IScreenSizesNumber {
  xsMax: number;
  smMax: number;
  mdMax: number;
  lgMax: number;
  xsMin: number;
  smMin: number;
  mdMin: number;
  lgMin: number;
  xlMin: number;
  xlMax: number;
  xxlMin: number;
  xxlMax: number;
}

export const screenSizesNumber: IScreenSizesNumber = {
  xsMax: 575.98,
  smMax: 767.98,
  mdMax: 991.98,
  lgMax: 1199.98,
  xsMin: 0,
  smMin: 576,
  mdMin: 768,
  lgMin: 992,
  xlMin: 1200,
  xlMax: 1377.98,
  xxlMin: 1378,
  xxlMax: 1440
};


interface IScreenSizes {
  xsMax: string;
  smMax: string;
  mdMax: string;
  lgMax: string;
  xsMin: string;
  smMin: string;
  mdMin: string;
  lgMin: string;
  xlMin: string;
  xlMax: string;
  xxlMin: string;
  xxlMax: string;
}

export const screenSizes: IScreenSizes = {
  xsMax: `${screenSizesNumber.xsMax}px`,
  smMax: `${screenSizesNumber.smMax}px`,
  mdMax: `${screenSizesNumber.mdMax}px`,
  lgMax: `${screenSizesNumber.lgMax}px`,
  xsMin: `${screenSizesNumber.xsMin}px`,
  smMin: `${screenSizesNumber.smMin}px`,
  mdMin: `${screenSizesNumber.mdMin}px`,
  lgMin: `${screenSizesNumber.lgMin}px`,
  xlMin: `${screenSizesNumber.xlMin}px`,
  xlMax: `${screenSizesNumber.xlMax}px`,
  xxlMin: `${screenSizesNumber.xxlMin}px`,
  xxlMax: `${screenSizesNumber.xxlMax}px`
};

export const mediaqueries: IScreenSizes = {
  xsMax: `@media (max-width: ${screenSizes.xsMax})`,
  smMax: `@media (max-width: ${screenSizes.smMax})`,
  mdMax: `@media (max-width: ${screenSizes.mdMax})`,
  lgMax: `@media (max-width: ${screenSizes.lgMax})`,
  xlMax: `@media (max-width: ${screenSizes.xlMax})`,
  xsMin: `@media (min-width: ${screenSizes.xsMin})`,
  smMin: `@media (min-width: ${screenSizes.smMin})`,
  mdMin: `@media (min-width: ${screenSizes.mdMin})`,
  lgMin: `@media (min-width: ${screenSizes.lgMin})`,
  xlMin: `@media (min-width: ${screenSizes.xlMin})`,
  xxlMin:`@media (min-width: ${screenSizes.xxlMin})`,
  xxlMax:`@media (min-width: ${screenSizes.xxlMax})`,
};

export const unitPageContentMaxWidth = screenSizes.xxlMax

export const pageContentMaxWidth = '1140px'

