import { colors } from '../colors';
import { spacing } from '../spacing';


export const dateRangePickerStyles = `
    .pbe-datepicker {
        .react-daterange-picker {
            width: 100%;
            &__calendar {
                width: 375px;
                max-width: 100vw;
            }
            &__wrapper {
                border: none;
                //padding: ${spacing.medium2};
                // padding-left: ${spacing.small4};
                // padding-top: ${spacing.small4};
                // padding-bottom: ${spacing.small4};
                // padding-right: ${spacing.medium3};
            }
            &__calendar-button {
                &:disabled {
                    path {
                        color: ${colors.darkGrey}!important;
                    }
                }
                &:enabled:hover {
                    path {
                        color: ${colors.primaryDarker};
                    }
                }

            }
            &__inputGroup {
                padding-left: ${spacing.small2};
                padding-top: ${spacing.small4};
                padding-bottom: ${spacing.small4};
                padding-right: ${spacing.small2};
                width: auto;
                height: auto;
                &__input {
                    font-size: 18px;
                }
                &__leadingZero {
                    font-size: 18px;
                }
                background: ${colors.lightGreyFill};

            }

            &--disabled {
                background-color: transparent;
            }
        }
        &--white {
            .react-daterange-picker {
                &__inputGroup {
                    background: ${colors.white};
                }
            }
        }
        &--disabled {
            .react-daterange-picker {
                //background: ${colors.grey};
                &__inputGroup {
                    background: ${colors.grey};
                } 
            }
            &__home
        }
        &--error {
            .react-daterange-picker {
                &__wrapper {
                    border-bottom: ${colors.yellow} solid 2px;
                }
            }
        }
    }
`;

export const calenderStyles = `
    .pbe-datepicker {
        .react-calendar {
            width: 100%;
            &__navigation {
                margin-bottom: 0;
                padding: ${spacing.medium2};
                height: auto;
                &__next2-button, &__prev2-button {
                    display: none;
                }
            }
            &__month-view {
                &__weekdays {
                    & abbr[title] {
                        text-decoration: none;
                        font-size: 12px;
                        color: ${colors.darkGrey};
                    }
                }
                &__days {
                    &__day {
                        &--neighboringMonth {
                            abbr {
                                color: ${colors.darkGrey};
                            }
                        }
                    }
                }
            }
            &__tile {
                padding: ${spacing.medium1} ${spacing.small3};
                & abbr {
                    white-space: nowrap;
                }
                &--now {
                    border: ${colors.black} solid 2px;
                    background: transparent;
                    border-radius: 4px;
                }
                &--rangeStart {
                    background: ${colors.primary}!important;
                    border: ${colors.primary} solid 2px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    abbr {
                        color: ${colors.white}!important;
                    }
                    &:hover {
                        background: ${colors.white};
                        abbr {
                            color: ${colors.black}!important;
                        }
                    }
                }
                &--rangeStart, &--rangeEnd {
                    background: ${colors.primary}!important;
                    abbr {
                        color: ${colors.white};!important;
                    }
                    &:hover {
                        background: ${colors.white}!important;
                        abbr {
                            color: ${colors.black};!important;
                        }
                    }
                }
                &--rangeEnd {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    background: ${colors.primary}!important;
                    border: ${colors.primary} solid 2px;
                    &:hover {
                        background: ${colors.white}!important;
                    }
                }
                &--range {
                    background: ${colors.primaryLight};
                    border-top: ${colors.primaryLight} solid 2px;
                    border-bottom: ${colors.primaryLight} solid 2px;
                    &:hover {
                        background: ${colors.white};    
                    }
                }
            }
            &--selectRange {
                background: ${colors.white};
            }
        }
        &--white {
            react-calender {
                &--selectRange {
                    background: ${colors.white};
                }
            }
            
        }
    }
`;