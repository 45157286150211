import { PropsWithChildren } from "react"

interface IAccordionProps extends PropsWithChildren<any> {

}

const Accordion = ({children}: IAccordionProps) => {
    return (
        <ul className='hw-accordion'>
            {children}
        </ul>
    )
    
}

export default Accordion