import { SearchContainer, SearchFieldPlaceholder, ButtonPlaceholder } from "./style"
interface ISearchPlaceholder {
}

const SearchPlaceholder = () => {
    return (
        <SearchContainer>
            <SearchFieldPlaceholder className="loading-content" />
            <ButtonPlaceholder className="loading-content" />
        </SearchContainer>
    )
}

export default SearchPlaceholder