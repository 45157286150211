import { PropsWithChildren } from "react";
import { Label } from "./style"
import { CheckboxVariants } from "@/models/forms/formTypes"

interface CheckboxProps extends PropsWithChildren<any> {
  name?: string
  id?: string
  disabled?: boolean
  checked?: boolean
  variants?: CheckboxVariants[]
  errorMessage?: string
  onChange?: (e: any) => void
}

const Checkbox = ({
                    name,
                    id,
                    disabled,
                    checked,
                    errorMessage,
                    variants,
                    onChange,
                    children,
                  }: CheckboxProps) => {
  let checkboxVariants = ''
  for (const variant of (variants ?? []).filter(v => v !== '')) {
    checkboxVariants += ' hw-checkbox--' + variant
  }
  return (
    <>
      <Label className={`hw-checkbox${checkboxVariants} ${disabled ? 'label_disabled' : ''} ${errorMessage && errorMessage !== '' ? ' hw-checkbox--error' : ''}`}>
        {children}
        <input type="checkbox" checked={checked} id={id} name={name} disabled={disabled} onChange={onChange} autoComplete="off"/>
        <i className="hw-checkbox__indicator"></i>
      </Label>
      {
        errorMessage && errorMessage !== '' ? (
          <div className="hw-error-simple hw-error--align-left">{errorMessage}</div>
        ) : <></>
      }
    </>
  )
}
export default Checkbox;