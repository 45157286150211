import { useEffect, useState } from "react";
import { PaginationContainer } from './style';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IPaginationProps {
  totalCount: number;
  recordsToDisplay: number;
  currentPage?: number
  setCurrentPage?: (page: number) => void;
}

const Pagination = ({totalCount, currentPage, setCurrentPage, recordsToDisplay}: IPaginationProps) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(totalCount / recordsToDisplay))

  useEffect(() => {
    setTotalPages(Math.ceil(totalCount / recordsToDisplay))
  }, [totalCount, recordsToDisplay])

  useEffect(() => {
    if (currentPage > totalPages && setCurrentPage) setCurrentPage(totalPages ?? 1)
  }, [currentPage, totalPages])

  function handlePageChange(page: number) {
    if (page < 1 || page > totalPages) return
    if (setCurrentPage) setCurrentPage(page)
  }

  const renderPageNumber = (page: number) => (
    <button
      key={page}
      onClick={() => handlePageChange(page)}
      className={page === currentPage ? 'active' : ''}
    >
      {page}
    </button>
  );

  return (
    <PaginationContainer>
      <button onClick={() => handlePageChange(currentPage - 1)} aria-label="previousPage">
        <FontAwesomeIcon icon={faChevronLeft}/>
      </button>

      {/* Always render the first page */}
      {renderPageNumber(1)}

      {/* If currentPage is 6 or below */}
      {currentPage <= 5 ? (
          <>
            {Array.from({length: Math.min(5, totalPages - 1)}, (_, index) => index + 2).map(renderPageNumber)}
            {totalPages > 7 && <div className="ellipsis">...</div>}
            {totalPages > 6 && renderPageNumber(totalPages)}
          </>
        ) :
        /* If currentPage is in the middle */
        currentPage > 5 && currentPage <= totalPages - 4 ? (
            <>
              <div className="ellipsis">...</div>
              {Array.from({length: 5}, (_, index) => currentPage + index - 2).map(renderPageNumber)}
              {currentPage + 2 < totalPages - 2 && <div className="ellipsis">...</div>}
              {renderPageNumber(totalPages)}
            </>
          ) :
          /* If currentPage is among the last 5 pages */
          (
            <>
              <div className="ellipsis">...</div>
              {Array.from({length: Math.min(5, totalPages)}, (_, index) => totalPages - index).reverse().map(renderPageNumber)}
            </>
          )}

      <button aria-label="nextPage" onClick={() => handlePageChange(currentPage + 1)}>
        <FontAwesomeIcon icon={faChevronRight}/>
      </button>
    </PaginationContainer>
  );
};

export default Pagination;
