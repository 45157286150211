import styled from "styled-components";
import { spacing } from "@/ui/spacing"
import { colors } from "@/ui/colors"
import { hedwigFontSizes } from "@/ui/typography"

export const BannerContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        color: ${colors.primaryDarker};
    }

    p {
        color: ${colors.primaryDark};

    }
`;

export const PLoading = styled.div`
    width: 80px;
    height: calc(${hedwigFontSizes.p} * 1.5);
`;

export const H1Loading = styled.div<{
  width: number,
}>`
    width: ${(props) => (props.width)}px;
    height: calc(${hedwigFontSizes.h1} * 1.5);
    margin-top: ${spacing.small1};
`;

export const LoadingHeaderContainer = styled.div`
    display: flex;
    gap: ${spacing.small1};
`;
