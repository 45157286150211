import { useTranslation } from 'react-i18next';
import { IMessage } from "@/models/UnitPage/message";
import { ButtonContainer, MessageContainer, MessageInformationContainer, MessageText } from "./style";
import DescretButton from '@/components/base/descrete-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fontClasses } from "@/ui/typography";
import { formatDate } from "@/helpers/dateHelpers";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons/faSyncAlt";
import { faEdit } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons/faTrashAlt";

interface IMessageCardProps {
  message?: IMessage
  onEdit?: (idMessage: number) => void
  onDelete?: (idMessage: number) => void
  onRestore?: (idMessage: number) => void
  deleteable?: boolean
  editable?: boolean
}

const MessageCard = ({ message, onDelete, onEdit, onRestore, deleteable, editable }: IMessageCardProps) => {
  const { t } = useTranslation(['translation']);
  if (Date.now() > new Date(message.toDate).getTime())
    message.deleted = true;
  return (
    <MessageContainer className={message && message.deleted ? "history" : undefined}>
      <MessageText>{message.text ?? ''}</MessageText>
      <MessageInformationContainer className={fontClasses.captionTitle}>
        {
          message?.mapMessage && (
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          )
        }
        {
          message?.fromDate && message?.toDate && (
            <span>{`${formatDate(message.fromDate)} - ${formatDate(message.toDate)}`}</span>
          )
        }
      </MessageInformationContainer>
      <ButtonContainer className="button-group">
        {
          message.deleted ? (
            <DescretButton icon={faSyncAlt} text={t('common.restore')} onClick={() => onRestore(message.idMessage)} />
          ) : (
            <>
              {editable && <DescretButton icon={faEdit} text={t('common.edit')} onClick={() => onEdit(message.idMessage)} />}
              {deleteable && <DescretButton icon={faTrashAlt} text={t('common.delete')} onClick={() => onDelete(message.idMessage)} />}
            </>
          )
        }
      </ButtonContainer>
    </MessageContainer>
  );
}
export default MessageCard;