import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IEditableMessage, IMessage } from "@/models/UnitPage/message";
import { FormBody, SectionTitle, ShowInMapSpec, ShowInMapTitle, InfoSubtleContainer } from "./style";
import TextArea from "@/components/form-components/text-area";
import DateRangePicker from "@/components/form-components/date-range-picker"
import Checkbox from "@/components/form-components/checkbox";
import { getValidationErrorField } from "@/helpers/validators";
import ValidationErrorInformation from "@/components/validation-error-information";
import { fontAdjustmentClasses, fontClasses } from "@/ui/typography";

interface IMessageFormProps {
  message?: IMessage
  variant: 'white' | ''
  mapMessageExist?: boolean
}

export interface IMessageFormRef {
  validate: () => boolean
  cancel: () => void
  getEditableMessage: () => IMessage
}

const MessageForm = forwardRef<IMessageFormRef, IMessageFormProps>(({message, variant, mapMessageExist}: IMessageFormProps, ref: any) => {
  const {t} = useTranslation(['translation']);
  const [editableMessage, setEditableMessage] = useState<IEditableMessage>({
    ...message,
    permanently: !(message.fromDate && message.toDate)
  })
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [validationErros, setValidationError] = useState({
    text: '',
    dateRange: ''
  })

  const validations = useRef({
    text: '',
    dateRange: ''
  })

  useEffect(() => {
    setEditableMessage({
      ...message,
      permanently: !(message.fromDate && message.toDate)
    })
  }, [message])

  useImperativeHandle(ref, () => ({
    validate(): boolean {
      validate(editableMessage)
      const errorFields = getValidationErrorField(validations.current)
      setErrorFields(errorFields)
      return errorFields.length === 0
    },
    cancel() {
      setEditableMessage(undefined)
      setErrorFields([])
    },
    getEditableMessage(): IMessage {
      const messageToUpdate = Object.assign({}, editableMessage)
      if (messageToUpdate.permanently) {
        messageToUpdate.fromDate = undefined
        messageToUpdate.toDate = undefined
      }
      messageToUpdate.permanently = undefined
      return messageToUpdate
    }
  }))

  const handleFromToChanged = (value: Date[] | null[]) => {
    const editedMessage = {
      ...editableMessage,
      fromDate: value !== null ? value[0] : undefined,
      toDate: value !== null ? value[1] : undefined
    }
    setEditableMessage(editedMessage)
    validate(editedMessage)
  }

  const validate = (editedMessage: IEditableMessage) => {
    if (editedMessage.text && editedMessage.text !== '') {
      validations.current.text = ''
    } else {
      validations.current.text = `${t('unitPage.messageSection.messageText')}${t('validationError.requiredPostFix')}`
    }
    if (!editedMessage.permanently && !(editedMessage.fromDate && editedMessage.toDate)) {
      validations.current.dateRange = `${t('validationError.dateRange.required')}`
    } else {
      validations.current.dateRange = ''
    }
    setValidationError(Object.assign({}, validations.current))
  }

  const handlePermanentChanged = (e: any) => {
    const checked = e.currentTarget.checked
    const editedMessage = {
      ...editableMessage,
      permanently: checked,
      fromDate: undefined,
      toDate: undefined,
    }
    setEditableMessage(editedMessage)
    validate(editedMessage)
  }

  const handleShowInMap = (e: any) => {
    const editedMessage = {
      ...editableMessage,
      mapMessage: e.currentTarget.checked
    }
    setEditableMessage(editedMessage)
  }

  const handleMessageText = (e: any) => {
    const editedMessage = {
      ...editableMessage,
      text: e.currentTarget.value
    }
    setEditableMessage(editedMessage)
    validate(editedMessage)
  }

  return (
    <FormBody>
      <TextArea
        id="messageText"
        name="messageText"
        label={t('unitPage.messageSection.messageText')}
        variant={variant}
        value={editableMessage?.text ?? ''}
        errorMessage={validationErros.text}
        onChange={handleMessageText}
      />
      <SectionTitle className={fontClasses.bodySmallTitle}>{t('unitPage.messageSection.lifeSpan')}</SectionTitle>
      <DateRangePicker
        labelFrom={t('unitPage.messageSection.validFrom')}
        labelTo={t('unitPage.messageSection.validTo')}
        selectedFrom={editableMessage?.fromDate}
        selectedTo={editableMessage?.toDate}
        disabled={editableMessage?.permanently}
        errorMessage={validationErros.dateRange}
        variant={variant}
        onChange={handleFromToChanged}
      />
      <Checkbox
        id="permanentMessage"
        name="permanentMessage"
        checked={editableMessage?.permanently}
        label={t('unitPage.messageSection.permanently')}
        onChange={handlePermanentChanged}>
        {t('unitPage.messageSection.permanently')}
      </Checkbox>

      <SectionTitle className={fontClasses.bodySmallTitle}>{t('unitPage.messageSection.spesifications')}</SectionTitle>
      <Checkbox
        id="showInMapMessage"
        name="showInMapMessage"
        checked={editableMessage?.mapMessage}
        variants={['bounding', 'full']}
        label={t('unitPage.messageSection.showInMap')}
        onChange={handleShowInMap}
      >
        <ShowInMapTitle className={fontClasses.bodySmallTitle}>{t('unitPage.messageSection.showInMap')}</ShowInMapTitle>
        <ShowInMapSpec className={fontAdjustmentClasses.small}>{t('unitPage.messageSection.shopInMapExplain')}</ShowInMapSpec>
      </Checkbox>
      {
        errorFields && errorFields.length > 0 ? (
          <InfoSubtleContainer>
            <ValidationErrorInformation fields={errorFields}/>
          </InfoSubtleContainer>
        ) : <></>
      }

    </FormBody>
  );
})
export default MessageForm;