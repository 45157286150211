import { Select, Label, Container } from "./styles"
import { InputVariants } from "@/models/forms/formTypes"

interface IBaseDropdownProps {
  name?: string
  id?: string
  label?: string
  errorMessage?: string
  disabled?: boolean
  options?: {
    label: string
    value: string | number
  }[] | undefined
  required?: boolean
  selected?: number | string | undefined
  variant?: InputVariants
  undefinedLabel?: string
  onChange?: (e: any) => void
  onErrorMessage?: (text: string) => void
  readOnly?: boolean
  ariaControls?: string
  ariaLabel?: string
  width?: string
  height?: string
}

const BaseDropdown = ({
  label,
  name,
  variant,
  id,
  selected,
  disabled,
  onChange,
  options,
  errorMessage,
  undefinedLabel,
  ariaLabel,
  width,
  height
}: IBaseDropdownProps) => {
  let variation = variant ? ' hw-dropdown--' + variant : ''
  let labelVariation = variant === 'line' ? ' hw-label--line' : ''
  if (errorMessage && errorMessage !== '') {
    variation += ' hw-dropdown--error'
    labelVariation += ' hw-label--error'
  }
  return (
    <Container width={width} height={height}>
      {label && <Label htmlFor={id} className={"hw-label" + labelVariation}>
        {label}
      </Label>}
      <Select
        id={id}
        name={name}
        aria-labelledby={ariaLabel}
        value={selected}
        disabled={disabled}
        onChange={onChange}
        className={"hw-dropdown hw-dropdown--native" + variation}>
        {
          (selected === undefined || selected === null) && undefinedLabel && (
            <option value={selected}>{undefinedLabel}</option>
          )
        }
        {
          options && options.map((opt, idx) => (
            <option key={idx} value={opt.value}>{opt.label}</option>
          ))
        }
      </Select>
      {
        errorMessage && errorMessage !== '' && (
          <div className="hw-error hw-error--align-right hw-error--indented">{errorMessage}</div>
        )
      }

    </Container>
  )
}
export default BaseDropdown;