import { pageSpacing, spacing } from "@/ui/spacing";
import { mediaqueries, unitPageContentMaxWidth } from "@/ui/mediaqueries";
import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const OuterWrapper = styled.div`
    width: 100%;
    max-width: ${unitPageContentMaxWidth};
`;

export const BannerBackground = styled.div`
    width: 100%;
    padding-left: ${spacing.medium2};
    padding-bottom: ${spacing.medium4};
    padding-top: ${spacing.medium4};

    ${mediaqueries.xsMax} {
        padding-left: ${spacing.medium1};
        padding-top: ${spacing.medium2};
    }
`;

export const InnerPageWrapper = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 500px;
`;

export const ContentWrapper = styled.div`
    ${pageSpacing.padding}
    margin-left: 324px;
    position: relative;
    min-height: 600px;

    ${mediaqueries.lgMax} {
        margin-left: 0;
    }
`;
