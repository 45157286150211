import { PropsWithChildren, useState } from "react"
import { IconContainer, ListElement } from "./style"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";

interface IAccordionItemProps extends PropsWithChildren<any> {
    title?: string | any
    expanded?: boolean

}

const AccordionItem = ({ expanded, children, title }: IAccordionItemProps) => {
    const id = "item-" + Math.floor(Math.random() * 10000)
    const [isExpanded, setIsExpanded] = useState(expanded ?? false)
    const handleToggle = () => {
        setIsExpanded(!isExpanded)
    }
    return (
        <ListElement
            className={
                'hw-accordion__item' +
                (isExpanded ? ' hw-accordion__item--expanded' : '')
            }
        >
            <button
                className='hw-accordion__trigger'
                onClick={handleToggle}
                aria-controls={id}
                aria-expanded={isExpanded}
            >
                {title}
                <IconContainer>
                    <FontAwesomeIcon className="close-icon" icon={faChevronDown} />
                </IconContainer>
            </button>
            {
                isExpanded && (
                    <div id={id} className='hw-accordion__contents'>
                        {children}
                    </div>
                )
            }
            
        </ListElement>
    )
}

export default AccordionItem