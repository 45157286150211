import PaginationPlaceholder from "@/components/loading/pagination-placeholder"
import ProgressCardPlaceholder from "@/components/loading/progress-card-placeholder"
import { getRandomNumberBetween } from "@/helpers/random"
import { ListContainer } from "./style"

interface IProgressCardListPlaceholderProps {
    numberOfItems: number
    numerOfPagination?: number
    ordered?: boolean
    includeHeader?: boolean
    includeInfo?: boolean
    includeFirstProgress?: boolean
    includeSecondProgress?: boolean
}

interface IProgressCardItem {
    headerWidth?: number
    infoTextWidth?: number
    firstProgress?: number
    secondProgress?: number
}

const ProgressCardListPlaceholder = ({ numberOfItems, numerOfPagination, ordered, includeHeader, includeInfo, includeFirstProgress, includeSecondProgress}: IProgressCardListPlaceholderProps) => {
    const progressList: IProgressCardItem[] = []
    for (let i = 0; i < numberOfItems; i++) {
        progressList.push({
            headerWidth: includeHeader ? getRandomNumberBetween(13, 16) : undefined,
            infoTextWidth: includeInfo ? getRandomNumberBetween(6, 8) : undefined,
            firstProgress: includeFirstProgress ? getRandomNumberBetween(0, 100) : undefined,
            secondProgress: includeSecondProgress ? getRandomNumberBetween(0, 100) : undefined
        })
    }
    if (ordered) {
        progressList.sort((a, b) => {
            if (a.firstProgress > b.firstProgress) {
                return -1
            } else if (a.firstProgress < b.firstProgress) {
                return 1
            } else {
                return 0
            }
        })
    }
    return (
        <PaginationPlaceholder hide={numerOfPagination === undefined} numberOfPages={numerOfPagination} >
            <ListContainer>
                {
                    progressList.map((item, idx) => (
                        <ProgressCardPlaceholder
                            key={idx}
                            headerWidth={item.headerWidth}
                            infoTextWidth={item.infoTextWidth}
                            firstProgress={item.firstProgress}
                            secondProgress={item.secondProgress} />
                    ))
                }
            </ListContainer>
        </PaginationPlaceholder>
    )
}

export default ProgressCardListPlaceholder