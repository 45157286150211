import * as styled from 'styled-components';
import { resets } from '@/ui/styles/css-resets';
import { inputStyles, labelStyles, selectStyles, textAreaStyles } from '@/ui/styles/input';
import { calenderStyles, dateRangePickerStyles } from '@/ui/styles/dateRangePicker';
import { buttonStyles } from '@/ui/styles/button';
import { searchStyles } from '@/ui/styles/search';
import { colors } from '@/ui/colors'
import { headerStyles } from '../header'
import { loading } from './loading'
import { hedwigAccordion } from "./accordion"
import { tableStyle } from "./table"
import { datePickerStyles, timePickerStyles } from '@/ui/styles/datePicker';

const GlobalStyle = styled.createGlobalStyle`
    :root {
        --hw-navbar-search-max-width: 70%;
    }

    * {
        box-sizing: border-box;
        color: ${colors.black};
    }

    html {
        height: 100%;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
        padding: 0;
        background-color: ${colors.white};
    }

    .separator {
        height: 1px;
        background-color: var(--hw-color-gray-light-stroke);
    }

    @media print {
        body {
            -webkit-print-color-adjust: exact;
        }
    }
    
    ${inputStyles}
    ${selectStyles}
    ${labelStyles}
    ${textAreaStyles}
    ${resets}
    ${dateRangePickerStyles}
    ${calenderStyles}
    ${searchStyles}
    ${buttonStyles}
    ${headerStyles}
    ${loading}
    ${hedwigAccordion}
    ${tableStyle}
    ${datePickerStyles}
    ${timePickerStyles}
`;

export default GlobalStyle;
