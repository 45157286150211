import styled from "styled-components";
import { mediaqueries } from "@/ui/mediaqueries"
import { spacing, cardSpacing } from "@/ui/spacing"
import { colors } from "@/ui/colors";
import { hedwigFontSizes } from "@/ui/typography";

export const ComponentContainer = styled.div`
    margin-top: ${spacing.large2};
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${spacing.small4};
    margin-bottom: ${spacing.small4};
`;

export const HeaderText = styled.div<{width: number}>`
    height: calc(${hedwigFontSizes.h3} * 1.6);
    width: calc(${hedwigFontSizes.h3} * 1.6 * ${props => props.width});
`;

export const HeaderLinkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.small2};
`;

export const HeaderLinkText = styled.div<{width: number}>`
    height: calc(${hedwigFontSizes.p} * 1.6);
    width: calc(${hedwigFontSizes.p} * 1.6 * ${props => props.width});
`;

export const HeaderLinkIcon = styled.div`
    height: calc(${hedwigFontSizes.p} * 1.3);
    width: calc(${hedwigFontSizes.p} * 1.6);
`;