import { mediaqueries, pageContentMaxWidth } from "@/ui/mediaqueries";
import { defaultPageSpacing, spacing } from "@/ui/spacing";
import styled from "styled-components";

export const DashboardPageContainer = styled.div`
    width: 100%;
    max-width: ${pageContentMaxWidth};
    padding-bottom: ${defaultPageSpacing.yPadding};
`;

export const LoadingContainer = styled.div`
    display: flex;
    gap: ${spacing.medium2};
    flex-direction: column;
`;

export const GreetingContainer = styled.div`
    align-items: flex-start;
    padding-top: ${spacing.large1};
    padding-bottom: ${spacing.medium3};
`;

export const ComponentsContainer = styled.div`
    & > div:last-child {
        margin-bottom: 0;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: ${spacing.large2};
`;
