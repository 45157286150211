import { mediaqueries } from "./mediaqueries";

export interface ISpacing {
  small1: string;
  small2: string;
  small3: string;
  small4: string;
  medium1: string;
  medium2: string;
  medium3: string;
  medium4: string;
  large1: string;
  large2: string;
  large3: string;
  large4: string;
  large5: string;
}

export interface IPadding {
  left: string;
  top: string;
  bottom: string;
  right: string;
}

export const spacing: ISpacing = {
  small1: 'var(--hw-spacing-small-1)',// '4px'
  small2: 'var(--hw-spacing-small-2)',// '8px'
  small3: 'var(--hw-spacing-small-3)',// '12px'
  small4: 'var(--hw-spacing-small-4)',// '16px'
  medium1: 'var(--hw-spacing-medium-1)',// '20px'
  medium2: 'var(--hw-spacing-medium-2)',// '24px'
  medium3: 'var(--hw-spacing-medium-3)',// '32px'
  medium4: 'var(--hw-spacing-medium-4)',// '40px'
  large1: 'var(--hw-spacing-large-1)',// '48px'
  large2: 'var(--hw-spacing-large-2)',// '64px'
  large3: 'var(--hw-spacing-large-3)',// '80px'
  large4: 'var(--hw-spacing-large-4)',// '120px'
  large5: 'var(--hw-spacing-large-5)',// '160px'
};

export const cardPaddings: IPadding = {
  left: `padding-left:${spacing.small4};`,
  right: `padding-right:${spacing.small4};`,
  top: `padding-left:${spacing.medium2};`,
  bottom: `padding-right:${spacing.medium2};`,
}

export const cardSpacing: IComponentSpacing = {
  xPadding: `
    padding-left:${spacing.small4};
    padding-right:${spacing.small4};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium3};
      padding-right:${spacing.medium3};
    }
    `,
  yPadding: `
    padding-top:${spacing.medium2};
    padding-bottom:${spacing.medium2};
    ${mediaqueries.smMin} {
      padding-top:${spacing.medium3};
      padding-bottom:${spacing.medium3};
    }
    `,
  padding: `
    padding-left:${spacing.small4};
    padding-right:${spacing.small4};
    padding-top:${spacing.medium2};
    padding-bottom:${spacing.medium2};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium3};
      padding-right:${spacing.medium3};
      padding-top:${spacing.medium3};
      padding-bottom:${spacing.medium3};
    }
    `,
  inversePadding: `
    margin-left:calc(${spacing.small4} * -1);
    margin-right:calc(${spacing.small4} * -1);
    margin-top:calc(${spacing.medium2} * -1);
    ${mediaqueries.smMin} {
      margin-left:calc(${spacing.medium3} * -1);
      margin-right:calc(${spacing.medium3} * -1);
      margin-top:calc(${spacing.medium3} * -1);
    }
    `,
  margin: `
    margin-left:${spacing.small3};
    margin-right:${spacing.small3};
    margin-bottom:${spacing.medium2};
    ${mediaqueries.smMin} {
      margin-bottom:${spacing.medium3};
    }
  `,
  xMargin: `
    margin-left:${spacing.small3};
    margin-right:${spacing.small3};
  `,
  yMargin: `
    margin-bottom:${spacing.medium2};
    ${mediaqueries.smMin} {
      margin-bottom:${spacing.medium3};
    }
  `,

}

export const modalSpacing: IComponentSpacing = {
  xPadding: `
    padding-left:${spacing.medium3};
    padding-right:${spacing.medium3};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium4};
      padding-right:${spacing.medium4};
    }
    `,
  yPadding: `
    padding-top:${spacing.medium3};
    padding-bottom:${spacing.medium3};
    ${mediaqueries.smMin} {
      padding-top:${spacing.medium4};
      padding-bottom:${spacing.medium4};
    }
    `,
  padding: `
    padding-left:${spacing.medium3};
    padding-right:${spacing.medium3};
    padding-top:${spacing.medium3};
    padding-bottom:${spacing.medium3};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium4};
      padding-right:${spacing.medium4};
      padding-top:${spacing.medium4};
      padding-bottom:${spacing.medium4};
    }
    `,
  inversePadding: `
    margin-left:calc(${spacing.medium3} * -1);
    margin-right:calc(${spacing.medium3} * -1);
    margin-top:calc(${spacing.medium3} * -1);
    ${mediaqueries.smMin} {
      margin-left:calc(${spacing.medium4} * -1);
      margin-right:calc(${spacing.medium4} * -1);
      margin-top:calc(${spacing.medium4} * -1);
    }
    `,
}

export interface IComponentSpacing {
  yPadding?: string
  xPadding?: string
  padding?: string
  yMargin?: string
  xMargin?: string
  margin?: string
  inversePadding?: string
}

export const pageSpacing: IComponentSpacing = {
  padding: `
    padding-left:${spacing.small3};
    padding-right:${spacing.small3};
    padding-bottom:${spacing.large4};
    padding-top:${spacing.small2};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium3};
      padding-right:${spacing.medium3};
      padding-bottom:${spacing.large5};
    }
  `,
  yPadding: `
    padding-top:${spacing.small2};
    padding-bottom:${spacing.large4};
    ${mediaqueries.smMin} {
      padding-bottom:${spacing.large5};
    }
  `,
  xPadding: `
    padding-left:${spacing.small3};
    padding-right:${spacing.small3};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium3};
      padding-right:${spacing.medium3};
    }
  `,
  margin: `
    margin-left:${spacing.small3};
    margin-right:${spacing.small3};
    margin-bottom:${spacing.medium2};
    margin-top:0;
    ${mediaqueries.xxlMin} {
      margin-right: 0;
    }
  `,
  xMargin: `
    margin-left:${spacing.small3};
    margin-right:${spacing.small3};
    ${mediaqueries.xxlMin} {
      margin-right: 0;
    }
  `,
  yMargin: `
    margin-bottom:${spacing.medium2};
    margin-top:0;
  `,
  inversePadding: `
    margin-left:calc(${spacing.small3} * -1);
    margin-right:calc(${spacing.small3} * -1);
    ${mediaqueries.smMin} {
      margin-left:calc(${spacing.medium3} * -1);
      margin-right:calc(${spacing.medium3} * -1);
    }

    ${mediaqueries.xxlMin} {
      margin-right: 0
    }
  `
}

export const defaultPageSpacing: IComponentSpacing = {
  padding: `
    padding-left:${spacing.small3};
    padding-right:${spacing.small3};
    padding-bottom:${spacing.large4};
    padding-top:${spacing.small2};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium3};
      padding-right:${spacing.medium3};
      padding-bottom:${spacing.large5};
    }

    ${mediaqueries.xxlMin} {
      padding-right: 0;
      padding-left: 0;
    }
  `,
  yPadding: `
    padding-top:${spacing.small2};
    padding-bottom:${spacing.large4};
    ${mediaqueries.smMin} {
      padding-bottom:${spacing.large5};
    }
  `,
  xPadding: `
    padding-left:${spacing.small3};
    padding-right:${spacing.small3};
    ${mediaqueries.smMin} {
      padding-left:${spacing.medium3};
      padding-right:${spacing.medium3};
    }

    ${mediaqueries.xxlMin} {
      padding-right: 0;
    }
  `,
  margin: `
    margin-left:${spacing.small3};
    margin-right:${spacing.small3};
    margin-bottom:${spacing.medium2};
    margin-top:0;
    ${mediaqueries.xxlMin} {
      margin-right: 0;
    }
  `,
  xMargin: `
    margin-left:${spacing.small3};
    margin-right:${spacing.small3};
    ${mediaqueries.xxlMin} {
      margin-right: 0;
    }
  `,
  yMargin: `
    margin-bottom:${spacing.medium2};
    margin-top:0;
  `,
  inversePadding: `
    margin-left:calc(${spacing.small3} * -1);
    margin-right:calc(${spacing.small3} * -1);
    ${mediaqueries.smMin} {
      margin-left:calc(${spacing.medium3} * -1);
      margin-right:calc(${spacing.medium3} * -1);
    }

    ${mediaqueries.xxlMin} {
      margin-right: 0
    }
  `
}

export const pageTitleSpacing: IComponentSpacing = {
  margin: `
    margin-left:${spacing.medium2};
    margin-right:${spacing.medium2};
    margin-bottom:${spacing.medium3};
    margin-top:${spacing.medium3};
  `
}

export const buttonGroupSpacing = {
  gap: spacing.medium2
}
