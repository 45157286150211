import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: ${spacing.medium2};
    overflow: auto;
`;