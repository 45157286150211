import { spacing } from "@/ui/spacing";
import { hedwigFontSizes } from "@/ui/typography";
import styled from "styled-components";

export const PaginationContainer = styled.div`

`;

export const PaginationHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${spacing.small2};
`;

export const HeaderLeftText = styled.div`
    height: calc(${hedwigFontSizes.technical} * 1.6);
    width: calc(${hedwigFontSizes.technical} * 1.6 * 8);
`;

export const HeaderRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.small2};
`;

export const HeaderRightText = styled.div`
    height: calc(${hedwigFontSizes.technical} * 1.6);
    width: calc(${hedwigFontSizes.technical} * 1.6 * 6);
    max-width: 100%;
`;

export const Dropdown = styled.div`
    height: calc(${hedwigFontSizes.technical} * 2.5);
    width: calc(${hedwigFontSizes.technical} * 2.5 * 1.8);
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.small2};
    margin-top: ${spacing.small3};
`;

export const ChevronIcon = styled.div`
    height: calc(${hedwigFontSizes.p} * 1.6);
    width: calc(${hedwigFontSizes.p} * 1);
`;

export const NumberContainer = styled.div`
    padding: 0 ${spacing.small2};
    display: flex;
    align-items: center;
    gap: ${spacing.small2};
`;

export const NumberPlaceholder = styled.div`
    height: calc(${hedwigFontSizes.p} * 1.6);
    width: calc(${hedwigFontSizes.p} * 1);
`;