import { BreadcrumbsContainer } from './style';
import { Link, useMatches } from "react-router-dom";
import { Breadcrumbs } from "@postenbring/hedwig-react/";
import { useTranslation } from 'react-i18next';


const BreadcrumbsComponent = () => {
  const matches = useMatches();
  const { t } = useTranslation(['translation']);

  const crumbs = matches
    // @ts-ignore
    .filter((match) => Boolean(match.handle?.crumb))
    // @ts-ignore
    .map((m, index) => ({ crumb: m.handle.crumb(), pathname: m.pathname, index }))

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs>
        {crumbs.map(m => <li key={m.index}>
          <Link to={m.pathname} className={m.index === crumbs.length - 1 ? 'disabled' : ''}>
            {t(m.crumb)}
          </Link>
        </li>)}
      </Breadcrumbs>
    </BreadcrumbsContainer>
  )
}

export default BreadcrumbsComponent;