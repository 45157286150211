import { t } from 'i18next';
import { Name, NewServiceAndEquipmentTileContainer, Note, TextContainer } from './style';
import { fontClasses } from '@/ui/typography';
import { Badge } from "@postenbring/hedwig-react";

interface INewServiceAndEquipmentTile {
  name: string
  note?: string
};
const NewServiceAndEquipmentTile = ({name, note}: INewServiceAndEquipmentTile) => {

  return (
    <NewServiceAndEquipmentTileContainer>
      <Badge variant={"warning"}>{`${t('common.new')}!`}</Badge>
      <TextContainer>
        <Name className={fontClasses.technical}>
          {name}
        </Name>
        <Note className={fontClasses.technical}>
          {note}
        </Note>
      </TextContainer>


    </NewServiceAndEquipmentTileContainer>)
}
export default NewServiceAndEquipmentTile;
