import { ArticleItem } from "@/models/UnitPage/basePage"
import { Container, Icon } from "./style"
import { fontClasses } from "@/ui/typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import { useCheckMobileScreen, useCheckTabletScreen } from "@/helpers/screenSizeDetector";
import Accordian from "@/components/base/accordian";
import { colors } from "@/ui/colors";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { faDesktop } from "@fortawesome/pro-solid-svg-icons/faDesktop";

type Props = {
  articleItems: ArticleItem[];
}

const renderContent = (item: ArticleItem) => {
  switch (item.articleItemTypeValue) {
    case 1: // Title
      return (
        <div key={item.idArticleItem}>
          <ul className="hw-linklist">
            <li>
              <h2 key={item.idArticleItem}>{i18n.language === 'no' ? item.text1 : item.text2}</h2>
            </li>
          </ul>
        </div>)
    case 2: // Email
      return (
        <div key={item.idArticleItem}>
          <ul className="hw-linklist">
            {item.text1 != null &&
                <li>
                  {item.text1}
                </li>}
            <li>
              <Icon><FontAwesomeIcon icon={faEnvelope}/></Icon>
              <a href={`mailto:${item.text2}`} className={`${fontClasses.technical}`}>
                <i className="far fa-cloud-download"></i>
                {item.text2}
              </a>
            </li>
          </ul>
        </div>)
    case 3: // Telefone
      return (
        <div key={item.idArticleItem}>
          <ul className="hw-linklist">
            <li>
              <Icon><FontAwesomeIcon icon={faPhone}/></Icon>
              <p className={`${fontClasses.technical}`}>
                {item.text2}
              </p>
            </li>
          </ul>
        </div>)
    case 4: // URL
      return (
        <div key={item.idArticleItem}>
          <ul className="hw-linklist">
            <li>
              <Icon><FontAwesomeIcon icon={faDesktop}/></Icon>
              <a href={item.text2} className={`${fontClasses.technical}`}>
                {item.text1}
              </a>
            </li>
          </ul>
        </div>
      );
    default:
      return (
        <div key={item.idArticleItem}>
          <ul className="hw-linklist">
            <li>
              <p>
                {i18n.language === 'no' ? item.text1 : item.text2}
              </p>
            </li>
          </ul>
        </div>
      );
  }
}


const HedwigFooterContent = ({articleItems}: Props) => {
  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabletScreen();
  return (
    <Container>
      {/* <!-- Mobile & Tablet footer --> */}
      {isMobile || isTablet ?
        <div className="hw-block">
          {articleItems &&
              <Accordian backgroundColor={colors.primaryDarker}
                         title={i18n.language === 'no' ? articleItems.find((a) => a.articleItemTypeValue == 1)?.text1 : articleItems.find((a) => a.articleItemTypeValue == 1)?.text2}
                         showInfoIcon={false}>
                {
                  articleItems.map((item) =>
                    item.articleItemTypeValue != 1 && renderContent(item)
                  )}
              </Accordian>
          }
        </div>

        :
        <>
          {articleItems?.map((item) =>
            renderContent(item)
          )}
        </>
      }
    </Container>)
}
export default HedwigFooterContent;
