import styled from "styled-components";
import { colors } from "@/ui/colors";
import { spacing } from "@/ui/spacing";

export const UnitCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.primaryLighter};
    border-radius: 4px;
    padding: ${spacing.small4};
    &:hover {
        background: ${colors.hoverLightRed};
        transition: 1s;
    }
    margin-bottom: ${spacing.small1};
`;
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    path {
        color: ${colors.primaryDark};
    }
`;
export const UnitInformation = styled.div`
    display: flex;
    align-items: center;
    & .unit-number{
        padding-right: ${spacing.small2} ;
    };
`;
export const SecondaryInformation = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${spacing.small2};
    svg {
        padding-right: ${spacing.small2} ;
    }
    path {
        color: ${colors.primaryDarker};
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    path {
        color: ${colors.primaryDark};
    }
`;

export const TimeInformation = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${spacing.small2};
    & .progress {
        flex: 1;
    }
    svg {
        padding-right: ${spacing.small2} ;
    }
    path {
        color: ${colors.primaryDarker};
    }

`;