import SectionTitle from '@/components/dashboard/section-title';
import { CardsContainer, ActiveProcessesContainer } from './style';
import { getActiveProcesses } from '@/services/dashboard';
import { IActiveProcesses, ILastVisitedUnits } from '@/models/dashboard/dashboardPage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '@/components/dashboard/pagination-component';
import ActiveProcessCard from "@/components/dashboard/active-process-card";
import DashboardComponentPlaceholder from "@/components/loading/dashboard-component-placeholder";
import ProgressCardListPlaceholder from "@/components/loading/progress-card-list-placeholder";

const ActiveProcesses = () => {
    const { t } = useTranslation(['translation']);
    const [activeProcesses, setActiveProcessess] = useState<IActiveProcesses>({ processes: [], totalCount: 0 });
    const [recordsToDisplay, setRecordsToDisplay] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (currentPage == 0) return
        getActiveProcesses(currentPage, recordsToDisplay).then(response => {
            if (response.ok) setActiveProcessess(response.body)
            setLoading(false)
        })
    }, [currentPage, recordsToDisplay])

    return (
        <>
            {activeProcesses.processes.length > 0 && <ActiveProcessesContainer>
                <SectionTitle title={t('dashboard.activeProcesses')} />
                {loading ? <DashboardComponentPlaceholder>
                    <ProgressCardListPlaceholder numberOfItems={5} includeHeader={false} numerOfPagination={3} />
                </DashboardComponentPlaceholder>
                    :
                    <PaginationComponent numberToShowOptions={[5, 10, 15]}
                        totalCount={activeProcesses.totalCount}
                        recordsToDisplay={recordsToDisplay}
                        currentPage={currentPage == 0 ? 1 : currentPage}
                        setCurrentPage={setCurrentPage}
                        showRecordsToDisplay={true}
                        onNumberOfRecordsChanged={setRecordsToDisplay}>
                        <CardsContainer>
                            {
                                activeProcesses.processes?.map((p, idx) => (
                                    <ActiveProcessCard unitName={p.unitName} title={p.title} unitNumber={p.unitNumber} key={idx} deadline={p.deadline} url={p.url} createdDate={p.createdDate} />
                                ))
                            }
                        </CardsContainer>
                    </PaginationComponent>
                }
            </ActiveProcessesContainer>}
        </>
    )
}

export default ActiveProcesses;
