import { ICountry } from "@/models/UnitPage/pickUpArea";
import { IBaseUnit, IEstablishmentStatusType, IUnitType } from "@/models/UnitPage/unit";
import { IFilterCount, IFilterCountMapping, IFilterMappings, IReport, IReportDataset } from "@/models/reports/reports";
import { ICheckbox } from "@/pages/Reports/Sections/Filters";

export const REPORT_QUERY_NAMES = {
  countries: 'countries',
  divisions: 'divisions',
  regions: 'regions',
  districts: 'districts',
  unitTypes: 'unitTypes',
  statuses: 'statuses',
  datasets: 'datasets',
  queryString: 'queryString',
  initSearch: 'initialSearch'
}

export const getCountryFilterCheckboxes = (countries: ICountry[], searchParams: URLSearchParams) => {
  const result: { [countryCode: string]: ICheckbox } = {}
  const contryFilters = searchParams.get(REPORT_QUERY_NAMES.countries) ?? ''
  const activeContries = contryFilters.split(',')
  for (const country of countries) {
    result[country.code] = {
      label: country.name,
      value: country.code,
      checked: activeContries.findIndex(d => d === country.code) > -1,
      count: 0
    }
  }
  return result
}

export const getUnitFilterCheckboxes = (units: IBaseUnit[], queryName: string, searchParams: URLSearchParams) => {
  const result: { [unitNumber: string]: ICheckbox } = {}
  const districtFilters = searchParams.get(queryName) ?? ''
  const unitNumbers = districtFilters.split(',')
  for (const unit of units) {
    result[unit.unitNumber] = {
      label: unit.name,
      value: unit.unitNumber,
      checked: unitNumbers.findIndex(d => d === unit.unitNumber) > -1,
      count: 0
    }
  }
  return result
}

export const getUnitTypeCheckboxes = (unitTypes: IUnitType[], searchParams: URLSearchParams) => {
  const result: { [unitType: string]: ICheckbox } = {}
  const unitTypeFilters = searchParams.get(REPORT_QUERY_NAMES.unitTypes) ?? ''
  const activeUnitTypes = unitTypeFilters.split(',')
  for (const unitType of unitTypes) {
    result[unitType.unittype] = {
      label: unitType.name,
      value: unitType.unittype,
      checked: activeUnitTypes.findIndex(d => d === unitType.unittype.toString()) > -1,
      count: 0
    }
  }
  return result
}

export const getStatusCheckboxes = (statuses: IEstablishmentStatusType[], searchParams: URLSearchParams) => {
  const result: { [estabslishmentStatusType: string]: ICheckbox } = {}
  const statusFilters = searchParams.get(REPORT_QUERY_NAMES.statuses) ?? ''
  const activeStatus = statusFilters.split(',')
  for (const status of statuses) {
    result[status.establishmentStatustype] = {
      label: status.name,
      value: status.establishmentStatustype,
      checked: activeStatus.findIndex(d => d === status.establishmentStatustype.toString()) > -1,
      count: 0
    }
  }
  return result
}

export const getDatasetCheckboxes = (datasets: IReportDataset[], searchParams: URLSearchParams) => {
  const result: { [dataset: string]: ICheckbox } = {}
  const datasetFilters = searchParams.get(REPORT_QUERY_NAMES.datasets) ?? ''
  const activeDatasets = datasetFilters.split(',')
  for (const dataset of datasets) {
    result[dataset.datasetValue] = {
      label: dataset.name,
      value: dataset.datasetValue,
      checked: activeDatasets.findIndex(d => d === dataset.datasetValue.toString()) > -1,
      count: 0
    }
  }
  return result
}

export const updateCountryUrlParameter = (searchParams: URLSearchParams, countryCode: string, checked: boolean) => {
  const countryFilters = searchParams.get(REPORT_QUERY_NAMES.countries) ?? ''
  const activeCountries = countryFilters.split(',')
  const idx = activeCountries.findIndex(u => u === countryCode)
  if (idx > -1) {
    if (!checked) {
      activeCountries.splice(idx, 1)
    }
  } else {
    if (checked) {
      activeCountries.push(countryCode)
    }
  }
  const newParam = activeCountries.filter(s => s !== '').join(",")
  if (newParam !== '') {
    searchParams.set(REPORT_QUERY_NAMES.countries, newParam)
  } else {
    searchParams.delete(REPORT_QUERY_NAMES.countries)
  }

  return searchParams
}

export const updateUnitUrlParameter = (searchParams: URLSearchParams, queryParamter: string, unitNumber: string, checked: boolean) => {
  const datasetFilters = searchParams.get(queryParamter) ?? ''
  const activeUnits = datasetFilters.split(',')
  const idx = activeUnits.findIndex(u => u === unitNumber)
  if (idx > -1) {
    if (!checked) {
      activeUnits.splice(idx, 1)
    }
  } else {
    if (checked) {
      activeUnits.push(unitNumber)
    }
  }
  const newParam = activeUnits.filter(s => s !== '').join(",")
  if (newParam !== '') {
    searchParams.set(queryParamter, newParam)
  } else {
    searchParams.delete(queryParamter)
  }
  return searchParams
}


export const updateUnitTypeUrlParameter = (searchParams: URLSearchParams, unitType: string, checked: boolean) => {
  const unitTypeFilters = searchParams.get(REPORT_QUERY_NAMES.unitTypes) ?? ''
  const activeUnitTypes = unitTypeFilters.split(',')
  const idx = activeUnitTypes.findIndex(u => u === unitType)
  if (idx > -1) {
    if (!checked) {
      activeUnitTypes.splice(idx, 1)
    }
  } else {
    if (checked) {
      activeUnitTypes.push(unitType)
    }
  }
  const newParam = activeUnitTypes.filter(s => s !== '').join(",")
  if (newParam !== '') {
    searchParams.set(REPORT_QUERY_NAMES.unitTypes, newParam)
  } else {
    searchParams.delete(REPORT_QUERY_NAMES.unitTypes)
  }
  return searchParams
}

export const updateStatusUrlParameter = (searchParams: URLSearchParams, establishmentStatusType: string, checked: boolean) => {
  const statusFilters = searchParams.get(REPORT_QUERY_NAMES.statuses) ?? ''
  const activeStatuses = statusFilters.split(',')
  const idx = activeStatuses.findIndex(u => u === establishmentStatusType)
  if (idx > -1) {
    if (!checked) {
      activeStatuses.splice(idx, 1)
    }
  } else {
    if (checked) {
      activeStatuses.push(establishmentStatusType)
    }
  }
  const newParam = activeStatuses.filter(s => s !== '').join(",")
  if (newParam !== '') {
    searchParams.set(REPORT_QUERY_NAMES.statuses, newParam)
  } else {
    searchParams.delete(REPORT_QUERY_NAMES.statuses)
  }
  return searchParams
}

export const updateDatasetUrlParameter = (searchParams: URLSearchParams, datasetValue: string, checked: boolean) => {
  const datasetFilters = searchParams.get(REPORT_QUERY_NAMES.datasets) ?? ''
  const activeDatasets = datasetFilters.split(',')
  const idx = activeDatasets.findIndex(u => u === datasetValue)
  if (idx > -1) {
    if (!checked) {
      activeDatasets.splice(idx, 1)
    }
  } else {
    if (checked) {
      activeDatasets.push(datasetValue)
    }
  }
  const newParam = activeDatasets.filter(s => s !== '').join(",")
  if (newParam !== '') {
    searchParams.set(REPORT_QUERY_NAMES.datasets, newParam)
  } else {
    searchParams.delete(REPORT_QUERY_NAMES.datasets)
  }
  return searchParams
}

export const getFilterQueryString = (searchParams: URLSearchParams) => {
  let queryParamter = ''
  const countryFilters = searchParams.get(REPORT_QUERY_NAMES.countries) ?? ''
  const activeCountries = countryFilters.split(',').filter(s => s !== '')
  if (activeCountries.length > 0) {
    queryParamter += `${REPORT_QUERY_NAMES.countries}=${activeCountries.join(',')}`
  }
  const divisionFilters = searchParams.get(REPORT_QUERY_NAMES.divisions) ?? ''
  const activeDivisons = divisionFilters.split(',').filter(s => s !== '')
  if (activeDivisons.length > 0) {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.divisions}=${activeDivisons.join(',')}`
  }
  const regionFilters = searchParams.get(REPORT_QUERY_NAMES.regions) ?? ''
  const activeRegions = regionFilters.split(',').filter(s => s !== '')
  if (activeRegions.length > 0) {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.regions}=${activeRegions.join(',')}`
  }
  const districtFilters = searchParams.get(REPORT_QUERY_NAMES.districts) ?? ''
  const activeDistricts = districtFilters.split(',').filter(s => s !== '')
  if (activeDistricts.length > 0) {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.districts}=${activeDistricts.join(',')}`
  }

  const unitTypeFilters = searchParams.get(REPORT_QUERY_NAMES.unitTypes) ?? ''
  const activeUnitTypes = unitTypeFilters.split(',').filter(s => s !== '')
  if (activeUnitTypes.length > 0) {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.unitTypes}=${activeUnitTypes.join(',')}`
  }

  const statusFilters = searchParams.get(REPORT_QUERY_NAMES.statuses) ?? ''
  const activeStatuses = statusFilters.split(',').filter(s => s !== '')
  if (activeStatuses.length > 0) {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.statuses}=${activeStatuses.join(',')}`
  }
  const queryString = searchParams.get(REPORT_QUERY_NAMES.queryString)
  if (queryString && typeof queryString === "string") {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.queryString}=${queryString}`
  }
  return queryParamter
}

export const getFilterWithDatasetQueryString = (searchParams: URLSearchParams) => {
  let queryParamter = getFilterQueryString(searchParams)
  const datasetFilters = searchParams.get(REPORT_QUERY_NAMES.datasets) ?? ''
  const activeDatasets = datasetFilters.split(',').filter(s => s !== '')
  if (activeDatasets.length > 0) {
    if (queryParamter.length > 0) {
      queryParamter += '&'
    }
    queryParamter += `${REPORT_QUERY_NAMES.datasets}=${activeDatasets.join(',')}`
  }
  return queryParamter
}

export const mapQueryCount = (filterCount: IFilterCount): IFilterCountMapping => {
  const countryCount = {
    values: {},
    total: 0
  }
  for (const country of filterCount.countries ?? []) {
    countryCount.values[country.key] = country.count
    countryCount.total += country.count
  }

  const divisionCount = {
    values: {},
    total: 0
  }
  for (const divison of filterCount.divisions ?? []) {
    divisionCount.values[divison.key] = divison.count
    divisionCount.total += divison.count
  }
  const regionCount = {
    values: {},
    total: 0
  }
  for (const region of filterCount.regions ?? []) {
    regionCount.values[region.key] = region.count
    regionCount.total += region.count
  }
  const districtCount = {
    values: {},
    total: 0
  }
  for (const district of filterCount.districts ?? []) {
    districtCount.values[district.key] = district.count
    districtCount.total += district.count
  }
  const unitTypeCount = {
    values: {},
    total: 0
  }
  for (const unitType of filterCount.unitTypes ?? []) {
    unitTypeCount.values[unitType.key] = unitType.count
    unitTypeCount.total += unitType.count
  }
  const statusCount = {
    values: {},
    total: 0
  }
  for (const status of filterCount.statuses ?? []) {
    statusCount.values[status.key] = status.count
    statusCount.total += status.count
  }
  return {
    countries: countryCount,
    divisions: divisionCount,
    regions: regionCount,
    districts: districtCount,
    unitTypes: unitTypeCount,
    statuses: statusCount
  }
}

export const getActiveFilters = (searchParams: URLSearchParams) => {
  const countryFilters = searchParams.get(REPORT_QUERY_NAMES.countries) ?? ''
  const activeCountries = countryFilters.split(',').filter(s => s !== '')

  const divisionFilters = searchParams.get(REPORT_QUERY_NAMES.divisions) ?? ''
  const activeDivisons = divisionFilters.split(',').filter(s => s !== '')

  const regionFilters = searchParams.get(REPORT_QUERY_NAMES.regions) ?? ''
  const activeRegions = regionFilters.split(',').filter(s => s !== '')

  const districtFilters = searchParams.get(REPORT_QUERY_NAMES.districts) ?? ''
  const activeDistricts = districtFilters.split(',').filter(s => s !== '')

  const unitTypeFilters = searchParams.get(REPORT_QUERY_NAMES.unitTypes) ?? ''
  const activeUnitTypes = unitTypeFilters.split(',').filter(s => s !== '')

  const statusFilters = searchParams.get(REPORT_QUERY_NAMES.statuses) ?? ''
  const activeStatuses = statusFilters.split(',').filter(s => s !== '')

  const datasetFilters = searchParams.get(REPORT_QUERY_NAMES.datasets) ?? ''
  const activeDatasets = datasetFilters.split(',').filter(s => s !== '')

  return {
    countries: activeCountries,
    divisions: activeDivisons,
    regions: activeRegions,
    districts: activeDistricts,
    unitTypes: activeUnitTypes,
    statuses: activeStatuses,
    datasets: activeDatasets
  }
}

export const getEditableFilters = (searchParams: URLSearchParams, filters: IFilterMappings) => {
  const activeFilters = getActiveFilters(searchParams)
  const queryString = searchParams.get(REPORT_QUERY_NAMES.queryString)
  return {
    countryFilters: activeFilters.countries.map(countryCode => filters?.countries[countryCode]).filter(c => c !== undefined),
    divisionFilters: activeFilters.divisions.map(unitNumber => filters?.divisions[unitNumber]).filter(d => d !== undefined),
    regionFilters: activeFilters.regions.map(unitNumber => filters?.regions[unitNumber]).filter(d => d !== undefined),
    districtFilters: activeFilters.districts.map(unitNumber => filters?.districts[unitNumber]).filter(d => d !== undefined),
    unitTypeFilters: activeFilters.unitTypes.map(unitType => filters?.unitTypes[unitType]).filter(d => d !== undefined),
    establishmentStatusTypeFilters: activeFilters.statuses.map(status => filters?.establishmentStatusTypes[status]).filter(d => d !== undefined),
    datasetFilters: activeFilters.datasets.map(dataset => filters?.datasets[dataset]).filter(d => d !== undefined),
    queryString: queryString
  }
}

export const setQueryParameterFromReport = (searchParams: URLSearchParams, report: IReport) => {
  const countryParam = (report.countryFilters ?? []).map(country => country.code).join(",");
  if (countryParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.countries, countryParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.countries)

  const divisionParam = (report.divisionFilters ?? []).map(division => division.unitNumber).join(",");
  if (divisionParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.divisions, divisionParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.divisions)

  const regionParam = (report.regionFilters ?? []).map(region => region.unitNumber).join(",");
  if (regionParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.regions, regionParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.regions)

  const districtParam = (report.districtFilters ?? []).map(district => district.unitNumber).join(",");
  if (districtParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.districts, districtParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.districts)

  const unitTypeParam = (report.unitTypeFilters ?? []).map(unitType => unitType.unittype).join(",");
  if (unitTypeParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.unitTypes, unitTypeParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.unitTypes)

  const establishmentStatusTypeParam = (report.establishmentStatusTypeFilters ?? []).map(establishmentStatusType => establishmentStatusType.establishmentStatustype).join(",");
  if (establishmentStatusTypeParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.statuses, establishmentStatusTypeParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.statuses)

  const datasetParam = (report.datasetFilters ?? []).map(dataset => dataset.datasetValue).join(",");
  if (datasetParam !== '')
    searchParams.set(REPORT_QUERY_NAMES.datasets, datasetParam)
  else
    searchParams.delete(REPORT_QUERY_NAMES.datasets)

  if ((report.queryString ?? '') !== '')
    searchParams.set(REPORT_QUERY_NAMES.queryString, report.queryString)
  else
    searchParams.delete(REPORT_QUERY_NAMES.queryString)
  return searchParams
}

export const getQueryStringFromReport = (report: IReport) => {
  let queryParams = ''
  const countryParam = (report.countryFilters ?? []).map(country => country.code).sort().join(",");
  if (countryParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.countries}=${countryParam}`
  }
  const divisionParam = (report.divisionFilters ?? []).map(division => division.unitNumber).sort().join(",");
  if (divisionParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.divisions}=${divisionParam}`
  }
  const regionParam = (report.regionFilters ?? []).map(region => region.unitNumber).join(",");
  if (regionParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.regions}=${regionParam}`
  }
  const districtParam = (report.districtFilters ?? []).map(district => district.unitNumber).join(",");
  if (districtParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.districts}=${districtParam}`
  }
  const unitTypeParam = (report.unitTypeFilters ?? []).map(unitType => unitType.unittype).join(",");
  if (unitTypeParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.unitTypes}=${unitTypeParam}`
  }
  const establishmentStatusTypeParam = (report.establishmentStatusTypeFilters ?? []).map(establishmentStatusType => establishmentStatusType.establishmentStatustype).join(",");
  if (establishmentStatusTypeParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.statuses}=${establishmentStatusTypeParam}`
  }
  const datasetParam = (report.datasetFilters ?? []).map(dataset => dataset.datasetValue).join(",");
  if (datasetParam !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.datasets}=${datasetParam}`
  }

  if ((report.queryString ?? '') !== '') {
    if (queryParams.length > 0) queryParams += '&'
    queryParams += `${REPORT_QUERY_NAMES.queryString}=${report.queryString}`
  }
  return queryParams
}
