import InfoGraphicCardPlaceholder from "../info-graphic-card-placeholder"
import { CardContainer } from "./style"

interface IGraphicCardListPlaceholderProps {
    numberOfCards: number
    includeIcon?: boolean
}

const GraphicCardListPlaceholder = ({ numberOfCards, includeIcon } : IGraphicCardListPlaceholderProps) => {
    return (
        <CardContainer>
            {
                new Array(numberOfCards).fill(0).map((_, idx) => (
                    <InfoGraphicCardPlaceholder key={idx} includeIcon={includeIcon} />
                ))
            }
        </CardContainer>
    )
}

export default GraphicCardListPlaceholder 