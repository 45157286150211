import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const PaginationComponentContainer = styled.div`
    
`;

export const RowCountManager = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-right: ${spacing.small4};;
    }
    select {
        padding: 0 0 0 10px;
        height: 40px;
    }
`;

export const CountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;