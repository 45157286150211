import { colors } from "@/ui/colors";
import { mediaqueries } from "@/ui/mediaqueries";
import { spacing } from "@/ui/spacing";
import styled from "styled-components";

export const NewServiceAndEquipmentTileContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-basis: 50%;
    padding-left:${spacing.medium3};
    align-items: center;
    .hw-badge {
        min-width: 43px;
        max-height: 28px;
    }
    min-height: 44px;
     // Mobile View
     ${mediaqueries.xsMax} {
        flex-basis: 100%;
        padding-left:${spacing.medium2};
    }
        
`;

export const Name = styled.div`
    width: 100%;
`;

export const Note = styled.div`
    width: 100%;
    color: ${colors.primaryDark};

`;

export const TextContainer = styled.div`
    display: flex;
    flex: 1 1;
    align-items: flex-end;
    border-bottom: 1px solid ${colors.darkGrey};
    width: 100%;
    // Mobile View
    ${mediaqueries.xsMax} {
        flex-direction: column;
    }
`;